/* eslint-disable no-bitwise */
/**
 * @module RelatedContentCarousel
 */
import React from 'react';
import Carousel from 'nuka-carousel';
import { getLabelValueV2 } from '@io/web-tools-io/dist/utils/helpers/magnolia/labels';
import { mediaUrlFromType } from '@io/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByPath } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import CarouselItem from '../Carousel/Carousel';
import LCImage from '../LCImage/LCImage';

/**
 * Component for showing the related content carousel of the media collection.
 *
 * @param {object} props - The component props object.
 * @param {object} props.data - The data of the media media collection.
 * @param {object} props.carouselTitle - The data of the media collection.
 * @param {object} [props.hasBgColor] - The data of the media collection.
 * @param {object} props.preload - The preloaded data of the media collection.
 *
 * @returns {React.ReactElement} The RelatedContentCarousel component.
 */
function RelatedContentCarousel({
  data,
  carouselTitle,
  hasBgColor = true,
  preload,
}) {
  const { isMobile, isTablet } = useWindowSize();
  const [slideWidthPx, setSlideWidthPx] = React.useState(0);
  const [slidesToShow, setSlidesToShow] = React.useState(1);
  const [draggable, setDraggable] = React.useState(false);
  const [showNoControls, setShowNoControls] = React.useState(false);

  const {
    itemType,
    labelList,
    logoPath,
    relatedCollectionType,
    relatedContent,
  } = preload;

  const limit = Number(data?.relatedContent?.maxItems) || 0;

  const checkSlidesToShow = (slide) => {
    const slider = document.getElementById('opc-carousel');
    /* istanbul ignore next */
    if (slider?.offsetWidth) {
      const sliderWidth = slider.offsetWidth < 1200 ? slider.offsetWidth : 1200;
      setSlidesToShow(~~(sliderWidth / (slide + 16)) || 1);
    }
  };

  const showCarouselControlOrNot = () => {
    const slider = document.getElementById('opc-carousel');
    const slides = slider?.querySelectorAll('.carousel-item-image');
    const slidesHaveLength = slides?.length;
    let totalWidth = slidesHaveLength ? slides.length * 16 : 0;

    if (slides?.length) {
      slides.forEach((item) => {
        /* istanbul ignore next */
        totalWidth += item.clientWidth;
      });
    }

    /* istanbul ignore next */
    if (slider && totalWidth < slider?.offsetWidth) {
      setShowNoControls(true);
    } else {
      setShowNoControls(false);
    }
  };

  const checkCarouselSlideWidth = () => {
    if (isMobile) {
      setSlideWidthPx((150 * 16) / 9);
      checkSlidesToShow((150 * 16) / 9);
      setDraggable(true);
    } else if (isTablet) {
      setSlideWidthPx((170 * 16) / 9);
      checkSlidesToShow((170 * 16) / 9);
      setDraggable(false);
    } else {
      setSlideWidthPx((210 * 16) / 9);
      checkSlidesToShow((210 * 16) / 9);
      setDraggable(false);
    }
    return true;
  };

  React.useEffect(() => {
    function handleResize() {
      showCarouselControlOrNot();
      checkCarouselSlideWidth();
    }

    checkCarouselSlideWidth();

    setTimeout(() => {
      showCarouselControlOrNot();
      checkCarouselSlideWidth();
    }, 500);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // Note: Adding dependencies for hook-related state items so resize logic
    // for determining/setting image source is properly utilized and set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const titleLabel = getLabelValueV2(labelList, relatedCollectionType);
  const elementUrl = itemType ? mediaUrlFromType({ type: itemType }) : '#';

  return (
    <>
      {relatedContent?.length ? (
        <div
          className={`related-content-carousel carousel-wrapper ${
            hasBgColor ? 'bg-lighter_gray' : /* istanbul ignore next*/ ''
          } ${isMobile ? 'py-bit_more_relaxed' : 'py-slightly_more_relaxed'}`}
          data-testid="lc-related-content-carousel"
          id="opc-carousel"
        >
          <div className="open-content-list content-general-carousel container">
            <div className="carousel-title-wrapper">
              <h2 className="carousel-title text-group_header text-weight-bold">
                {logoPath ? (
                  <LCImage
                    className="extra-icon"
                    src={`${logoPath}.svg`}
                    width={32}
                  />
                ) : null}
                {carouselTitle || titleLabel || 'More Series'}
              </h2>
              <a
                className="view-all"
                href={implementUtmParams(elementUrl, window?.location)}
              >
                View All
              </a>
            </div>
            <Carousel
              autoplay={false}
              cellSpacing={16}
              defaultControlsConfig={{
                nextButtonClassName: 'custom-next-button',
                nextButtonText: ' ',
                prevButtonClassName: 'custom-prev-button',
                prevButtonText: ' ',
              }}
              dragging={draggable}
              slideWidth={`${slideWidthPx}px`}
              slidesToScroll={1}
              slidesToShow={slidesToShow}
              speed={1000}
              swiping={true}
              withoutControls={showNoControls}
              wrapAround={false}
            >
              {relatedContent.map((relatedElement, index) => {
                if (limit > 0 && index >= limit) {
                  return null;
                }

                const { collection, subCollection, item } =
                  getUrlStructureByPath(relatedElement?.['@path']);
                const itemUrl = mediaUrlFromType({
                  slug: item,
                  subCollection,
                  type: collection,
                });
                const image =
                  relatedElement?.channelImageList?.cinemaImage_public ??
                  relatedElement?.featuredImage_public ??
                  '';

                return (
                  <div key={relatedElement?.['@id']}>
                    <CarouselItem
                      image={image}
                      slideWidth={slideWidthPx}
                      target="_self"
                      title={relatedElement?.name}
                      type="general-carousel"
                      url={itemUrl}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default RelatedContentCarousel;
