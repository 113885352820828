/**
 * @module FindsFetchers
 */
import { getMagnoliaItem } from '@io/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS } from '../constants';

async function fetchArticleByUUID(uuid) {
  const res = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/findsFetchers.js > fetchArticleByUUID',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/finds-articles/v1/?jcr:uuid=${uuid}`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  return res.results ? { ...res.results[0] } : {};
}

async function fetchMultipleArticlesByUUID(articleList) {
  return Promise.all(articleList.map((article) => fetchArticleByUUID(article)));
}

async function fetchJourneyByUUID(uuid) {
  const res = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/findsFetchers.js > fetchJourneyByUUID',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/finds/journey/?jcr:uuid=${uuid}`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  return res.results ? { ...res.results[0] } : {};
}

async function fetchMultipleJourneysByUUID(journeyList) {
  return Promise.all(journeyList.map((journey) => fetchJourneyByUUID(journey)));
}

async function fetchLatestJourney() {
  const res = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/findsFetchers.js > fetchLatestJourney',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/finds/journey/?orderBy=startDate%20DESC`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  return res.results ? { ...res.results[0] } : {};
}

/**
 * Convenience function to the Journey preview.
 *
 * @param {object} params - The function params object.
 * @param {string} params.journey - The journey uuid value.
 *
 * @returns {object} Data object of the specified Journey preview.
 */
export async function fetchJourneyPreview({ journey }) {
  return fetchJourneyByUUID(journey);
}

/**
 * Convenience function to fetch Journey listing hero.
 *
 * @param {object} params - The function params object.
 * @param {string} params.featuredJourney - Optional uuid of the featured journey. If not specified, will fetch the latest journey.
 *
 * @returns {object} Data object of the specified (or latest) Journey.
 */
export async function fetchJourneyListingHero({ featuredJourney }) {
  if (featuredJourney) {
    return fetchJourneyByUUID(featuredJourney);
  }
  return fetchLatestJourney();
}

/**
 * Convenience function to fetch Hub Preview data.
 *
 * @param {object} params - The function params object.
 * @param {Array} params.article - The article list uuid value.
 *
 * @returns {object} Data object of article card.
 */
export async function fetchArticleCard({ article }) {
  return fetchArticleByUUID(article);
}

/**
 * Convenience function to fetch Articles data.
 *
 * @param {object} params - The function params object.
 * @param {Array} params.articles - Array of article list uuid values.
 *
 * @returns {object} Array of articles.
 */
export async function fetchFeaturedArticles({ articles }) {
  return fetchMultipleArticlesByUUID(articles);
}

/**
 * Convenience function to fetch Hub Preview data.
 *
 * @param {object} params - The function params object.
 * @param {Array} params.articleList - Array of article list uuid values.
 * @param {string} params.featuredArticle - The featured article uuid value.
 *
 * @returns {object} Data object of featured article and articles list data objects.
 */
export async function fetchHubPreview({ articleList, featuredArticle }) {
  const featured = await fetchArticleByUUID(featuredArticle);
  const list = await fetchMultipleArticlesByUUID(articleList);
  return { featured, list };
}

/**
 * Convenience function to fetch Carousel data.
 *
 * @param {object} params - The function params object.
 * @param {Array} params.articles - Array of article uuid values.
 * @param {Array} params.journeys - Array of journey uuid values.
 *
 * @returns {Array} Array of carousel items.
 */
export async function fetchCarousel({ articles, journeys }) {
  if (articles?.length) {
    return articles;
  }
  if (journeys?.length) {
    return fetchMultipleJourneysByUUID(journeys);
  }
  return [];
}

/**
 * Convenience function to fetch Journey data.
 *
 * @param {object} params - The function params object.
 * @param {string} params.journey - The journey uuid value.
 *
 * @returns {object} Data object for the Journey.
 */
export async function fetchJourney({ journey }) {
  return fetchJourneyByUUID(journey);
}

/**
 * Convenience function to fetch social channel data.
 *
 * @returns {object} Data object of social media links.
 */
export async function fetchSocials() {
  const res = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/findsFetchers.js > fetchSocials',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/finds/share/`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  const socials = res.results ? { ...res.results[0] } : {};
  return socials.socialMediaLinks || {};
}
