/**
 * @module AlbumCardListing
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { generateAlbumSubtitle } from '@io/web-tools-io/dist/utils/helpers/magnolia/generateAlbumSubtitle';
import { mediaUrlFromType } from '@io/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByPath } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import LCBackground from '../LCImage/LCBackground';

const AlbumCardListing = ({ data = [], isMobile }) => {
  return (
    <div
      className={`album-cards-listing container ${
        isMobile ? 'my-very_relaxed' : 'my-slightly_more_relaxed'
      }`}
      data-testid="lc-album-card-listing"
    >
      {data?.map((item) => {
        const {
          '@id': id,
          '@path': path,
          albumCopyrightDate,
          channelImageList,
          parts,
          title,
        } = item;
        const {
          collection,
          item: itemSlug,
          subCollection,
        } = getUrlStructureByPath(path);
        const itemUrl = mediaUrlFromType({
          slug: itemSlug,
          subCollection,
          type: collection,
        });
        return (
          <AlbumCard
            image={channelImageList?.albumImage_public ?? ''}
            key={id}
            name={title}
            subtitle={generateAlbumSubtitle(albumCopyrightDate, parts)}
            url={itemUrl}
          />
        );
      })}
    </div>
  );
};

export const AlbumCard = ({ name, subtitle, url, image }) => {
  return (
    <div className="album-card-item" data-testid="lc-album-card">
      {image ? (
        <a
          className="album-card-image-link"
          href={implementUtmParams(url || '#', window?.location)}
          target="_self"
        >
          <LCBackground
            backup={'album-img'}
            className="album-card-image"
            imgixParams={{ h: 300, w: 300 }}
            src={image}
          ></LCBackground>
        </a>
      ) : null}
      <h1 className="album-card-name">{name}</h1>
      <p className="album-card-subtitle">{subtitle}</p>
    </div>
  );
};

export default AlbumCardListing;
