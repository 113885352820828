/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/**
 * @module PageLoader
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { convertS3DamItems } from '@io/web-tools-io/dist/utils/helpers/magnolia/imgix';
import {
  getCurrentLanguage,
  getLanguages,
  removeCurrentLanguage,
} from '@io/web-tools-io/dist/utils/helpers/magnolia/languages';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { EditablePage, EditorContextHelper } from '@magnolia/react-editor';
import { useScrollTracker } from 'react-scroll-tracker';
import config from '../magnolia.config';
import AppDownload from '../views/AppDownload';
import Authentication from '../views/Authentication';
import {
  AUTH_ROUTES,
  EVENTS,
  MGNL_ENV_VARS,
  SMART_APP_DOWNLOAD_ROUTES,
} from './constants';

function SpecialtyRouteComponent({ isAuthPath, isSmartUrlPath }) {
  if (isAuthPath) {
    return <Authentication />;
  }
  if (isSmartUrlPath) {
    return <AppDownload />;
  }
  return null;
}

/**
 * Represents a loader for a website page.
 *
 * @returns {React.ReactElement} The page component.
 */
function PageLoader() {
  const { configure, isConfigured } = useAuth();

  const [isInitialized, setIsInitialized] = React.useState(false);
  const [content, setContent] = React.useState(null);
  const [templateDefinitions, setTemplateDefinitions] = React.useState(null);
  const [pathname, setPathname] = React.useState(null);
  const [isAuthPath, setIsAuthPath] = React.useState(null);
  const [isSmartUrlPath, setIsSmartUrlPath] = React.useState(false);

  /**
   * Use local user var since auth may not yet be configured, and the useAuth
   * hook might not be fully configured and initialized at this point.
   */
  let user;
  try {
    user = window?.localStorage?.getItem('user_profile')
      ? JSON.parse(window?.localStorage?.getItem('user_profile'))
      : null;
  } catch (error) {
    Log.error(error);
  }

  /**
   * Implement scroll depth tracking and fire off Segment when thresholds met.
   */
  useScrollTracker([50], ({ scrollY }) => {
    callSegmentTrack({
      event: EVENTS.pageScrolled,
      properties: {
        depth: scrollY / 100,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  });

  const getPagePath = () => {
    const languages = getLanguages(MGNL_ENV_VARS);
    const nodeName = process.env.REACT_APP_MGNL_APP_BASE;
    const currentLanguage = getCurrentLanguage(MGNL_ENV_VARS);
    let path =
      nodeName +
      window.location.pathname
        .replace(/(.html)/g, '')
        .replace('/magnoliaAuthor', '');
    if (currentLanguage !== languages[0]) {
      path = removeCurrentLanguage(path, currentLanguage);
      path += `?lang=${currentLanguage}`;
    }
    if (path.startsWith('//')) {
      path = path.substring(1);
    }

    return path;
  };

  const loadPage = async () => {
    // Bail out if already loaded content.
    if (pathname === window.location.pathname) {
      return;
    }

    if (AUTH_ROUTES.includes(window.location.pathname)) {
      setIsAuthPath(true);
      return;
    }

    if (SMART_APP_DOWNLOAD_ROUTES.includes(window.location.pathname)) {
      setIsSmartUrlPath(true);
      return;
    }

    const apiBase = getAPIBase(MGNL_ENV_VARS);

    const pagePath = getPagePath();
    Log.info(`PageLoader > pagePath: ${pagePath}`);
    const fullContentPath = `${apiBase}${process.env.REACT_APP_MGNL_API_PAGES}${pagePath}`;
    let pageResponse;
    let pageJson;
    // Check if ___appProps exists.
    if (window?.___appProps?.currentPage) {
      pageJson = window.___appProps.currentPage;
    } else {
      try {
        pageResponse = await fetch(fullContentPath);
        pageJson = await pageResponse.json();
      } catch (error) {
        Log.error(error);
      }
    }

    const templateId = pageJson['mgnl:template'];

    let templateJson = null;
    if (EditorContextHelper.inIframe()) {
      try {
        const templateResponse = await fetch(
          `${apiBase}${process.env.REACT_APP_MGNL_API_TEMPLATES}/${templateId}`,
        );
        templateJson = await templateResponse.json();
      } catch (error) {
        Log.error(error);
      }
    }
    setIsInitialized(true);
    setContent(convertS3DamItems(pageJson, process.env.IMGIX_BASE_URL));
    setTemplateDefinitions(templateJson);
    setPathname(window.location.pathname);
  };

  React.useEffect(() => {
    if (!isConfigured) {
      const authConfig = {
        audience: process.env.AUTHAUDIENCE,
        clientID: process.env.AUTHCLIENTID,
        domain: process.env.AUTHDOMAIN,
        redirectUri: `${window.location.origin}${process.env.AUTH_CALLBACK_URL}`,
      };
      configure({
        callback: () => {
          loadPage();
        },
        config: authConfig,
      });
    } else {
      loadPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isInitialized && !isAuthPath && !isSmartUrlPath ? (
        <EditablePage
          config={config || {}}
          content={content || {}}
          templateDefinitions={templateDefinitions || {}}
        ></EditablePage>
      ) : (
        <SpecialtyRouteComponent
          isAuthPath={isAuthPath}
          isSmartUrlPath={isSmartUrlPath}
        />
      )}
    </>
  );
}

export default PageLoader;
