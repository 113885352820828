/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module PodcastAccordion
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import Accordion from '../Accordion/Accordion';
import { fetchAccordionData } from '../../helpers/dataFetchers/commonFetchers';

const PodcastAccordion = (props) => {
  const { filterOutByID, label, pagination, source } = props;

  const data = props.preload;

  /* istanbul ignore next */
  function filterData(orig = {}, dataToFilter = {}) {
    const filteredData = { ...orig, ...dataToFilter };
    if (!!filterOutByID && !!Object.keys(filteredData).length) {
      const idx = filteredData['@nodes'].findIndex(
        (element) => element === filterOutByID.split('-').join(''),
      );
      filteredData['@nodes'].splice(idx, 1);
    }
    return filteredData;
  }

  const [accordionItems, setAccordionItems] = React.useState(filterData(data));
  const [currentPagination, setCurrentPagination] = React.useState(pagination);
  const [numShows, setNumShows] = React.useState(
    accordionItems?.['@nodes']?.length,
  );

  /* istanbul ignore next */
  async function handleShowMoreBtnClicked(callback) {
    try {
      const accordionDataResult = await fetchAccordionData({
        data: { ...props, offset: currentPagination?.next || 0 },
        getMoreItems: true,
        source,
      });
      // Important: Need to explicitly spread/set new nodes for proper accuracy.
      const newNodes = [
        ...accordionItems['@nodes'],
        ...accordionDataResult.accordionData['@nodes'],
      ];
      setAccordionItems((prevItems) => {
        return {
          ...prevItems,
          ...accordionDataResult.accordionData,
          '@nodes': newNodes, // Ensure the merged array of nodes is updated!
        };
      });
      setNumShows(newNodes.length);
      setCurrentPagination(accordionDataResult.pagination);
      // Important: Handle support for callback option, which is setter function
      // that ultimately updates the `show` state of the Accordion.
      if (callback) {
        callback({ numShow: newNodes.length });
      }
    } catch (error) {
      if (callback) {
        callback({ numShow: numShows });
      }
      Log.error(error);
    }
  }

  React.useEffect(() => {
    /* istanbul ignore next */
    if (typeof window !== 'undefined') {
      const element = document.getElementById(
        window?.location?.hash.replace('#', ''),
      );

      if (element) {
        window.scrollTo({
          top: element.offsetTop,
        });
      }
    }
  }, []);

  return (
    <>
      {accordionItems ? (
        <div
          className="podcast-accordion"
          data-testid="lc-podcast-accordion"
          id="more-episodes"
        >
          {label?.more_media_title ? (
            <h2 className="title">{label.more_media_title}</h2>
          ) : null}
          {accordionItems?.['@nodes']?.length ? (
            /* istanbul ignore next */
            <Accordion
              {...{
                defaultShow: numShows,
                items: accordionItems,
                metadata: {
                  'mgnl:template': 'lifechurch:components/accordion',
                },
                onShowMoreClick: handleShowMoreBtnClicked,
                pagination: currentPagination,
              }}
            />
          ) : null}
        </div>
      ) : /* istanbul ignore next */ null}
    </>
  );
};

export default PodcastAccordion;
