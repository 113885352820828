/**
 * @module PodcastContentAndSubscribe
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Cookies from 'js-cookie';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import {
  addQueryParamToUrl,
  getQueryParam,
} from '@io/web-tools-io/dist/utils/helpers/queryParams';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import DownloadLeaderGuideModal from './DownloadLeaderGuideModal';
import PodcastMediaButton from './PodcastMediaButton';
import {
  ACTIONS,
  CATEGORIES,
  EVENTS,
  MGNL_ENV_VARS,
  SCREENS,
} from '../../helpers/constants';

const PodcastContentAndSubscribe = (props) => {
  const {
    additionalResources,
    collectionData,
    description,
    label,
    overrideDefaultMediaButton,
    resourceLabels,
    title,
  } = props;

  const { user } = useAuth();
  const [modal, setModal] = React.useState(false);
  const overrideBtn = overrideDefaultMediaButton ?? {};
  const [currentResource, setCurrentResource] = React.useState({});
  const { applePodcastURL, spotifyURL, youtubeURL } = collectionData;

  React.useEffect(() => {
    document.body.style.overflow = modal ? 'hidden' : '';
  }, [modal]);

  /**
   * Handler function for general button click.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} [labelValue] - The label value to use for the analytics properties data object.
   */
  function handleButtonClick(event, labelValue) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Podcast Content and Subscribe',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: labelValue || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  const handleBtnClick = (event, resource) => {
    setCurrentResource(resource);
    const leaderCookie = Cookies.get('leadership_podcast_optin');
    const eventLabel = `${resource.lang} - ${props.title}`;

    const utmMap = {
      campaign: getQueryParam('utm_campaign'),
      medium: getQueryParam('utm_medium'),
      name: getQueryParam('utm_name'),
      source: getQueryParam('utm_source'),
    };

    /**
     * Note: With the completion of epic MND-1694 and Segment
     * tracking improvements, the new set of event properties is
     * used, but the original (legacy) ones are also spread on
     * to the properties object.
     */
    const legacySegmentProperties = {
      campaign: utmMap.campaign,
      label: eventLabel,
      screen: SCREENS.podcastDetailPage,
    };

    if (leaderCookie) {
      callGtagEvent({
        parameters: {
          event_category: CATEGORIES.downloadLeaderGuide,
          event_label: eventLabel,
        },
        type: 'Download',
      });
      callSegmentTrack({
        event: EVENTS.downloadLeaderGuide,
        properties: {
          action: ACTIONS.clicked,
          component: 'Podcast Collection Content Form',
          component_url: event?.currentTarget?.getAttribute('href'),
          label: event?.currentTarget?.textContent,
          logged_in: !!user,
          preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
          referrer: document?.referrer || null,
          title: document?.title || '',
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
          ...legacySegmentProperties,
        },
      });

      const resourceUrl = addQueryParamToUrl({
        name: 'utm_source',
        url: resource?.asset_public,
        value: 'life_church',
      });
      const leaderGuideWindow = window.open(resourceUrl, '_blank');
      try {
        leaderGuideWindow.focus();
      } catch (error) {
        /* istanbul ignore next*/
        Log.error(error);
      }
    } else {
      setModal(!modal);
    }
  };

  const podcastIcons = [
    {
      iconSlug: 'apple-podcasts',
      label: 'Apple Podcasts',
      url: applePodcastURL,
    },
    {
      iconSlug: 'spotify',
      label: 'Spotify',
      url: spotifyURL,
    },
    {
      iconSlug: 'youtube',
      label: 'YouTube',
      url: youtubeURL,
    },
    {
      iconSlug: 'LC-app-listen',
      label: 'Life.Church App',
      url: 'https://www.life.church/app/',
    },
  ];

  /* istanbul ignore next*/
  const handleDownloadBtnClick = (resource) => {
    const resourceUrl = addQueryParamToUrl({
      name: 'utm_source',
      url: resource?.asset_public,
      value: 'life_church',
    });
    const leaderGuideWindow = window.open(resourceUrl, '_blank');
    try {
      leaderGuideWindow.focus();
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <div
      className="podcast-content-subscribe container mb-bit_more_relaxed mb-tablet-very_relaxed mb-laptop-even_more_relaxed"
      data-testid="lc-podcast-content-subscribe"
    >
      <div className="podcast-content">
        {title ? (
          <h2 className="title text-weight-bold text-section_header line-height-tighter mb-more_slight_tight mt-auto">
            {title}
          </h2>
        ) : null}

        {description ? (
          <p className="description mb-more_slight_tight mt-auto">
            {description}
          </p>
        ) : null}

        {additionalResources?.['@nodes']?.length ? (
          <div className="podcast-button">
            {additionalResources['@nodes'].map((item, index) => {
              const resourceItem = additionalResources[item];
              const isPrimary = index === 0;
              const { buttonType, field, buttonTitle } = overrideBtn;

              return field?.toString() === 'true' ? (
                <ButtonItem
                  buttonSize="medium"
                  key={resourceItem['@id']}
                  modalTrigger={
                    buttonType?.field === 'modal' ? buttonType?.modalIDs : ''
                  }
                  onClick={handleButtonClick}
                  style="btn-modal btn-primary"
                  text={buttonTitle || 'Download Leader Guide'}
                  url={
                    buttonType?.field !== 'modal' &&
                    buttonType?.field === 'link'
                      ? `${buttonType?.url || /* istanbul ignore next*/ ''}`
                      : `${getAPIBase(MGNL_ENV_VARS)}${
                          buttonType?.pageID?.['@path']
                        }`
                  }
                />
              ) : (
                <ButtonItem
                  buttonSize="medium"
                  className={
                    isPrimary
                      ? 'text-white'
                      : 'mr-none mr-tablet-more_slight_tight'
                  }
                  key={`podcast-button-${resourceItem.text
                    .toLowerCase()
                    .replace(/ /g, '-')}`}
                  onClick={(event) => handleBtnClick(event, resourceItem)}
                  style={isPrimary ? 'btn-primary' : 'btn-secondary'}
                  text={resourceItem.text}
                />
              );
            })}
          </div>
        ) : null}
      </div>

      <div className="podcast-subscribe px-normal py-relaxed">
        {label?.subscribeTitle ? (
          <h3 className="title text-center text-weight-bold line-height-tighter mb-normal mt-auto">
            {label.subscribeTitle}
          </h3>
        ) : null}
        <div className="button-group-wrapper">
          <ul className="button-item-listing channel-buttons-group center-align">
            {podcastIcons.map((podcastIcon) => {
              return podcastIcon.url ? (
                <PodcastMediaButton
                  buttonSize="medium"
                  iconClass={`icon-channel-${podcastIcon.iconSlug}`}
                  key={`podcast-icon-${podcastIcon.iconSlug}`}
                  onClick={(event) =>
                    handleButtonClick(event, podcastIcon.label)
                  }
                  url={podcastIcon.url}
                />
              ) : null;
            })}
          </ul>
        </div>
      </div>

      {modal ? (
        <DownloadLeaderGuideModal
          {...props}
          onClose={setModal}
          onDownloadClick={handleDownloadBtnClick}
          podcastTitle={props.title}
          resource={currentResource}
          resourceLabels={resourceLabels}
          screen={SCREENS.podcastDetailPage}
        />
      ) : null}
    </div>
  );
};

export default PodcastContentAndSubscribe;
