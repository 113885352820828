/**
 * @module Poster
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { getStateNameFromCode } from '@io/web-tools-io/dist/utils/helpers/locationsHelper';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import SpecialEventContent from './SpecialEventContent';
import WeeklyEventsContent from './WeeklyEventsContent';
import Button from '../ButtonItem/ButtonItem';
import RichText from '../RichText/RichText';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';
import LocationPosterContact from './LocationPosterContact';
import LCImage from '../LCImage/LCImage';
import Tabs from '../Tabs/Tabs';
import TabPanel from '../TabPanel/TabPanel';

/**
 * Represents the Poster element in the LocationPoster component.
 *
 * @param {object} props - The component props object.
 * @param {string} props.additionalInfo - The location additional information.
 * @param {string} props.campusCode - The location campus code.
 * @param {string} props.email - The location contact email.
 * @param {string} props.facebook - The location Facebook account id.
 * @param {Function} props.handleOpenModal - Handler function to trigger opening the modal.
 * @param {boolean} props.hasSpecialEvent - Boolean flag denoting whether or not the location has a special event.
 * @param {string} props.instagram - The location Instagram account id.
 * @param {object} props.label - The object containing the prayer request title.
 * @param {string} props.locationName - The location name.
 * @param {string} props.locationSubtitle - The location subtitle.
 * @param {string} props.pastorImage - The pastor image source URL.
 * @param {string} props.pastorName - The pastor name.
 * @param {string} props.phone - The location phone number.
 * @param {object} props.primaryAddress - The location primary address object.
 * @param {object} props.secondaryAddress - The location secondary address object.
 * @param {object} props.specialEventData - The location special event data.
 * @param {object} props.specialEventTimes - The location special event times data.
 * @param {object} props.weeklyEventTimes - The location weekly event times data.
 *
 * @returns {React.ReactElement} The Poster component.
 */
export default function Poster({
  additionalInfo,
  campusCode,
  email,
  facebook,
  handleOpenModal,
  hasSpecialEvent,
  instagram,
  label,
  locationName,
  locationSubtitle,
  pastorImage,
  pastorName,
  phone,
  primaryAddress,
  secondaryAddress,
  specialEventData,
  specialEventTimes,
  weeklyEventTimes,
}) {
  const { user } = useAuth();
  const specialEventTitle = specialEventData?.label || specialEventData?.name;
  const tabs = [
    { id: 'tab-0', title: specialEventTitle },
    { id: 'tab-1', title: 'Weekly Times' },
  ];

  /* istanbul ignore next */
  const envVarActiveTab =
    process.env.LOCATION_POSTER_ACTIVE_TAB &&
    parseInt(process.env.LOCATION_POSTER_ACTIVE_TAB, 10) >= 0 &&
    parseInt(process.env.LOCATION_POSTER_ACTIVE_TAB, 10) < tabs.length
      ? tabs[parseInt(process.env.LOCATION_POSTER_ACTIVE_TAB, 10)].id
      : tabs[0].id;
  const [activeTab, setActiveTab] = React.useState(envVarActiveTab);
  const prayerUrl = `https://my.life.church/forms/prayer?Location=${campusCode}`;
  const hideLocationSST =
    process.env.LOCATION_HIDE_SST &&
    process.env.LOCATION_HIDE_SST.toString() === 'true';

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} labelValue - The label value for the properties object.
   */
  function handleElementClick(event, labelValue) {
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Poster',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: labelValue || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div className="poster" data-testid="lc-poster">
      <div className="back-to-states-row">
        <a
          className="back-to-states-link"
          href={implementUtmParams(
            `/locations/?open=${getStateNameFromCode(
              primaryAddress.state,
            ).toLowerCase()}`,
            window?.location,
          )}
          onClick={(event) => handleElementClick(event)}
        >
          <span className="icon back-arrow-linear-icon"></span>
          <span>
            {' '}
            View all {getStateNameFromCode(primaryAddress.state)} locations{' '}
          </span>
        </a>
      </div>

      <div className="poster-content container text-center py-normal">
        <h3 className="location">{locationName}</h3>
        <p className="text-gray">{locationSubtitle}</p>
        <div className="display-flex justify-center align-center column-gap-normal mt-normal">
          <LCImage
            className="poster-profile"
            htmlAttributes={{ alt: 'profile' }}
            src={`${pastorImage}`}
          />
          {pastorName ? (
            <div className="poster-name display-flex flex-column justify-center align-flex-start">
              <h2 className="text-weight-bold line-height-normal">
                {pastorName}
              </h2>
              <span className="line-height-normal">Pastor</span>
            </div>
          ) : null}
        </div>
      </div>

      <div className="poster-schedule">
        <h3>Service Times</h3>
        {hideLocationSST ? (
          <WeeklyEventsContent weeklyEventTimes={weeklyEventTimes} />
        ) : (
          <>
            {Boolean(hasSpecialEvent && specialEventData) ? (
              <>
                <Tabs
                  activeTab={activeTab}
                  onClick={(event, tabId) => {
                    setActiveTab(tabId);
                    handleElementClick(event);
                  }}
                  tabs={tabs}
                />
                <TabPanel
                  isHidden={activeTab !== 'tab-0'}
                  tabIdentifier="tab-0"
                >
                  <SpecialEventContent
                    campusCode={campusCode}
                    specialEventData={specialEventData}
                    specialEventTimes={specialEventTimes}
                  />
                </TabPanel>

                <TabPanel
                  isHidden={activeTab !== 'tab-1'}
                  tabIdentifier="tab-1"
                >
                  <WeeklyEventsContent weeklyEventTimes={weeklyEventTimes} />
                </TabPanel>
              </>
            ) : null}
            {weeklyEventTimes && !specialEventData ? (
              <WeeklyEventsContent weeklyEventTimes={weeklyEventTimes} />
            ) : null}
          </>
        )}

        <div className="poster-schedule-separator mt-normal" />
      </div>

      {additionalInfo ? (
        <div className="poster-additional-info px-normal">
          <RichText className="px-none" content={additionalInfo} />
        </div>
      ) : null}

      <div className="text-center mb-normal">
        <Button
          buttonSize="medium"
          className="btn-special-event mx-auto"
          onClick={(event) => handleElementClick(event)}
          style="btn-secondary"
          target="_blank"
          text="Invite a Friend"
          url="https://www.life.church/invite/"
        />
      </div>

      <LocationPosterContact
        campusCode={campusCode}
        email={email}
        facebook={facebook}
        handleOpenModal={handleOpenModal}
        instagram={instagram}
        phone={phone}
        primaryAddress={primaryAddress}
        secondaryAddress={secondaryAddress}
      />

      {label?.prayer_request ? (
        <a
          className="mb-normal display-flex align-center justify-center column-gap-tighter"
          href={implementUtmParams(prayerUrl, window?.location)}
          onClick={(event) => {
            event.preventDefault();
            handleOpenModal(prayerUrl);
            handleElementClick(event);
          }}
        >
          <span className="icon prayer-request-linear-icon"></span>
          {label.prayer_request}
        </a>
      ) : null}
    </div>
  );
}
