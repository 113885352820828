/**
 * @module MenuModalLink
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { stringToSlug } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';

const MenuModalLink = ({ menuItem }) => {
  if (!menuItem) {
    return null;
  }

  const { customLink, openInNewTab, name, navigationExcerpt } = menuItem;
  const field = customLink?.field;
  const target = openInNewTab ? '_blank' : undefined;

  const urlsMap = {
    external: customLink?.external,
    internal: customLink?.internal?.['@path'],
  };
  const url = urlsMap?.[field] || '';

  return (
    <a
      data-menu-id={stringToSlug(name)}
      data-testid="lc-menu-modal-link"
      href={implementUtmParams(url, window?.location)}
      rel="noreferrer"
      target={target}
    >
      {name ? <span className="modal-link-title">{name}</span> : null}
      {name && navigationExcerpt ? <span> </span> : null}
      {navigationExcerpt ? (
        <span className="modal-link-description">{navigationExcerpt}</span>
      ) : null}
    </a>
  );
};

export default MenuModalLink;
