/**
 * @module ContentList
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import OpenContentList from '../OpenContentList/OpenContentList';
import { fetchContentList } from '../../helpers/dataFetchers/contentListFetcher';

const ContentList = (props) => {
  const { sectionId } = props;
  const [media, setMedia] = React.useState(props?.preload);

  React.useEffect(() => {
    async function initial() {
      try {
        const contentList = await fetchContentList(props);
        /* istanbul ignore next */
        setMedia(contentList || {});
      } catch (error) {
        /* istanbul ignore next */
        Log.error(error);
      }
    }
    if (props.preload) {
      setMedia(props.preload);
    } else {
      initial(); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      className="content-list"
      data-testid="lc-content-list"
      id={sectionId}
    >
      {media ? <OpenContentList {...media} /> : null}
    </section>
  );
};

export default ContentList;
