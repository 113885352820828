/**
 * @module MediaAsset
 */
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import LCImage from '../LCImage/LCImage';
import './MediaAsset.scss';

const MediaAsset = ({
  image,
  largeImage,
  alt,
  maxwidth,
  alignment,
  imgWidth,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
}) => {
  const { isMobile } = useWindowSize();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [imgixUrl, setImgixUrl] = React.useState('');
  const mediaAssetRef = React.useRef(null);

  let largeImageUrl = null;
  if (!!largeImage && typeof largeImage === 'string') {
    largeImageUrl = `${largeImage}`;
  } else if (largeImage) {
    largeImageUrl = largeImage['@link'];
  }

  const imageUrl = !!image && !!image['@link'] ? image['@link'] : image;

  React.useEffect(() => {
    function setImgUrl() {
      const url = !isMobile && !!largeImageUrl ? largeImageUrl : imageUrl;
      setImgixUrl(url);
    }
    setImgUrl();
    // Note: Adding dependencies for hook-related state items so resize logics
    // for determining/setting image source is properly utilized and set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // Ignoring due to it referencing state-based const not necessary to cover.
  /* istanbul ignore next */
  const style = {
    display: isLoaded ? '' : 'none',
    maxWidth: `${parseInt(maxwidth, 10)}px`,
  };

  // Ignoring due to its setting of state-based value.
  /* istanbul ignore next */
  const htmlAttributes = {
    alt: alt || image?.metadata?.caption || 'media asset image',
    onLoad: () => setIsLoaded(true),
    style,
  };

  let classNames = 'media-asset-wrapper';
  if (alignment) {
    classNames += ` text-${convertValueToClassName(alignment)}`;
  }
  if (imgWidth) {
    classNames += ` ${convertValueToClassName(imgWidth)}`;
  }
  classNames += ` ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`;
  classNames += ' container';

  return (
    <div
      className={classNames}
      data-testid="lc-media-asset"
      id={sectionId}
      ref={mediaAssetRef}
    >
      {!!imgixUrl && (
        <LCImage
          htmlAttributes={htmlAttributes}
          parentRef={mediaAssetRef}
          src={`${imgixUrl}`}
          width={parseInt(maxwidth, 10)}
        />
      )}
    </div>
  );
};

export default MediaAsset;
