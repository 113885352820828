/**
 * @module PrelaunchedBannerV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../../ButtonItem/ButtonItem';
import Spans from '../../IconSpans/Spans';
import { ACTIONS, EVENTS } from '../../../helpers/constants';

/**
 * Represents the new Banner which will be sticky.
 *
 * @param {object} props - The component props object.
 * @param {string} props.facebook - Life.Church Facebook id of the current campus.
 * @param {Function} props.handleOpenModal - Handler function for modal open event.
 * @param {string} props.instagram - Life.Church Instagram username of the current campus.
 * @param {LocationData} props.locationData - The location data object.
 *
 * @returns {React.ReactElement} The PrelaunchedBannerV2 component.
 */
export default function PrelaunchedBannerV2({
  facebook,
  handleOpenModal,
  instagram,
  locationData,
}) {
  const { user } = useAuth();

  /**
   * Convenience function to trigger analytics tracking call.
   *
   * @param {Event} event - The event object associated with the click.
   * @param {string} label - The label value for the properties object.
   */
  function callAnalytics(event, label) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Pre-Launched - Banner',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  // Handler Functions.
  function handleButtonClick(event) {
    if (handleOpenModal) {
      handleOpenModal(event);
    }
    callAnalytics(event);
  }

  function handleSocialClick(event, socialProvider) {
    callAnalytics(event, socialProvider);
  }

  return (
    <section
      className="prelaunched-banner"
      data-testid="lc-prelaunched-banner-v2"
    >
      <div className="banner-title">
        <h2>{locationData.title}</h2>
      </div>
      <div className="banner-btns">
        <ButtonItem
          buttonSize="medium"
          className="btn-banner"
          darkVariant="true"
          onClick={handleButtonClick}
          style="btn-primary"
          target=""
          text="Send Me Updates"
          url="#"
        />

        <a
          className="social-link"
          href={`https://www.facebook.com/${facebook}`}
          name="Facebook"
          onClick={(event) => {
            handleSocialClick(event, 'Facebook');
          }}
          rel="noreferrer"
          target="_blank"
        >
          <span className="icon icon-facebook">
            <Spans />
          </span>
        </a>

        <a
          className="social-link"
          href={`https://www.instagram.com/${instagram}`}
          name="Instagram"
          onClick={(event) => {
            handleSocialClick(event, 'Instagram');
          }}
          rel="noreferrer"
          target="_blank"
        >
          <span className="icon icon-instagram">
            <Spans />
          </span>
        </a>
      </div>
    </section>
  );
}
