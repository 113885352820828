/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module MediaCollection
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import MediaCollectionSeriesBased from './Template/MediaCollectionSeriesBased';
import MediaCollectionEpisodeBased from './Template/MediaCollectionEpisodeBased';
import MediaCollectionAlbumSongBased from './Template/MediaCollectionAlbumSongBased';
import MediaCollectionPodcastBased from './Template/MediaCollectionPodcastBased';
import MediaCollectionEarlyChildhoodBased from './Template/MediaCollectionEarlyChildhoodBased';
import { fetchMediaCollection } from '../../helpers/dataFetchers/mediaCollectionFetcher';
import { MEDIA_TYPES } from '../../helpers/constants';

const MediaCollection = (props) => {
  const [data, setData] = React.useState(props?.preload || {});
  React.useEffect(() => {
    async function fetchData() {
      try {
        const mediaCollectionData = await fetchMediaCollection(props);
        setData(mediaCollectionData);
      } catch (error) {
        /* istanbul ignore next */
        Log.error(error);
      }
    }

    if (props.preload) {
      setData(props.preload);
    } else {
      fetchData(); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="media-collection" data-testid="lc-media-collection">
      {data?.mediaCollectionData && data?.type ? (
        <>
          {data.type === MEDIA_TYPES.SERIES ? (
            <MediaCollectionSeriesBased
              data={data.mediaCollectionData}
              labelList={data.labelList}
              pagination={data.pagination}
              preload={data.collectionTypeSpecificData}
            />
          ) : null}
          {data.type === MEDIA_TYPES.EPISODE ? (
            <MediaCollectionEpisodeBased
              data={data.mediaCollectionData}
              labelList={data.labelList}
              pagination={data.pagination}
              preload={data.collectionTypeSpecificData}
            />
          ) : null}
          {data.type === MEDIA_TYPES.ALBUM_SONG ? (
            <MediaCollectionAlbumSongBased
              data={data.mediaCollectionData}
              labelList={data.labelList}
              preload={data.collectionTypeSpecificData}
            />
          ) : null}
          {data.type === MEDIA_TYPES.PODCAST ? (
            <MediaCollectionPodcastBased
              {...data.mediaCollectionData}
              labelList={data.labelList}
              pagination={data.pagination}
              preload={data.collectionTypeSpecificData}
            />
          ) : null}
          {data.type === MEDIA_TYPES.EARLY_CHILDHOOD ? (
            <MediaCollectionEarlyChildhoodBased
              data={data.mediaCollectionData}
              labelList={data.labelList}
              pagination={data.pagination}
              preload={data.collectionTypeSpecificData}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default MediaCollection;
