/**
 * @module MediaDetailPodcastBased
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import { getLabelValue } from '@io/web-tools-io/dist/utils/helpers/magnolia/labels';
import PodcastAccordion from '../../MediaSharedComponents/PodcastAccordion';
import PodcastContentAndSubscribe from '../../MediaSharedComponents/PodcastContentAndSubscribe';
import PodcastFilter from '../../MediaSharedComponents/PodcastFilter';
import PodcastHero from '../../MediaSharedComponents/PodcastHero';
import PodcastLeadGenerationForm from '../../MediaSharedComponents/PodcastLeadGenerationForm';
import '../../MediaSharedComponents/style/_MediaComponentSharedStyle.scss';

const MediaDetailPodcastBased = (props) => {
  const { labelList, mediaItemPodcastBased } = props;
  const { isMobile } = useWindowSize();

  return (
    <section
      className="media-detail-podcast-based"
      data-testid="lc-media-detail-podcast-based"
    >
      <PodcastHero {...props} isMobile={isMobile} />
      <PodcastContentAndSubscribe
        {...props}
        isMobile={isMobile}
        label={{
          subscribeTitle: getLabelValue(labelList, 'listen_and_subscribe_on'),
        }}
        resourceLabels={mediaItemPodcastBased?.resourceLabels}
      />
      <PodcastLeadGenerationForm labelList={labelList} />
      <PodcastFilter />
      <PodcastAccordion
        {...props}
        isMobile={isMobile}
        label={{
          more_media_title: getLabelValue(labelList, 'more_media_title'),
        }}
        pagination={mediaItemPodcastBased?.pagination}
        preload={mediaItemPodcastBased?.accordionData}
      />
    </section>
  );
};

export default MediaDetailPodcastBased;
