/**
 * @module SpecialEventTimes
 */
import React from 'react';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { DAYS_OF_WEEK } from '@io/web-tools-io/dist/utils/helpers';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import _, { isEmpty, split } from 'lodash';
import { ACTIONS, EVENTS, MGNL_ENV_VARS } from '../../helpers/constants';
import Spans from '../IconSpans/Spans';
import LCImage from '../LCImage/LCImage';
import LocationPosterSpan from '../LocationPoster/LocationPosterSpan';
import LocationPosterAddress from './LocationPoster/LocationPosterAddress';
import LocationPosterPhone from './LocationPoster/LocationPosterPhone';
import './SpecialEventTimes.scss';

const SpecialEventTimes = ({
  churchOnlineDesc,
  churchOnlineLink,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  specialEvent,
}) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const [isChurchOnline, setIsChurchOnline] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [eventTimes, setEventTimes] = React.useState([]);
  const [specialEventData, setSpecialEventData] = React.useState(null);
  const [selectedLocationData, setSelectedLocationData] = React.useState('');
  const [btnUrl, setBtnUrl] = React.useState('');
  const [fakeEvent, setFakeEvent] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [stateLocations, setStateLocations] = React.useState({});
  const [churchOnlineLocation, setChurchOnlineLocation] = React.useState({});

  /**
   * Convenience function to trigger analytics events.
   *
   * @param {object} params - The function params object.
   * @param {Event} params.event - The Event object associated with the analytics call.
   * @param {object} params.properties - The properties/parameters object data to assign to the analytics event data.
   */
  /* istanbul ignore next */
  function callAnalytics({ event, properties }) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.changed,
        component: 'Special Event Times - Campus Dropdown',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: properties.segment.label,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        ...properties.segment,
      },
    });

    /* istanbul ignore next */
    callGtagEvent({
      parameters: {
        ...properties.gtag,
      },
      type: 'click',
    });
  }

  /**
   * Handler function for location link click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLocationLinkClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Special Event Times - Location Poster',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    /* istanbul ignore next */
    callGtagEvent({
      parameters: {
        event_category: specialEventData.name.trim(),
        event_label: `Location Poster`,
        value: event?.currentTarget?.textContent,
      },
      type: 'click',
    });
  }

  /**
   * Handler function for select element change.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  const onSelectChange = (event) => {
    const currentValue = event.target.value;

    if (currentValue === '') {
      setSelectedValue('');
      setSelectedLocationData({});
      setEventTimes([]);
      setIsChurchOnline(false);
    } else {
      const obj = locations.find((item) => item['@id'] === currentValue);
      /* istanbul ignore next*/
      if (obj) {
        const { hasSpecialEvent } = obj;
        const special_event = hasSpecialEvent.specialEvent;

        special_event?.['@nodes']?.forEach((i) => {
          /* istanbul ignore next*/
          if (special_event[i]?.event === specialEventData['@id']) {
            const { eventTimes: specialEventTimes } = special_event[i];
            const arr = (specialEventTimes?.['@nodes'] ?? []).map(
              (node) => specialEventTimes[node],
            );
            setEventTimes(arr);
          }
        });
      }

      setSelectedValue(obj['@id']);
      setSelectedLocationData(obj);

      if (obj.name === 'Life.Church Online') {
        setIsChurchOnline(true);
        setBtnUrl(churchOnlineLink);
        callAnalytics({
          event,
          properties: {
            gtag: {
              event_category: specialEventData.name.trim(),
              event_label: 'Campus Dropdown Life.Church Online',
            },
            segment: {
              label: 'Campus Dropdown Life.Church Online',
            },
          },
        });
      } else {
        setIsChurchOnline(false);
        setBtnUrl(`/${obj.slug}`);
        const { cityOrTown, primaryAddress, state } = obj;
        const cityValue = primaryAddress?.cityOrTown || cityOrTown;
        const stateValue = primaryAddress?.state || state;

        callAnalytics({
          event,
          properties: {
            gtag: {
              event_category: specialEventData.name.trim(),
              event_label: `Campus Dropdown ${cityValue}, ${stateValue}`,
            },
            segment: {
              label: `${cityValue}, ${stateValue}`,
            },
          },
        });
      }
    }
  };

  async function getLocationsWithSpecialEvents(eventId) {
    if (eventId) {
      try {
        const locationsResponse = await fetch(
          encodeURI(`${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/location`),
        );
        const json = await locationsResponse.json();
        const locationsResults = json?.results ?? [];
        const filteredLocations = locationsResults.filter((item) => {
          const { hasSpecialEvent } = item;
          if (hasSpecialEvent?.field?.toString() === 'true') {
            const special_event = hasSpecialEvent?.specialEvent;
            const obj = special_event?.['@nodes']?.find(
              (node) => special_event[node].event === eventId,
            );
            return obj;
          }
          return false;
        });
        return filteredLocations;
      } catch (error) {
        Log.error(error);
        return [];
      }
    }
    return [];
  }

  React.useEffect(() => {
    async function fetchSpecialEventAndLocation() {
      /**
       * Note: The specialEvent/${specialEvent} endpoint returns 404 when the
       * value provided as `specialEvent` is a UUID value and not a string, such
       * as 'Easter' or 'Christmas'. As such, if a UUID is provided, there is a
       * need to use the `jcr:uuid` filter to return proper results.
       *
       * Also, the RegEx used is from a supported and working answer from a
       * StackOverflow question, and checks for proper UUID syntax. This is used
       * since our version of the npm `uuid` package is a deprecated, legacy
       * version that introduces many more errors when updated to a more modern
       * one. As such, using this to avoid a much larger development effort of a
       * project-wide update of all various places `uuid` is used.
       *
       * Endpoints:
       * • String-based: `${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/specialEvent/${specialEvent}`.
       * • UUID-based: `${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/specialEvent?jcr:uuid=${specialEvent}`.
       *
       * References:
       * • https://docs.magnolia-cms.com/headless/api/delivery.html#_filter_types.
       * • https://stackoverflow.com/a/7905992/1914233.
       */
      try {
        const isUuid =
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
            specialEvent,
          );
        const specialEventPath = isUuid
          ? `?jcr:uuid=${specialEvent}`
          : `/${specialEvent}`;
        const response = await fetch(
          encodeURI(
            `${getAPIBase(
              MGNL_ENV_VARS,
            )}/.rest/delivery/specialEvent${specialEventPath}`,
          ),
        );
        const data = await response.json();
        const resultEventData = isUuid
          ? data?.results?.find(
              (eventData) => eventData['@id'] === specialEvent,
            )
          : data;
        if (resultEventData) {
          const filteredLocation = await getLocationsWithSpecialEvents(
            resultEventData['@id'],
          );
          const groupedLocations = _.groupBy(
            filteredLocation,
            (l) => split(l['@path'], '/')[1],
          );
          const churchOnlineFiltered = filteredLocation.find(
            (item) => item.name === 'Life.Church Online',
          );

          setChurchOnlineLocation(churchOnlineFiltered);
          setLocations(filteredLocation);
          setStateLocations(groupedLocations);
          setSpecialEventData(resultEventData);

          /**
           * To support multiple query params, iterate over the urlParams.keys()
           * and compare against supported query param keys array. If match is
           * found, set `hasCampusCode` and `campusCode` accordingly.
           */
          const urlParams = new URLSearchParams(window.location.search);
          const urlParamsKeys = new Set([...urlParams.keys()]);
          const supportedKeys = ['campus', 'location'];
          let hasCampusCode = false;
          let campusCode;
          urlParamsKeys.forEach((urlKey) => {
            if (supportedKeys.includes(urlKey)) {
              hasCampusCode = true;
              campusCode = urlParams.get(urlKey);
            }
          });
          if (hasCampusCode) {
            const filteredCampusLocation = filteredLocation.filter(
              (location) => {
                return (
                  campusCode.toLowerCase() === location.campusCode.toLowerCase()
                );
              },
            );
            if (filteredCampusLocation.length) {
              const nodeToAdd = {
                target: { value: filteredCampusLocation[0]['@id'] },
              };
              setFakeEvent(nodeToAdd);
              setSelectedValue(filteredCampusLocation[0]['@id']);
            }
          }
        }
      } catch (error) {
        /* istanbul ignore next */
        Log.error(error);
      }
    }

    fetchSpecialEventAndLocation(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (locations.length > 0 && !!specialEventData && !!fakeEvent) {
      onSelectChange(fakeEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, specialEventData, fakeEvent]);

  const getDateString = (item) => {
    return !!DAYS_OF_WEEK[item.dow] && item.date
      ? `${DAYS_OF_WEEK[item.dow]}, ${item.date}`
      : DAYS_OF_WEEK[item.dow] || item.date || '';
  };

  const showChurchOnline = () => {
    if (churchOnlineLocation) {
      return (
        <option value={churchOnlineLocation['@id']}>
          {churchOnlineLocation.name}
        </option>
      );
    }
    return null;
  };

  /**
   * Handler function for button item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const onButtonClick = (event) => {
    const { cityOrTown, primaryAddress, state } = selectedLocationData;
    const cityValue = primaryAddress?.cityOrTown || cityOrTown;
    const stateValue = primaryAddress?.state || state;
    const description = isChurchOnline
      ? 'Life.Church Online'
      : /* istanbul ignore next */ `${cityValue}, ${stateValue}`;

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Special Event Times - Button',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    callGtagEvent({
      parameters: {
        event_category: `${specialEventData.name.trim()} Times`,
        event_label: `Campus CTA ${description}`,
      },
      type: 'click',
    });
  };

  const showOtherLocations = () => {
    delete stateLocations['Life.Church-Online']; // NOSONAR
    /* istanbul ignore next */
    const sortCitiesByName = (a, b) => {
      if (a.name.trim() < b.name.trim()) {
        return -1;
      }
      if (a.name.trim() > b.name.trim()) {
        return 1;
      }
      return 0;
    };
    return (
      !!stateLocations &&
      Object.keys(stateLocations)
        .sort() // NOSONAR
        .map((state) => {
          const sortedCities = stateLocations[state].sort(sortCitiesByName);
          return (
            <optgroup key={state} label={state.replace(/-/g, ' ')}>
              {sortedCities.map((campus) => {
                return (
                  <option key={campus['@id']} value={campus['@id']}>
                    {campus.name}
                  </option>
                );
              })}
            </optgroup>
          );
        })
    );
  };

  const buttonText = `${
    isChurchOnline
      ? 'Attend Life.Church Online'
      : `About Life.Church ${selectedLocationData.name}`
  }`;
  const containerClass = `special-event-times ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`;

  const hideDropdown = isEmpty(stateLocations) && isEmpty(churchOnlineLocation);

  return (
    <>
      <div
        className={containerClass}
        data-testid="lc-specialeventtimes"
        id={sectionId}
      >
        {!hideDropdown ? (
          <select
            className="location-select"
            name="location-select"
            onChange={onSelectChange}
            value={selectedValue}
          >
            <option value="">Choose a Location</option>
            {showChurchOnline()}
            {showOtherLocations()}
          </select>
        ) : null}
        {(selectedLocationData && eventTimes?.length) || !!isChurchOnline ? (
          <div className="special-events px-normal pb-normal pt-tight">
            <div className="content-wrapper special-events-wrapper">
              <div className="event-subcontainer">
                {isChurchOnline ? (
                  <p className="church-online-desc mb-tighter text-italic text-center text-paragraph_small">
                    {churchOnlineDesc}
                  </p>
                ) : null}
                {eventTimes.map((item) => (
                  <div
                    className="special-events-item pb-normal mt-normal"
                    key={item['@id']}
                  >
                    <p className="date text-weight-bold mb-more_slight_tight mt-none line-height-tightest">
                      {getDateString(item)}
                    </p>
                    <div className="poster-event-times" key={item['@id']}>
                      {item.time.split(',').map((eventTime, index) => (
                        <div
                          className="poster-event-time"
                          key={`${eventTime}-${index}`}
                        >
                          {eventTime.replace(/&/g, '').trim()}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="content-wrapper">
              <div
                className="location-data-item"
                data-testid="set-location-address-phone"
              >
                {selectedLocationData.primaryAddress ? (
                  <div className="location-data display-flex flex-row mb-normal">
                    <span className="icon-marker-filled">
                      <Spans />
                    </span>
                    <LocationPosterAddress
                      address={selectedLocationData.primaryAddress}
                      onClick={handleLocationLinkClick}
                    />
                  </div>
                ) : null}
                {/* Note: Ignore added since test data set only includes `secondaryStreet1` instance on `secondaryAddress` data object, but both added as a fail-safe logical check. */}
                {/* Also only checking for {secondaryStreet1|street1} on `secondaryStreet` since if that is not set, the secondary address is not needed and fully populated. */}
                {
                  /* istanbul ignore next */ selectedLocationData
                    .secondaryAddress?.secondaryStreet1 ||
                  selectedLocationData.secondaryAddress?.street1 ? (
                    <div className="location-data display-flex flex-row mb-normal">
                      <span className="icon-about-filled">
                        <Spans />
                      </span>
                      <div>
                        <LocationPosterSpan
                          addLineBreak={true}
                          className={'text-weight-bold'}
                          text="Weekday Contact"
                        />
                        <LocationPosterAddress
                          address={{
                            ...selectedLocationData.secondaryAddress,
                            cityOrTown:
                              selectedLocationData.secondaryAddress
                                .secondaryCityOrTown ||
                              selectedLocationData.secondaryAddress.cityOrTown,
                            latitude:
                              selectedLocationData.secondaryAddress
                                .secondaryLatitude ||
                              selectedLocationData.secondaryAddress.latitude,
                            longitude:
                              selectedLocationData.secondaryAddress
                                .secondaryLongitude ||
                              selectedLocationData.secondaryAddress.longitude,
                            state:
                              selectedLocationData.secondaryAddress
                                .secondaryState ||
                              selectedLocationData.secondaryAddress.state,
                            street1:
                              selectedLocationData.secondaryAddress
                                .secondaryStreet1 ||
                              selectedLocationData.secondaryAddress.street1,
                            street2:
                              selectedLocationData.secondaryAddress
                                .secondaryStreet2 ||
                              selectedLocationData.secondaryAddress.street2,
                            zip:
                              selectedLocationData.secondaryAddress
                                .secondaryZip ||
                              selectedLocationData.secondaryAddress.zip,
                          }}
                          enableAsLink={false}
                          onClick={handleLocationLinkClick}
                        />
                      </div>
                    </div>
                  ) : null
                }
                {selectedLocationData.phone ? (
                  <div className="location-data display-flex flex-row">
                    <span className="icon-phone-filled">
                      <Spans />
                    </span>
                    <LocationPosterPhone
                      onClick={handleLocationLinkClick}
                      phone={selectedLocationData.phone}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {selectedLocationData.pastorName ? (
              <div className="content-wrapper">
                <div
                  className="location-data-item"
                  data-testid="set-location-pastor"
                >
                  <div className="display-flex justify-left align-center column-gap-normal mb-normal">
                    {selectedLocationData.pastorImage ? (
                      <LCImage
                        className="poster-profile"
                        htmlAttributes={{ alt: 'profile' }}
                        src={`${selectedLocationData.pastorImage}`}
                        width={80}
                      />
                    ) : null}
                    <div className="poster-name display-flex flex-column justify-left align-flex-start">
                      <h2 className="text-weight-bold line-height-normal">
                        {selectedLocationData.pastorName}
                      </h2>
                      <span className="line-height-normal">Pastor</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="content-wrapper pt-normal">
              <a
                className="btn btn-secondary size-medium align-center"
                data-testid="set-button-link"
                href={implementUtmParams(btnUrl, window?.location)}
                onClick={onButtonClick}
                rel="noreferrer"
                target={isChurchOnline ? '_blank' : '_self'}
              >
                {buttonText}
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SpecialEventTimes;
