/**
 * @module LocationConnectLocally
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import '../OpenContentList/OpenContentList.scss';

const LocationConnectLocally = (props) => {
  const { user } = useAuth();
  const { campusCode, label, name } = props;

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Connect Locally Button',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div
      className="py-very_relaxed py-tablet-even_extra_more_relaxed py-laptop-even_more_relaxed bg-gray-5"
      data-testid="lc-location-connect-locally"
    >
      {label?.connect_locally_desc || label?.connect_locally_title ? (
        <div className="maxWidth-768 text-center container mx-auto mb-slightly_more_relaxed mb-laptop-very_relaxed">
          {label.connect_locally_title ? (
            <h2 className="heading container rich-text text-section_header text-black text-center mb-relaxed">
              {label.connect_locally_title}
            </h2>
          ) : null}
          {label.connect_locally_desc ? (
            <p className="text-feature_paragraph">
              {label.connect_locally_desc}
            </p>
          ) : null}
        </div>
      ) : null}

      <div className="maxWidth-1026 container mx-auto display-flex justify-center flex-column md-flex-row gap-normal">
        {campusCode && name ? (
          <>
            <ButtonItem
              buttonSize="medium"
              onClick={handleButtonClick}
              style="btn-primary"
              target="_self"
              text={`Search ${name} Local Partners`}
              url={`/lifegroups/search/?campus=${campusCode}&groupTypes=local-partner`}
            />

            <ButtonItem
              buttonSize="medium"
              onClick={handleButtonClick}
              style="btn-primary"
              target="_self"
              text={`Search ${name} Neighbor Groups`}
              url={`/lifegroups/search/?campus=${campusCode}&categories[0]=neighbor&groupTypes=lifegroups`}
            />
          </>
        ) : null}
        <ButtonItem
          buttonSize="medium"
          onClick={handleButtonClick}
          style="btn-secondary"
          target="_self"
          text="Learn More"
          url="/missions/"
        />
      </div>
    </div>
  );
};

export default LocationConnectLocally;
