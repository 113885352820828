/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
/**
 * @module Segment
 */
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';

/**
 * Convenience function to load and initialize Segment for Analytics tracking.
 *
 * @param {object} params - The function params object.
 * @param {boolean} [params.autoLoad] - Optional boolean flag denoting whether or not to force auto-load Segment. If false, will be dependent on Consent Manager inclusion.
 * @param {SegmentPageData} [params.pagePayload] - Optional object of Segment page payload data to send with the `.page()` method. The payload object supports the following properties: `category` (string), `name` (string), and `properties` (object).
 */
export function loadSegment({ autoLoad = true, pagePayload = null } = {}) {
  const analytics = (window.analytics = window.analytics || []);

  if (!analytics.initialize) {
    if (analytics.invoked) {
      Log.error(new Error('Segment snippet included twice.'));
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e) {
        return function () {
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };

      analytics.methods.forEach((method) => {
        analytics[method] = analytics.factory(method);
      });

      analytics.load = function (key, e) {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = `${process.env.SEGMENT_URL}${key}/analytics.min.js`;
        const n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };

      /**
       * Note: Per instructions on the Consent Manager GitHub page, the line:
       * analytics.load(process.env.SEGMENT_KEY);
       * is conditionally included so when the consent manager is used, it can
       * manage the loading process. Otherwise, with no consent manager in use,
       * the .load() call is still triggered and will work properly.
       *
       * Source: https://github.com/segmentio/consent-manager#usage}.
       */
      analytics._writeKey = process.env.SEGMENT_KEY;
      analytics.SNIPPET_VERSION = '4.13.2';
      if (autoLoad) {
        analytics.load(process.env.SEGMENT_KEY);
      }
      analytics.page(
        pagePayload && typeof pagePayload === 'object' ? pagePayload : null,
      );
    }
  }
}
