/**
 * @module LocationDetail
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { convertS3DamItems } from '@io/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { getLabelList } from '@io/web-tools-io/dist/utils/helpers/magnolia/labels';
import LocationPrelaunched from '../LocationPrelaunched/LocationPrelaunched';
import LocationLaunched from '../LocationLaunched/LocationLaunched';
import Banner from '../Banner/Banner';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS } from '../../helpers/constants';
import './LocationDetail.scss';

const LocationDetail = (props) => {
  const [data, setData] = React.useState(props.name ? props : false);
  const [locationBanners, setLocationBanners] = React.useState([]);
  const [labelList, setLabelList] = React.useState(
    props.labelList ? props.labelList : [],
  );
  React.useEffect(() => {
    async function fetchingData() {
      try {
        const params = window.location.search
          .slice(1)
          .split('&')
          .map((p) => p.split('='))
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
        const hasPreviewSlug = !!params && !!params.previewSlug;
        const response = await fetch(
          `${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/location${
            hasPreviewSlug
              ? `/${params.previewSlug}`
              : `?jcr:uuid=${props.locationID}`
          }`,
        );
        const lc = await response.json();
        const tmpData = hasPreviewSlug
          ? !!lc && convertS3DamItems(lc, process.env.IMGIX_BASE_URL)
          : !!lc.results.length &&
            convertS3DamItems(lc.results[0], process.env.IMGIX_BASE_URL);
        /* istanbul ignore next */
        if (tmpData) {
          const locationPhasesMap = {
            Launched: 'launched',
            'Pre-Launch': 'pre_launch',
          };
          const phaseField = tmpData?.phase?.field;
          const tempType = locationPhasesMap[phaseField] ?? phaseField ?? '';

          const [tempArr] = await Promise.all([
            getLabelList({
              ancestor: `location_${tempType}`,
              mgnlEnvVars: MGNL_ENV_VARS,
              workspaceMap: ENDPOINT_WORKSPACE_MAP,
            }),
          ]);
          if (tempArr.length) {
            setLabelList(tempArr);
          }
          setData(tmpData);
        }
      } catch (error) {
        Log.error(error);
      }
    }

    async function getBanner() {
      try {
        const response = await fetch(
          `${getAPIBase(
            MGNL_ENV_VARS,
          )}/.rest/delivery/globalLocationBanner?location=${props.locationID}`,
        );
        const globalLocationsBanner = await response.json();
        setLocationBanners(globalLocationsBanner?.results ?? []);
      } catch (err) {
        Log.error(err);
      }
    }

    if (!data) {
      fetchingData(); // NOSONAR
    }
    getBanner(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="location-template" data-testid="lc-location-detail">
      {(!props.banner || props.banner.field === 'no') &&
        locationBanners.length > 0 &&
        locationBanners.map((banner) => (
          <Banner {...banner} key={banner['@id']} />
        ))}
      {!!props.banner && props.banner.field === 'yes' && (
        <Banner {...props.banner} />
      )}
      {!!data && !!data.phase && data.phase.field === 'Launched' && (
        <LocationLaunched {...data} labelList={labelList} />
      )}
      {!!data && !!data.phase && data.phase.field === 'Pre-Launch' && (
        <LocationPrelaunched {...data} labelList={labelList} />
      )}
    </section>
  );
};

export default LocationDetail;
