/* eslint-disable jsx-a11y/aria-role */
/**
 * @module MediaThumbnail
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { normalizeTimestamp } from '@io/web-tools-io/dist/utils/helpers/date';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import LCBackground from '../LCImage/LCBackground';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './MediaThumbnail.scss';

const MediaThumbnail = (props) => {
  const {
    alt,
    className,
    collectionType,
    image,
    partNumber,
    partTitle,
    target,
    title,
    url,
    ...passThroughProps
  } = props;
  const { user } = useAuth();
  let { timestamp } = props;

  timestamp = !!timestamp && normalizeTimestamp(timestamp);
  const Tag = url ? 'a' : 'div';
  const bgImg = image?.['@link'] ?? image ?? '';

  const providedPartTitle = partTitle ?? 'PART';
  const partNumberTitle = ['leadershippodcast'].includes(collectionType)
    ? 'EPISODE'
    : providedPartTitle;

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media Thumbnail',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <Tag
      className={`media-thumbnail ${className || ''}`.trim()}
      data-testid="lc-media-thumbnail"
      href={`${url ? implementUtmParams(url, window?.location) : null}`}
      onClick={handleElementClick}
      target={target || '_self'}
      {...passThroughProps}
    >
      <div
        className="media-image"
        data-testid="lc-media-image"
        itemProp="thumbnail"
      >
        <LCBackground
          aria-label={alt}
          backup={'message-thumbnail-img'}
          className="image"
          role="image"
          src={bgImg}
        >
          {timestamp ? (
            <span className="media-timestamp">{timestamp}</span>
          ) : null}
        </LCBackground>
      </div>
      <div className="media-content">
        <h3
          className="media-title"
          data-testid="lc-media-title"
          itemProp="headline"
        >
          {title}
        </h3>
        {partNumber ? (
          <h5
            className="part-number text-weight-normal"
            data-testid="lc-part-number-label"
            itemProp="episodeNumber"
          >
            {partNumberTitle} {partNumber}
          </h5>
        ) : null}
      </div>
    </Tag>
  );
};

export default MediaThumbnail;
