/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module MediaDetail
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import MediaDetailSeriesBased from './Template/MediaDetailSeriesBased';
import MediaDetailEpisodeBased from './Template/MediaDetailEpisodeBased';
import MediaDetailPodcastBased from './Template/MediaDetailPodcastBased';
import MediaDetailAlbumSongBased from './Template/MediaDetailAlbumSongBased';
import MediaDetailEarlyChildhoodBased from './Template/MediaDetailEarlyChildhoodBased';
import { fetchMediaItem } from '../../helpers/dataFetchers/mediaDetailFetcher';
import { MEDIA_TYPES, PAGINATION_OPTIONS } from '../../helpers/constants';

const MediaDetail = (props) => {
  const [data, setData] = React.useState(props?.preload || {});
  React.useEffect(() => {
    async function fetchData() {
      try {
        const limit =
          props.preload?.pagination?.limit ||
          PAGINATION_OPTIONS.mediaDetail.limit;
        const offset =
          props.preload?.pagination?.offset ||
          PAGINATION_OPTIONS.mediaDetail.offset;
        const mediaDetailData = await fetchMediaItem({
          ...props,
          limit,
          offset,
        }); // NOSONAR
        setData(mediaDetailData);
      } catch (error) {
        /* istanbul ignore next */
        Log.error(error);
      }
    }

    if (props.preload) {
      setData(props.preload);
    } else {
      fetchData(); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    mediaItemData,
    type,
    mediaTypeSpecificData,
    pagination,
    collectionData,
    labelList,
  } = data;

  return (
    <div className="media-detail" data-testid="lc-media-detail">
      {mediaItemData && type ? (
        <>
          {type === MEDIA_TYPES.SERIES ? (
            <MediaDetailSeriesBased
              collectionData={collectionData}
              mediaItemData={mediaItemData}
              mediaItemSeriesBased={mediaTypeSpecificData}
              pagination={pagination}
            />
          ) : null}
          {type === MEDIA_TYPES.SONG ? (
            <MediaDetailAlbumSongBased
              collectionData={collectionData}
              mediaItemAlbumSongBased={mediaTypeSpecificData}
              mediaItemData={mediaItemData}
              pagination={pagination}
            />
          ) : null}
          {type === MEDIA_TYPES.EPISODE ? (
            <MediaDetailEpisodeBased
              collectionData={collectionData}
              mediaItemData={mediaItemData}
              mediaItemEpisodeBased={mediaTypeSpecificData}
              pagination={pagination}
            />
          ) : null}
          {type === MEDIA_TYPES.PODCAST ? (
            <MediaDetailPodcastBased
              {...mediaItemData}
              collectionData={collectionData}
              labelList={labelList}
              mediaItemPodcastBased={mediaTypeSpecificData}
              pagination={pagination}
            />
          ) : null}
          {type === MEDIA_TYPES.EARLY_CHILDHOOD ? (
            <MediaDetailEarlyChildhoodBased
              collectionData={collectionData}
              labelList={labelList}
              mediaItemData={mediaItemData}
              mediaItemEarlyChildhoodBased={mediaTypeSpecificData}
              pagination={pagination}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default MediaDetail;
