/**
 * @module Video
 */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import {
  convertToCmsAssetUrl,
  replaceMagnoliaAssetsToImgix,
} from '@io/web-tools-io/dist/utils/helpers/magnolia/imgix';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import YoutubeVideo from '../YoutubeVideo/YoutubeVideo';
import BitmovinPlayer from '../BitmovinPlayer/BitmovinPlayer';
import './Video.scss';

/**
 * Represents a container with a video playback element. Based on the props that
 * are passed in to the component, the video can be a YouTube embed (if a
 * `youtubeID` is specified) or Bitmovin player.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} The Video component.
 */
const Video = (props) => {
  const {
    alignment = '',
    autoPlay,
    bitmovinUrl,
    internalVideo,
    maxWidth,
    posterImage,
    sbOnMobile = '',
    sbOnTabletAndUp = '',
    sectionId,
    videoUrl,
    youtubeID,
  } = props;

  const { isMobile } = useWindowSize();
  const id = sectionId || `video-${uuidv4()}`;
  const isAutoplay =
    autoPlay === 'true' || (autoPlay === 'false' ? false : autoPlay);

  const [poster, setPoster] = React.useState('');
  /* istanbul ignore next */
  const mediaDetails = { id, title: document?.title?.split('|')[0] ?? '' };
  const [finalVideoUrl, setFinalVideoUrl] = React.useState(null);

  /**
   * Handler function to set video poster image using Imgix.
   */
  /* istanbul ignore next */
  const posterImgix = async () => {
    try {
      const imgixPoster = await replaceMagnoliaAssetsToImgix(
        posterImage,
        process.env.IMGIX_BASE_URL,
      );
      setPoster(imgixPoster);
    } catch (error) {
      Log.error(error);
    }
  };

  /**
   * Convenience callback to determine final video URL based on conversion to
   * CMS Assets URL rather than Imgix.
   */
  const convertUrl = React.useCallback(async (url) => {
    try {
      const cmsAssetUrl = await convertToCmsAssetUrl({
        cmsAssetsBaseUrl: process.env.CMS_ASSETS_BASE_URL,
        imgixBaseUrl: process.env.IMGIX_BASE_URL,
        source: url,
      });
      setFinalVideoUrl(cmsAssetUrl);
    } catch (error) {
      /* istanbul ignore next */
      Log.error(error);
    }
  }, []);

  /**
   * Single-run convenience effect to set the video poster image and video src.
   */
  React.useEffect(() => {
    posterImgix(); // NOSONAR
    if (bitmovinUrl) {
      convertUrl(bitmovinUrl);
    } else if (videoUrl || internalVideo) {
      convertUrl(videoUrl ?? internalVideo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let finalClassName = `video mx-auto`;
  if (alignment) {
    finalClassName = `${finalClassName} ${convertValueToClassName(
      alignment,
    )}`.trim();
  }
  if (isMobile && sbOnMobile) {
    finalClassName = `${finalClassName} ${convertValueToClassName(
      sbOnMobile,
    )}`.trim();
  } else if (sbOnTabletAndUp) {
    finalClassName = `${finalClassName} ${convertValueToClassName(
      sbOnTabletAndUp,
    )}`.trim();
  }

  return (
    <div className={finalClassName} data-testid="lc-video-wrapper" id={id}>
      <div className="container">
        <div
          className="mx-auto"
          style={maxWidth ? { maxWidth: `${maxWidth}%` } : {}}
        >
          {youtubeID ? (
            <YoutubeVideo
              autoplay={isAutoplay}
              mediaData={mediaDetails}
              sbOnMobile="mb-relaxed"
              sbOnTabletAndUp="mb-more_relaxed"
              youtubeID={youtubeID}
            />
          ) : (
            <BitmovinPlayer
              autoplay={isAutoplay}
              mediaData={mediaDetails}
              muted={!isAutoplay}
              poster={poster}
              videoUrl={finalVideoUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Video;
