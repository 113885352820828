/**
 * @module RichText
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import _ from 'lodash';
import { filterAttributesForElement } from '@io/web-tools-io/dist/utils/helpers/attributes';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import { isColorSettingValidClassName } from '../../helpers/classNames';
import './RichText.scss';

const RichText = ({
  alignment = '',
  className = '',
  colorsetting,
  content,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  ...passThroughProps
}) => {
  const { isMobile } = useWindowSize();
  const colorSettingClass = convertValueToClassName(
    isColorSettingValidClassName(colorsetting) ? colorsetting : '',
  );
  const containerClass =
    `rich-text text-paragraph_large ${convertValueToClassName(
      className,
    )} ${colorSettingClass} ${convertValueToClassName(alignment)} ${
      isMobile
        ? convertValueToClassName(sbOnMobile)
        : convertValueToClassName(sbOnTabletAndUp)
    } container`.trim();
  const filteredProps = filterAttributesForElement({
    attributes: passThroughProps,
    elementType: 'div',
  });

  return (
    <div
      {...filteredProps}
      className={containerClass}
      dangerouslySetInnerHTML={{ __html: _.replace(content, /~\//g, '/') }}
      data-testid="lc-rich-text-component"
      id={sectionId}
    ></div>
  );
};

export default RichText;
