/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module MediaDetailEpisodeBased
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import MediaYouTubeOrFallBackPlayer from '../../MediaSharedComponents/MediaYouTubeOrFallBackPlayer';
import ContentAndForm from '../../MediaSharedComponents/ContentAndForm';
import RelatedContentCarousel from '../../MediaSharedComponents/RelatedContentCarousel';

const MediaDetailEpisodeBased = (props) => {
  const { collectionData, mediaItemData, mediaItemEpisodeBased } = props;
  const { isMobile } = useWindowSize();

  return (
    <section
      className="media-detail-episode-based"
      data-testid="lc-media-detail-episode-based"
    >
      <MediaYouTubeOrFallBackPlayer {...mediaItemData} />
      <ContentAndForm
        collectionData={collectionData}
        data={mediaItemData}
        isMedia={true}
        isMobile={isMobile}
        showForm={false}
        showSocialShare={false}
      />
      <RelatedContentCarousel
        data={mediaItemData}
        isMobile={isMobile}
        preload={mediaItemEpisodeBased.relatedContent}
      />
    </section>
  );
};

export default MediaDetailEpisodeBased;
