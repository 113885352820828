/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module MediaDetailEarlyChildhoodBased
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import ButtonItem from '../../ButtonItem/ButtonItem';
import { fetchMediaItem } from '../../../helpers/dataFetchers/mediaDetailFetcher';
import ContentAndForm from '../../MediaSharedComponents/ContentAndForm';
import PastSeries from '../../MediaSharedComponents/PastSeries';
import MediaYouTubeOrFallBackPlayer from '../../MediaSharedComponents/MediaYouTubeOrFallBackPlayer';
import { ACTIONS, EVENTS } from '../../../helpers/constants';

const MediaDetailEarlyChildhoodBased = (props) => {
  const {
    collectionData,
    mediaItemData,
    mediaItemEarlyChildhoodBased,
    pagination,
  } = props;
  const { user } = useAuth();
  const { pastMediaList, labels } = mediaItemEarlyChildhoodBased;
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = React.useState(false);
  const collectionType = mediaItemData?.['@path']?.split('/')?.[1];
  const [seriesData, setSeriesData] = React.useState(pastMediaList);
  const [limit, setLimit] = React.useState(pagination?.limit || 0);
  const [next, setNext] = React.useState(pagination?.next || 0);
  const [total, setTotal] = React.useState(pagination?.total || 0);

  async function handleShowMoreBtnClicked(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media Detail',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    setIsLoading(true);
    try {
      // Note: Offset that comes from pagination object is *current* offset, not
      // that which should be used for the subsequent API calls.
      const results = await fetchMediaItem({
        ...mediaItemData,
        limit,
        mediaCollectionType: collectionType,
        next,
        offset: next,
      }); // NOSONAR
      const resultsData = results.mediaTypeSpecificData;
      setSeriesData((prevData) => {
        return [
          ...prevData,
          ...(resultsData.pastMediaList || /* istanbul ignore next*/ []),
        ];
      });
      setLimit(results.pagination.limit);
      setNext(results.pagination.next);
      setTotal(results.pagination.total);
      // Since data loads so quickly, it's feasible that toggling between
      // button label of Show More and Loading depending on state would flash
      // too quickly, so a small, short timer helps to avoid instant flicker.
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    } catch (error) {
      /* istanbul ignore next*/
      setIsLoading(false);
      /* istanbul ignore next*/
      Log.error(error);
    }
  }

  return (
    <section
      className="media-detail-early-childhood-based"
      data-testid="lc-media-detail-early-childhood-based"
    >
      <MediaYouTubeOrFallBackPlayer {...mediaItemData} />
      <ContentAndForm
        collectionData={collectionData}
        data={mediaItemData}
        isMedia={true}
        isMobile={isMobile}
        showForm={false}
        showSocialShare={false}
      />
      <PastSeries
        className="early-childhood-based"
        collectionType={collectionType}
        data={seriesData}
        isMobile={isMobile}
        labels={labels}
        source="media"
        title="Early Childhood"
      />
      {total > next ? (
        <div
          className={`past-series early-childhood-based ${
            isMobile
              ? 'pb-relaxed pt-relaxed'
              : 'pb-more_relaxed pt-more_relaxed'
          }`}
        >
          <ButtonItem
            buttonSize="medium"
            className={`align-center`}
            disabled={isLoading}
            onClick={handleShowMoreBtnClicked}
            style="btn-secondary"
            text="Show More"
          />
        </div>
      ) : null}
    </section>
  );
};

export default MediaDetailEarlyChildhoodBased;
