/**
 * @module MediaCollectionEarlyChildhoodBased
 */
import React from 'react';
import YoutubeVideo from '../YoutubeVideo/YoutubeVideo';
import BitmovinPlayer from '../BitmovinPlayer/BitmovinPlayer';

/**
 * Represents a Media player that uses YouTube or a Fallback player.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} The MediaYouTubeOrFallBackPlayer component.
 */
const MediaYouTubeOrFallBackPlayer = (props) => {
  const [useFallBackPlayer, setUseFallBackPlayer] = React.useState(false);
  const mediaDetails = {
    id: props['@id'],
    title: props.title,
  };

  /**
   * Single-run convenience effect to determine fallback player status.
   */
  React.useEffect(() => {
    if (
      !props.showYoutubeVideo ||
      window.location.search.indexOf('use_fallback_player') > -1
    ) {
      setUseFallBackPlayer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!useFallBackPlayer && props.showYoutubeVideo ? (
        <div className="youtube-wrapper" itemProp="video">
          <YoutubeVideo
            autoplay={true}
            mediaData={mediaDetails}
            onYTNotAvailable={setUseFallBackPlayer}
            sbOnMobile="mb-relaxed"
            sbOnTabletAndUp="mb-more_relaxed"
            youtubeID={props.youtubeVideoID}
          />
        </div>
      ) : (
        <div className="fallback-player" itemProp="video">
          <BitmovinPlayer
            mediaData={mediaDetails}
            videoUrl={props.media_url || props.streaming_video_url}
          />
        </div>
      )}
    </>
  );
};

export default MediaYouTubeOrFallBackPlayer;
