/**
 * @module LaunchedContactCardV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import Button from '../../ButtonItem/ButtonItem';
import Spans from '../../IconSpans/Spans';
import { ACTIONS, EVENTS } from '../../../helpers/constants';
import LaunchedContactCardAddressV2 from './LaunchedContactCardAddressV2';
import './LaunchedContactCardV2.scss';

/**
 * Represents the Contact Card section of a launched location page.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.category] - The category in which the component is placed (Example: 'Location Modal').
 * @param {object} props.locationData - The location data object for the Contact Card section.
 * @param {boolean} [props.isContactUsModalOpen] - Boolean flag denoting whether or not the ContactUs modal is open.
 *
 * @returns {React.ReactElement} The LaunchedContactCardV2 component.
 */
export default function LaunchedContactCardV2({
  category,
  locationData,
  isContactUsModalOpen,
}) {
  const { user } = useAuth();

  /**
   * Convenience function to trigger `callSegmentTrack` for analytics.
   * This is intended to be a convenient way to avoid code duplication within
   * this file since all properties category values are shared.
   *
   * @param {object} params - The function params object.
   * @param {string} params.event - The event name.
   * @param {string} params.label - The event properties label value.
   */
  function callAnalytics({ event, label }) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: `Location Launched - Contact Card (${category})`,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  // Handler functions.
  function handleAddressClick(event) {
    callAnalytics({
      event,
      label: 'Directions',
    });
  }

  function handleButtonClick(event) {
    callAnalytics({
      event,
      label: event.currentTarget.textContent,
    });
  }

  function handlePhoneClick(event) {
    callAnalytics({
      event,
      label: 'Phone',
    });
  }

  return (
    <>
      {locationData ? (
        <section
          className={`location-contact-card-v2 ${
            isContactUsModalOpen ? 'modal-open' : ''
          }`}
          data-testid="lc-launched-contact-card-v2"
        >
          <div className="container">
            <div className="contact-card-header text-center">
              <h2>{`Life.Church ${locationData.locationName}`}</h2>
            </div>

            <div className="content">
              <div className="inner-container">
                <div className="contact-card-pastor">
                  <img
                    alt={`A headshot of the ${locationData.locationName} Campus Pastor`}
                    src={locationData.pastorImage}
                  />
                  <div>
                    <h3>{locationData.pastorName}</h3>
                    <p>Pastor</p>
                  </div>
                </div>
                <div className="contact-card-address">
                  <div>
                    <span className="icon icon-marker-filled">
                      <Spans />
                    </span>
                    <h3>{`Life.Church ${locationData.locationName}`}</h3>
                  </div>
                  <LaunchedContactCardAddressV2
                    addressData={locationData.primaryAddress}
                    handleAddressClick={handleAddressClick}
                  />
                </div>
                <div className="contact-card-social-media">
                  {locationData.phone ? (
                    <a
                      className="social-link"
                      href={`tel:${locationData.phone}}`}
                      onClick={handlePhoneClick}
                      rel="noreferrer"
                    >
                      <span className="icon icon-phone-filled">
                        <Spans />
                      </span>
                      {locationData.phone}
                    </a>
                  ) : null}

                  {locationData.instagram ? (
                    <a
                      className="social-link"
                      href={`https://www.instagram.com/${locationData.instagram}`}
                      onClick={handleButtonClick}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span className="icon icon-instagram-stroked">
                        <Spans />
                      </span>
                      Instagram
                    </a>
                  ) : null}

                  {locationData.facebook ? (
                    <a
                      className="social-link"
                      href={`https://www.facebook.com/${locationData.facebook}`}
                      onClick={handleButtonClick}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span className="icon icon-facebook-circle-filled">
                        <Spans />
                      </span>
                      Facebook
                    </a>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="contact-card-connect text-center">
              <div>
                <Button
                  buttonSize="medium"
                  onClick={handleButtonClick}
                  style="btn-primary"
                  target="_blank"
                  text="Connect With Our Team"
                  url={`https://my.life.church/forms/questions?source=web&referrer=${window.location.origin}${window.location.pathname}&Location=${locationData.campusCode}`}
                />
                <Button
                  buttonSize="medium"
                  onClick={handleButtonClick}
                  style="btn-secondary"
                  target="_blank"
                  text="Ask For Prayer"
                  url={`https://my.life.church/forms/prayer/?source=web&referrer=${window.location.origin}${window.location.pathname}&Location=${locationData.campusCode}`}
                />
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}
