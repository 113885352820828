/**
 * @module LifeChurchOnlineOnDemandBanner
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import { useElapsedTime } from 'use-elapsed-time';
import ButtonItem from '../ButtonItem/ButtonItem';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './LifeChurchOnlineOnDemandBanner.scss';

/**
 * Convenience function to add a leading zero to the specified value if it is less than 10.
 *
 * @param {number|string} value - The value to inspect for addition of a leading zero.
 *
 * @returns {number|string} The specified value with a leading zero, if less than 10.
 */
function addLeadingZero(value) {
  return value <= 9 ? `0${value}` : value;
}

/**
 * Convenience function to calculate the number of minutes until the next hour.
 *
 * @param {number|string} min - The minutes value.
 *
 * @returns {number} The number of minutes until the next hour.
 */
function minutesUntilNextHour(min) {
  const calculatedValue = parseInt(60 - min, 10);
  return calculatedValue < 60 ? calculatedValue : 0;
}

/**
 * Convenience function to calculate the number of seconds until the next minute.
 *
 * @param {number|string} sec - The seconds value.
 *
 * @returns {number} The number of seconds until the next minute.
 */
function secondsUntilNextMinute(sec) {
  const calculatedValue = parseInt(60 - sec, 10);
  return calculatedValue < 60 ? calculatedValue : 0;
}

/**
 * Convenience function to format the provided time into a CountdownTimerData object.
 *
 * @param {Date} time - Date object to use in calculating time values.
 * @param {number} offsetMinutes - Offset number of minutes to use in calculating minutes value.
 *
 * @returns {CountdownTimerData} Formatted CountdownTimerData object.
 */
/* istanbul ignore next */
function calculateTime(time, offsetMinutes = 0) {
  let min = minutesUntilNextHour(time.getMinutes()) + offsetMinutes;
  if (min >= 60) {
    min -= 60;
  }
  const sec = secondsUntilNextMinute(time.getSeconds());
  if (sec !== 0) {
    min -= 1;
  }
  return {
    min: addLeadingZero(min),
    sec: addLeadingZero(sec),
  };
}

/**
 * Represents a banner component containing status information and CTA for Life.Church Online services.
 *
 * Note: This intentionally uses a different countdown and timer style than used
 * in the CountdownTimer component to ensure accurate timing using a different
 * npm package utilized in the YoutubePlayer and BitmovinPlayer components.
 *
 * @param {object} props - The component props object.
 * @param {object} [props.labelList] - Normalized data object of label list items, with label names and values as the key/value pairs.
 *
 * @returns {React.ReactElement} The LifeChurchOnlineOnDemandBanner component.
 */
export default function LifeChurchOnlineOnDemandBanner({ labelList }) {
  const { user } = useAuth();
  const now = new Date();
  const { isMobile } = useWindowSize();
  const [desktopBg, setDesktopBg] = React.useState(
    labelList?.bg_image_desktop
      ? `${process.env.IMGIX_BASE_URL}/${labelList.bg_image_desktop}`
      : '',
  );
  const [countdownTimer, setCountdownTimer] = React.useState(
    calculateTime(
      now,
      labelList?.countdown_display_threshold_hide_countdown ?? 0,
    ),
  );
  const [isBannerClosedByUser, setIsBannerClosedByUser] = React.useState(false);
  const [isLiveNow, setIsLiveNow] = React.useState(
    now.getMinutes() < labelList?.countdown_display_threshold_hide_all &&
      now.getMinutes() >= labelList?.countdown_display_threshold_hide_countdown,
  );
  const [showBanner, setShowBanner] = React.useState(
    now >= new Date(labelList?.start) &&
      now <= new Date(labelList?.end) &&
      (now.getMinutes() < labelList?.countdown_display_threshold_hide_all ||
        now.getMinutes() >=
          labelList?.countdown_display_threshold_show_countdown) &&
      !isBannerClosedByUser &&
      labelList?.days.includes(now.getDay().toString()),
  );
  const [showBannerOverride, setShowBannerOverride] = React.useState(
    labelList?.is_override,
  );
  const onDemandBannerRef = React.useRef(null);

  /**
   * Elapsed time hook to track countdown timer every second.
   */
  /* istanbul ignore next */
  useElapsedTime({
    isPlaying: true,
    onUpdate: () => {
      setCountdownTimer(
        calculateTime(
          now,
          labelList?.countdown_display_threshold_hide_countdown ?? 0,
        ),
      );
    },
    updateInterval: 1,
  });

  /**
   * Handler function for banner button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Life.Church Online On-Demand Banner',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    const url = labelList.cta_url;
    window.open(url);
  }

  /**
   * Convenience effect to set the live status based on countdown thresholds.
   */
  React.useEffect(() => {
    if (labelList && Object.keys(labelList).length > 0) {
      setIsLiveNow(
        now.getMinutes() < labelList.countdown_display_threshold_hide_all &&
          now.getMinutes() >=
            labelList.countdown_display_threshold_hide_countdown,
      );
      if (!showBannerOverride) {
        setShowBanner(
          now >= new Date(labelList.start) &&
            now <= new Date(labelList.end) &&
            (now.getMinutes() <
              labelList.countdown_display_threshold_hide_all ||
              now.getMinutes() >=
                labelList.countdown_display_threshold_show_countdown) &&
            !isBannerClosedByUser &&
            labelList.days.includes(now.getDay().toString()),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTimer]);

  /**
   * Convenience effect to set state variables dependent on labelList data.
   */
  React.useEffect(() => {
    if (labelList && Object.keys(labelList).length > 0) {
      if (!showBannerOverride) {
        setShowBanner(
          now >= new Date(labelList.start) &&
            now <= new Date(labelList.end) &&
            (now.getMinutes() <
              labelList.countdown_display_threshold_hide_all ||
              now.getMinutes() >=
                labelList.countdown_display_threshold_show_countdown) &&
            !isBannerClosedByUser &&
            labelList.days.includes(now.getDay().toString()),
        );
      }
      setIsLiveNow(
        now.getMinutes() < labelList.countdown_display_threshold_hide_all &&
          now.getMinutes() >=
            labelList.countdown_display_threshold_hide_countdown,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelList]);

  /**
   * Convenience effect to update state based on override setting of labelList.
   * Also included is the calculation and setting of the desktop bg image.
   */
  React.useEffect(() => {
    if (labelList && Object.keys(labelList).length > 0) {
      if (labelList.is_override) {
        setIsBannerClosedByUser(false);
        setShowBannerOverride(true);
        setShowBanner(true);
      }
      if (labelList.bg_image_desktop) {
        setDesktopBg(
          `${process.env.IMGIX_BASE_URL}/${labelList.bg_image_desktop}`,
        );
      }
    }
  }, [labelList]);

  /**
   * Convenience function for window resize and size check for background image.
   */
  /* istanbul ignore next */
  const checkSizeForBackground = React.useCallback(() => {
    if (onDemandBannerRef?.current) {
      onDemandBannerRef.current.style.backgroundImage = !isMobile
        ? `url(${desktopBg})`
        : 'none';
    }
  }, [desktopBg, isMobile]);

  /**
   * Convenience effect to check for banner override to trigger size check.
   */
  React.useEffect(() => {
    if (showBannerOverride) {
      checkSizeForBackground();
    }
  }, [checkSizeForBackground, showBannerOverride]);

  /**
   * Convenience effect to handle resize listener and logic for background.
   */
  React.useEffect(() => {
    checkSizeForBackground();
    /* istanbul ignore next */
    function handleResize() {
      checkSizeForBackground();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // Note: Adding dependencies for hook-related state items so resize logic
    // for determining/setting image source is properly utilized and set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <>
      {showBanner ? (
        <>
          <div className="lco-on-demand-banner" ref={onDemandBannerRef}>
            <button
              className="btn-banner-close"
              onClick={() => {
                setIsBannerClosedByUser(true);
                setShowBanner(false);
              }}
            >
              <i className="icon"></i>
            </button>
            <div className="banner-content">
              <div className="banner-text-labels">
                <div className="banner-title">
                  {isLiveNow
                    ? labelList.title_live_now
                    : labelList.title_starts_soon}
                </div>
                {isLiveNow ? (
                  <div className="banner-badge">
                    <div className="badge gradient-lc_red-local-missions-135deg">
                      {labelList.badge_label}
                    </div>
                  </div>
                ) : (
                  <div className="banner-timer">
                    <div className="timer-column">
                      <div className="timer-numerals">{countdownTimer.min}</div>
                      <div className="timer-labels">
                        {labelList.timer_label_minutes}
                      </div>
                    </div>
                    <div className="timer-column">
                      <div className="timer-numerals">{countdownTimer.sec}</div>
                      <div className="timer-labels">
                        {labelList.timer_label_seconds}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="banner-ctas">
                <ButtonItem
                  buttonSize="medium"
                  darkVariant={true}
                  id="lco-on-demand-banner-cta-button"
                  onClick={handleButtonClick}
                  style={
                    /* istanbul ignore next */ isLiveNow
                      ? 'btn-primary dark-mode'
                      : 'btn-secondary dark-mode'
                  }
                  text={labelList.cta_label}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
