/**
 * @module LocationStaticText
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';
import ButtonItem from '../ButtonItem/ButtonItem';
import Modal from '../Modal/Modal';
import './LocationStaticText.scss';

const LocationStaticText = (props) => {
  const {
    className,
    ctas = null,
    descClassName = '',
    hideMobileTablet = false,
    label,
    name: campus_name,
    titleClassName = '',
  } = props;
  const { user } = useAuth();
  const [modal, setModal] = React.useState(null);
  const articleClass =
    `location-static-text text-center ${convertValueToClassName(className)} ${
      !!hideMobileTablet && 'hide-mobile-tablet'
    }`.trim();
  const titleClass = `location-static-title ${convertValueToClassName(
    titleClassName,
  )}`.trim();
  const descClass = `location-static-description ${convertValueToClassName(
    descClassName,
  )}`.trim();

  const handleOpenModal = (e) => {
    e.preventDefault();
    let type = {};
    const buttonID = e.currentTarget.id.split('-')[0];

    if (ctas[buttonID].modalType === 'iframe') {
      type = {
        field: 'iframe',
        iframeSource: ctas[buttonID].iframeSrc,
      };
    } else {
      type = {
        field: 'hubspot',
        hubspotFormID: props.hubspotId,
      };
    }

    setModal({
      modalType: {
        field: 'formModal',
        type,
      },
    });
  };

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Page',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  function renderCtas() {
    if (ctas) {
      const newCta = ctas.map((cta, index) => {
        if (cta.actionType === 'link') {
          return (
            <a
              className="link"
              href={implementUtmParams(cta.url, window?.location)}
              key={`link-${uuidv4()}`}
              onClick={(event) => handleElementClick(event)}
            >
              <span>{cta.btn_txt}</span>
              <svg
                fill="none"
                height="18"
                viewBox="0 0 11 18"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M1.5778 0L10.5 9L1.5778 18L0.5 16.9128L8.3444 9L0.5 1.0872L1.5778 0Z"
                  fill="#00A8D8"
                  fillRule="evenodd"
                />
              </svg>
            </a>
          );
        }
        if (cta.actionType === 'button') {
          return (
            <ButtonItem
              buttonSize="medium"
              className="display-inline-block my-none"
              id={`${index}-${uuidv4()}`}
              key={`btn-${uuidv4()}`}
              onClick={(event) => {
                handleElementClick(event);
                if (!cta?.url) {
                  handleOpenModal(event);
                }
              }}
              style={cta?.style ?? 'btn-primary'}
              target={cta?.target}
              text={cta.btn_txt}
              url={cta?.url}
            />
          );
        }
        return null;
      });
      return newCta;
    }
    return null;
  }

  return (
    <>
      <article className={articleClass} data-testid="lc-location-static-text">
        <div className="container maxWidth-768">
          <h2 className={titleClass}>{!!label && label.title}</h2>
          <p className={descClass}>
            {!!label && label.desc.replace('{{campus_name}}', campus_name)}
          </p>
          <div className="button-group-wrapper container">
            <div className="button-item-listing buttons-group center-align row-gap-normal">
              {renderCtas()}
            </div>
          </div>
          {!!modal && (
            <Modal
              data={modal}
              setShowStatus={() => setModal(null)}
              showStatus={true}
            />
          )}
        </div>
      </article>
    </>
  );
};

export default LocationStaticText;
