/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module Input
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { filterAttributesForElement } from '@io/web-tools-io/dist/utils/helpers/attributes';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import Checkbox from '../Checkbox/Checkbox';
import Radio from '../Radio/Radio';
import RichText from '../RichText/RichText';
import Signature from '../Signature/Signature';
import File from '../File/File';
import './Input.scss';

/**
 * Represents an element containing a form control element of the specified type.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name value to assign to the main element wrapper div.
 * @param {string} [props.defaultValue] - Optional value to attribute to the component of the specified type.
 * @param {string} [props.error] - Optional error value to display with the component.
 * @param {string} [props.hint] - Optional value to display for the component hint element.
 * @param {string} props.inputName - The value to use for the input name attribute.
 * @param {string} [props.label] - Optional label value for the component.
 * @param {number|string} [props.max] - Optional maximum value to use for components using max/min attributes.
 * @param {number|string} [props.min] - Optional maximum value to use for components using max/min attributes.
 * @param {Function} [props.onChange] - Optional handler function for input change event..
 * @param {string} [props.placeholder] - Optional placeholder value for components using placeholder attribute.
 * @param {string} [props.sbOnMobile] - Class name to apply to component wrapper for mobile screen sizes.
 * @param {string} [props.sbOnTabletAndUp] - Class name to apply to component wrapper for tablet and up screen sizes.
 * @param {string} props.type - The input type (Example: radio).
 *
 * @returns {React.ReactElement} The Input component wrapper containing a component of the specified type.
 */
const Input = ({
  className,
  defaultValue,
  error,
  hint,
  inputName,
  label,
  max,
  min,
  onChange,
  placeholder,
  sbOnMobile,
  sbOnTabletAndUp,
  type,
  ...passThroughProps
}) => {
  const { isMobile } = useWindowSize();
  const containerClass = `input ${convertValueToClassName(className)} ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();
  const filteredProps = filterAttributesForElement({
    attributes: passThroughProps,
    elementType: 'input',
  });

  return (
    <div className={containerClass} data-testid="input-component-wrapper">
      {type === 'checkbox' ? (
        <Checkbox {...{ hint, inputName, label, type }} />
      ) : null}
      {type === 'radio' ? (
        <Radio {...{ hint, inputName, label, type }} />
      ) : null}
      {type === 'richtext' ? (
        <RichText {...{ hint, inputName, label, type }} />
      ) : null}
      {
        /* istanbul ignore next */ type === 'signature' ? (
          <Signature {...{ hint, inputName, label, type }} />
        ) : null
      }
      {type === 'file' ? <File {...{ hint, inputName, label, type }} /> : null}
      {type === 'text' ||
      type === 'date' ||
      type === 'number' ||
      type === 'tel' ||
      type === 'email' ? (
        <InputForm
          {...{
            ...filteredProps,
            defaultValue,
            error,
            hint,
            inputName,
            label,
            max,
            min,
            onChange,
            placeholder,
            type,
          }}
        />
      ) : null}
    </div>
  );
};

/**
 * Represents an element containing a form control element with a label, input of the specified type, optional error (if specified), and hint.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.defaultValue] - Optional value to attribute to the component of the specified type.
 * @param {string} [props.error] - Optional error value to display with the component.
 * @param {string} [props.hint] - Optional value to display for the component hint element.
 * @param {string} props.inputName - The value to use for the input name attribute.
 * @param {string} [props.label] - Optional label value for the component.
 * @param {number|string} [props.max] - Optional maximum value to use for components using max/min attributes.
 * @param {number|string} [props.min] - Optional maximum value to use for components using max/min attributes.
 * @param {Function} [props.onChange] - Optional handler function for input change event..
 * @param {string} [props.placeholder] - Optional placeholder value for components using placeholder attribute.
 * @param {string} props.type - The input type (Example: date).
 *
 * @returns {React.ReactElement} The InputForm component wrapper containing a label, input, error, and hint element (as specified).
 */
const InputForm = ({
  defaultValue,
  error,
  hint,
  inputName,
  label,
  max,
  min,
  onChange,
  placeholder,
  type,
  ...passThroughProps
}) => {
  const filteredProps = filterAttributesForElement({
    attributes: passThroughProps,
    elementType: 'input',
  });

  return (
    <>
      {label ? (
        <label data-testid="input-component-label">{label}</label>
      ) : null}
      <input
        data-testid="input-component-input"
        {...filteredProps}
        defaultValue={defaultValue}
        max={max}
        min={min}
        name={inputName}
        onChange={onChange}
        placeholder={placeholder ?? label?.replace('*', '')}
        type={type}
      />
      {error ? (
        <p className="error" data-testid="input-component-error">
          {error}
        </p>
      ) : null}
      {hint ? (
        <span
          className="text-paragraph_xsmall"
          data-testid="input-component-hint"
        >
          {hint}
        </span>
      ) : null}
    </>
  );
};
export default Input;
