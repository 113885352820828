/**
 * @module MediaCollectionListingAlbumSongBased
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import CollectionListingAlbumHero from '../../MediaSharedComponents/CollectionListingAlbumHero';
import AlbumCardListing from '../../MediaSharedComponents/AlbumCardListing';
import ButtonItem from '../../ButtonItem/ButtonItem';
import { fetchMediaCollectionListing } from '../../../helpers/dataFetchers/mediaCollectionListingFetcher';
import { ACTIONS, EVENTS } from '../../../helpers/constants';
import '../../MediaSharedComponents/style/_MediaComponentSharedStyle.scss';
import '../../OpenContentList/OpenContentList.scss';

const MediaCollectionListingAlbumSongBased = ({
  collectionType,
  latestCollection,
  pagination,
  pastCollections,
}) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = React.useState(false);
  const [seriesData, setSeriesData] = React.useState(pastCollections);
  const [limit, setLimit] = React.useState(pagination?.limit || 0);
  const [next, setNext] = React.useState(pagination?.next || 0);
  const [total, setTotal] = React.useState(pagination?.total || 0);

  async function handleShowMoreBtnClicked(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media Collection Listing',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    setIsLoading(true);
    try {
      // Note: Offset that comes from pagination object is *current* offset, not
      // that which should be used for the subsequent API calls.
      const results = await fetchMediaCollectionListing({
        limit,
        mediaCollectionType: collectionType,
        next,
        offset: next,
      }); // NOSONAR
      setSeriesData((prevData) => {
        return [
          ...prevData,
          ...(results.pastCollections || /* istanbul ignore next*/ []),
        ];
      });
      setLimit(results.pagination.limit);
      setNext(results.pagination.next);
      setTotal(results.pagination.total);
      // Since data loads so quickly, it's feasible that toggling between
      // button label of Show More and Loading depending on state would flash
      // too quickly, so a small, short timer helps to avoid instant flicker.
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    } catch (error) {
      /* istanbul ignore next*/
      setIsLoading(false);
      /* istanbul ignore next*/
      Log.error(error);
    }
  }

  return (
    <section
      className="media-collection-listing-album-song-based"
      data-testid="lc-media-collection-listing-album-song-based"
    >
      {latestCollection ? (
        <CollectionListingAlbumHero
          data={latestCollection}
          isMobile={isMobile}
        />
      ) : null}
      {seriesData?.length ? (
        <AlbumCardListing
          data={seriesData}
          isMobile={isMobile}
          pagination={pagination}
        />
      ) : null}
      {total > next ? (
        <div
          className={`container ${
            isMobile
              ? 'mb-relaxed pb-relaxed'
              : 'mb-more_relaxed pb-more_relaxed'
          }`}
        >
          <ButtonItem
            buttonSize="medium"
            className={`align-center`}
            disabled={isLoading}
            onClick={handleShowMoreBtnClicked}
            style="btn-secondary"
            text="Show More"
          />
        </div>
      ) : null}
    </section>
  );
};

export default MediaCollectionListingAlbumSongBased;
