/**
 * @module LocationLaunchedV2
 */
import React from 'react';
import { DAYS_OF_WEEK } from '@io/web-tools-io/dist/utils/helpers';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { formatTimeTo12h } from '@io/web-tools-io/dist/utils/helpers/date';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import forYourCommunityLarge from '../LocationV2/assets/ForYourCommunityLarge.png';
import forYourCommunityMedium from '../LocationV2/assets/ForYourCommunityMedium.png';
import forYourCommunitySmall from '../LocationV2/assets/ForYourCommunitySmall.png';
import forYourFamilyLarge from '../LocationV2/assets/ForYourFamilyLarge.png';
import forYourFamilyMedium from '../LocationV2/assets/ForYourFamilyMedium.png';
import forYourFamilySmall from '../LocationV2/assets/ForYourFamilySmall.png';
import LaunchedBannerV2 from './V2/LaunchedBannerV2';
import LaunchedContactCardV2 from './V2/LaunchedContactCardV2';
import LaunchedDownloadV2 from './V2/LaunchedDownloadV2';
import LaunchedHeroV2 from './V2/LaunchedHeroV2';
import LaunchedLatestMessageV2 from './V2/LaunchedLatestMessageV2';
import LaunchedLifeChurchIsForYou from './V2/LaunchedLifeChurchIsForYouV2';
import LaunchedScheduleV2 from './V2/LaunchedScheduleV2';
import lcSwitchImage from '../LocationV2/assets/SwitchSmall.png';
import Modal from '../Modal/Modal';
import useIsVisible from '../LocationV2/hooks/useIsVisible';
import whatToExpectLarge from '../LocationV2/assets/WhatToExpectLarge.png';
import whatToExpectMedium from '../LocationV2/assets/WhatToExpectMedium.png';
import whatToExpectSmall from '../LocationV2/assets/WhatToExpectSmall.png';
import { ACTIONS, CATEGORIES, EVENTS } from '../../helpers/constants';

const LocationLaunchedV2 = (props) => {
  const { user } = useAuth();
  const {
    campusCode,
    eventTimes,
    facebook,
    instagram,
    name,
    pastorImage,
    pastorName,
    phone,
    primaryAddress,
  } = props;

  const [showContactUsModal, setShowContactUsModal] = React.useState(false);
  const [showServiceTimesModal, setShowServiceTimesModal] =
    React.useState(false);
  const serviceTimesRef = React.useRef(null);
  const isServiceTimesSectionVisible = useIsVisible(serviceTimesRef);

  const serviceTimes = (eventTimes?.['@nodes'] || []).reduce(
    (acc, node) => {
      const event = eventTimes[node];
      const eventDay = DAYS_OF_WEEK[event.dow];
      const isWednesday = event.dow === '2';
      const switchOrLocation = isWednesday ? 'switchEvents' : 'locationEvents';
      const currentEvent = acc[switchOrLocation].get(eventDay) || [];

      acc[switchOrLocation].set(eventDay, [
        ...currentEvent,
        formatTimeTo12h(`${event.time}:00`),
      ]);

      return acc;
    },
    { locationEvents: new Map(), switchEvents: new Map() },
  );

  /**
   * Handler function for modal visibility status.
   */
  function handleSetModalShowStatus() {
    setShowContactUsModal(false);

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Launched - Location Modal',
        component_url: null, // Modal trigger, no URL.
        label: 'Close',
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  const bannerData = {
    title: 'Join Us This Weekend!',
  };
  const contactCardData = {
    campusCode,
    facebook,
    instagram,
    locationName: name,
    pastorImage,
    pastorName,
    phone,
    primaryAddress,
  };
  const downloadData = {
    img: 'https://magnolia-cms-live.s3.amazonaws.com/logos/lc-app.svg',
    title: 'The best way to connect with Life.Church.',
  };
  const heroData = {
    description:
      'At Life.Church, you’ll find a safe place to explore your beliefs and connect with others.',
    videoBackground:
      'https://production-cms-assets.life.church/pages/cities/cities-header_720p.mp4',
    videoUrl:
      'https://p.life.church/info/about/who-we-are_,240p,1080p,720p,480p,144p,master.m3u8?subtitles=eng',
  };
  const latestMessageData = {
    title: 'Watch The Latest Message',
  };
  const lcIsForYouData = {
    description:
      'Life.Church is a safe place to explore your beliefs and connect with others. You can come as you are and find purpose.',
    imgLarge: whatToExpectLarge,
    imgMedium: whatToExpectMedium,
    imgSmall: whatToExpectSmall,
    subtitle: 'Wherever you are in life, you have a purpose.',
    title: 'Life.Church is For You',
  };
  const lcIsForYourCommunityData = {
    description:
      'We’re passionate about reaching people and taking action. We partner with organizations in your cities to affect positive change.',
    imgLarge: forYourCommunityLarge,
    imgMedium: forYourCommunityMedium,
    imgSmall: forYourCommunitySmall,
    subtitle: 'We’re dedicated to bringing hope and healing to your city.',
    title: 'Life.Church is For Your Community',
  };
  const lcIsForYourFamilyData = {
    campusCode,
    description:
      'LifeKids is a safe place for your kids to belong. You’ll find the support and friendships you need to help them follow Christ.',
    imgLarge: forYourFamilyLarge,
    imgMedium: forYourFamilyMedium,
    imgSmall: forYourFamilySmall,
    subtitle:
      'Kids ages birth-6th grade can experience LifeKids on the weekend!',
    title: 'Life.Church is For Your Family',
  };
  const lcSwitchData = {
    description:
      'It’s a place for students to grow in their relationship with others and with God. Experiences last an hour and a half.',
    img: lcSwitchImage,
    serviceEventTimes: serviceTimes.switchEvents,
    subtitle: 'Switch is our experience for 6th-12th grade students.',
  };
  const scheduleData = {
    addressData: { ...primaryAddress, locationName: name },
    description: `All weekend services last one hour.
          LifeKids is available at every service for children birth-6th grade.`,
    serviceEventTimes: serviceTimes.locationEvents,
    subtitle: 'Service Times',
    title: `Join us at\nLife.Church ${name.trim()} this weekend!`,
  };

  return (
    <section
      className="location-launched-v2"
      data-testid="lc-location-launched-v2"
    >
      <LaunchedHeroV2 locationData={heroData} />
      <div ref={serviceTimesRef}>
        <LaunchedScheduleV2
          locationData={scheduleData}
          openContactUsModal={() => setShowContactUsModal(true)}
        />
      </div>
      <LaunchedLatestMessageV2 locationData={latestMessageData} />
      <LaunchedLifeChurchIsForYou
        forYouData={lcIsForYouData}
        forYourCommunityData={lcIsForYourCommunityData}
        forYourFamilyData={lcIsForYourFamilyData}
        lcSwitchData={lcSwitchData}
      />
      <LaunchedDownloadV2 locationData={downloadData} />
      <LaunchedContactCardV2
        category={CATEGORIES.locationInformation}
        locationData={contactCardData}
      />
      <LaunchedBannerV2
        isVisible={!isServiceTimesSectionVisible}
        locationData={bannerData}
        openServiceTimesModal={() => setShowServiceTimesModal(true)}
      />
      {showServiceTimesModal ? (
        <Modal
          className="location-modal"
          setShowStatus={() => setShowServiceTimesModal(false)}
          showStatus={true}
        >
          <LaunchedScheduleV2
            isServiceTimesModalOpen={showServiceTimesModal}
            locationData={scheduleData}
          />
        </Modal>
      ) : null}
      {showContactUsModal ? (
        <Modal
          className="location-modal"
          setShowStatus={handleSetModalShowStatus}
          showStatus={true}
        >
          <LaunchedContactCardV2
            category={CATEGORIES.locationModal}
            isContactUsModalOpen={showContactUsModal}
            locationData={contactCardData}
          />
        </Modal>
      ) : null}
    </section>
  );
};

export default LocationLaunchedV2;
