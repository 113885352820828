/**
 * @module Footer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import Button from '../ButtonItem/ButtonItem';
import RichText from '../RichText/RichText';
import Spans from '../IconSpans/Spans';
import { implementAnchorTagAttributes } from '../../helpers/scriptHelper';
import { CONSENT_MANAGER_CONFIG } from '../../helpers/constants';
import { brightEdgeAutoPilotScript } from '../../lib';
import './Footer.scss';

const Footer = ({ footerData }) => {
  /* istanbul ignore next */
  const includeConsentManagerLink =
    process.env.OSANO_CONSENT_MANAGER_ACCOUNT_ID &&
    process.env.OSANO_CONSENT_MANAGER_CONFIG_ID &&
    process.env.OSANO_INCLUDE_CONSENT_MANAGER &&
    process.env.OSANO_INCLUDE_CONSENT_MANAGER.toString() === 'true';
  /* istanbul ignore next */
  const includeBrightEdgeMarkup =
    process.env.BRIGHTEDGE_AUTOPILOT_CONFIG_ID &&
    process.env.BRIGHTEDGE_AUTOPILOT_INCLUDE &&
    process.env.BRIGHTEDGE_AUTOPILOT_INCLUDE.toString() === 'true';
  const now = new Date();
  const [footerLeftContent, setFooterLeftContent] = React.useState(
    footerData?.policyContent,
  );
  const [hasFooterLeftContentChecked, setHasFooterLeftContentChecked] =
    React.useState(false);
  const [brightEdgeMarkup, setBrightEdgeMarkup] = React.useState(null);

  /**
   * Handler function for link click events.
   *
   * Note: The ignore is added since this isn't seen as covered even though
   * the tests assert all click handlers and the window function is mocked.
   *
   * @param {Event} event - The Event data object.
   */
  function handleLinkClick(event) {
    /* istanbul ignore next */
    window.handleAnchorElementClick(event, {
      component: 'Footer',
    });
  }

  /**
   * Convenience effect to dynamically add Cookie Preferences and Consent
   * Manager link markup to the footerData.policyContent string and update the
   * `footerLeftContent` state for use in the component markup.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (footerData?.policyContent && !hasFooterLeftContentChecked) {
      if (includeConsentManagerLink) {
        const additionalMarkup = `|&nbsp;<a href="#" id="consent-manager-cookie-prefs" onclick="event.preventDefault();event.stopPropagation();window.handleAnchorElementClick(event, { component: 'Footer' });window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');">${CONSENT_MANAGER_CONFIG.footerLinkCTA}</a>`;
        setFooterLeftContent(
          footerData.policyContent.replace(
            '{{consent_manager}}',
            additionalMarkup,
          ),
        );
      } else {
        setFooterLeftContent(
          footerData.policyContent.replace('{{consent_manager}}', ''),
        );
      }
      setHasFooterLeftContentChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerData?.policyContent, hasFooterLeftContentChecked]);

  /**
   * Convenience effect to add onClick handlers to anchor tags in string-based
   * footer policy content that do not already have it as an attribute.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (hasFooterLeftContentChecked) {
      setFooterLeftContent(
        implementAnchorTagAttributes(footerLeftContent, 'Footer'),
      );
    }
  }, [footerLeftContent, hasFooterLeftContentChecked]);

  /**
   * Single-run convenience effect to set the markup for BrightEdge Autopilot.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (includeBrightEdgeMarkup) {
      setBrightEdgeMarkup(
        brightEdgeAutoPilotScript({
          configId: process.env.BRIGHTEDGE_AUTOPILOT_CONFIG_ID,
          placement: 'body',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer className="footer" data-testid="lc-footer">
      {footerData ? (
        <div className="footer-wrapper container">
          {footerLeftContent ? (
            <RichText
              className="left-content"
              content={footerLeftContent.replace(
                '{{current_year}}',
                now.getFullYear(),
              )}
              data-testid="lc-footer-left-content"
            />
          ) : null}
          <div className="right-content">
            {!!footerData.pageNavLinks &&
            !!footerData.pageNavLinks['@nodes'] ? (
              <div className="page-nav-links">
                {footerData.pageNavLinks['@nodes'].map((item) => {
                  const pageItem = footerData.pageNavLinks[item];
                  return (
                    <Button
                      key={pageItem['@id']}
                      onClick={handleLinkClick}
                      style="link-tertiary"
                      text={pageItem.linkTitle}
                      url={pageItem.url}
                    />
                  );
                })}
              </div>
            ) : null}
            {!!footerData.socialMediaLinks &&
            !!footerData.socialMediaLinks['@nodes'] ? (
              <div className="social-media-links">
                {footerData.socialMediaLinks['@nodes'].map((item) => {
                  const socialItem = footerData.socialMediaLinks[item];
                  const spanClass = `icon ${convertValueToClassName(
                    socialItem.socialMediaType,
                  )}`.trim();

                  return (
                    <a
                      className="social-link"
                      href={implementUtmParams(
                        socialItem.socialMediaUrl || '#',
                        window?.location,
                      )}
                      key={socialItem['@id']}
                      onClick={handleLinkClick}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span className={spanClass}>
                        <Spans />
                      </span>
                    </a>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {
        /* istanbul ignore next */ brightEdgeMarkup ? (
          <div className="footer-bottom-wrapper">
            <div
              className="bottom-content"
              dangerouslySetInnerHTML={{ __html: brightEdgeMarkup }}
            ></div>
          </div>
        ) : null
      }
    </footer>
  );
};

export default Footer;
