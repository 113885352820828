/**
 * @module Heading
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import { isColorSettingValidClassName } from '../../helpers/classNames';
import './Heading.scss';

const Heading = (props) => {
  const {
    headingText,
    headingLevel,
    headingType,
    sbOnMobile,
    sbOnTabletAndUp,
    alignment = '',
    colorsetting,
    sectionId,
  } = props;
  const { isMobile } = useWindowSize();
  const Tag = `${headingLevel}`;
  const colorSettingClass = convertValueToClassName(
    isColorSettingValidClassName(colorsetting) ? colorsetting : '',
  );
  const containerClass =
    `heading container rich-text text-${convertValueToClassName(
      headingType,
    )} ${colorSettingClass} ${convertValueToClassName(alignment)} ${
      isMobile
        ? convertValueToClassName(sbOnMobile)
        : convertValueToClassName(sbOnTabletAndUp)
    }`.trim();

  return (
    <Tag
      className={containerClass}
      dangerouslySetInnerHTML={{ __html: headingText }}
      id={sectionId}
    ></Tag>
  );
};

export default Heading;
