/**
 * @module scriptHelper
 */

export const GTM_TYPES = {
  noscript: 'noscript',
  script: 'script',
};

/**
 * Convenience function to inject a `script` tag into the page head or body, based on the supplied data.
 *
 * @param {object} data - Data object containing `script` tag information.
 * @param {boolean} inHead - Boolean flag denoting whether to add the script into the head (true) or body (false).
 */
export function injectScript(data, inHead = true) {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  if (data?.src) {
    newScript.src = data.src;
  }
  newScript.async = data?.async ?? null;
  newScript.defer = data?.defer ?? null;
  /**
   * Note: No need to add test coverage for data-testid usage, as its entire
   * intent is to add the ability for targeting the script tag via tests.
   */
  /* istanbul ignore next */
  if (data?.testId) {
    newScript.setAttribute('data-testid', data.testId);
  }
  // Depending on browser and its age, using try/catch to ensure script gets content via .appendChild() or .text.
  if (data?.content) {
    try {
      newScript.appendChild(document.createTextNode(data?.content));
    } catch (error) {
      // No need to cover test coverage for backup older browser case.
      /* istanbul ignore next */
      newScript.text = data?.content;
    }
  }
  if (inHead) {
    document.head.appendChild(newScript);
  } else {
    document.body.appendChild(newScript);
  }
}

/**
 * Convenience function to generate and return markup for Google Tag Manager, either as a <script> or <noscript> based tag, depending on the specified type. Default: script.
 *
 * @param {string} id - The GTM id value.
 * @param {string} type - The markup type, either 'script' or 'noscript'.
 *
 * @returns {string} The GTM markup for the specified id.
 */
export function generateGtmMarkup(id, type) {
  if (type === GTM_TYPES.noscript) {
    return `<!-- Google Tag Manager (noscript) -->
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <!-- End Google Tag Manager (noscript) -->`;
  }
  return `<!-- Google Tag Manager -->
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');</script>
    <!-- End Google Tag Manager -->`;
}

/**
 * Convenience function to assert that the provided GTM id is valid based on Google Tag Manager id guidelines.
 *
 * @param {string} id - The GTM id value.
 *
 * @returns {boolean} Boolean flag denoting whether or not the specified id is a valid GTM id.
 */
export function isValidGtmId(id) {
  return id && /^GTM-[A-Z0-9]{1,7}$/.test(id);
}
