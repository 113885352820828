/**
 * @module Spacer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';

const Spacer = ({ size }) => {
  return (
    <div className={`spacer-wrapper ${convertValueToClassName(size)}`}></div>
  );
};

export default Spacer;
