/**
 * @module MediaCollectionListing
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import MediaCollectionListingSeriesBased from './Template/MediaCollectionListingSeriesBased';
import MediaCollectionListingAlbumSongBased from './Template/MediaCollectionListingAlbumSongBased';
import { fetchMediaCollectionListing } from '../../helpers/dataFetchers/mediaCollectionListingFetcher';
import { PAGINATION_OPTIONS } from '../../helpers/constants';

const MediaCollectionListing = (props) => {
  const [data, setData] = React.useState(props?.preload);
  const { mediaCollectionType, preload } = props;

  React.useEffect(() => {
    async function fetchData() {
      try {
        const limit =
          preload?.pagination?.limit ||
          PAGINATION_OPTIONS.mediaCollectionListingFetcher.limit;
        const offset =
          preload?.pagination?.offset ||
          PAGINATION_OPTIONS.mediaCollectionListingFetcher.offset;
        const mediaCollectionListingData = await fetchMediaCollectionListing({
          ...props,
          limit,
          offset,
        });
        setData(mediaCollectionListingData);
      } catch (error) {
        /* istanbul ignore next*/
        Log.error(error);
      }
    }

    if (props.preload) {
      setData(props.preload);
    } else {
      fetchData(); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="media-collection-listing"
      data-testid="lc-media-collection-listing"
    >
      {data ? (
        <>
          {mediaCollectionType !== 'worship' ? (
            <MediaCollectionListingSeriesBased
              collectionType={mediaCollectionType}
              inThisSeries={data.inThisSeries}
              latestCollection={data.latestCollection}
              pagination={data.pagination}
              pastCollections={data.pastCollections}
            />
          ) : null}
          {mediaCollectionType === 'worship' ? (
            <MediaCollectionListingAlbumSongBased
              collectionType={mediaCollectionType}
              latestCollection={data.latestCollection}
              pagination={data.pagination}
              pastCollections={data.pastCollections}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default MediaCollectionListing;
