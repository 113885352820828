/**
 * @module LocationInfoCard
 */
import React from 'react';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { DAYS_OF_WEEK } from '@io/web-tools-io/dist/utils/helpers';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import { ACTIONS, EVENTS, MGNL_ENV_VARS } from '../../helpers/constants';
import Spans from '../IconSpans/Spans';
import LCImage from '../LCImage/LCImage';
import LocationPosterSpan from '../LocationPoster/LocationPosterSpan';
import LocationPosterAddress from './LocationPoster/LocationPosterAddress';
import LocationPosterPhone from './LocationPoster/LocationPosterPhone';
import './SpecialEventTimes.scss';

/**
 * Represents a card with location data which includes event times for the specified special event.
 *
 * @param {object} props - The component props object.
 * @param {string} props.location - The location id value.
 * @param {string} [props.sbOnMobile] - Optional class name to apply to component wrapper for mobile screen sizes.
 * @param {string} [props.sbOnTabletAndUp] - Optional class name to apply to component wrapper for tablet and up screen sizes.
 * @param {string} [props.sectionId] - Optional unique id value to assign to the section.
 * @param {string} props.specialEvent - The special event uuid value.
 *
 * @returns {React.ReactElement} The LocationInfoCard component.
 */
const LocationInfoCard = ({
  location,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  specialEvent,
}) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const [locationData, setLocationData] = React.useState(null);
  const [eventTimes, setEventTimes] = React.useState([]);
  const [specialEventData, setSpecialEventData] = React.useState(null);

  /**
   * Handler function for location link click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLocationLinkClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Info Card - Location Poster',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    /* istanbul ignore next */
    callGtagEvent({
      parameters: {
        event_category: specialEventData.name.trim(),
        event_label: `Location Poster`,
        value: event?.currentTarget?.textContent,
      },
      type: 'click',
    });
  }

  /**
   * Convenience function to fetch location data for the component's specified location.
   *
   * @returns {Array} - An array of location data objects.
   */
  /* istanbul ignore next */
  async function getLocationData() {
    if (location) {
      try {
        const locationsResponse = await fetch(
          encodeURI(
            `${getAPIBase(
              MGNL_ENV_VARS,
            )}/.rest/delivery/location?jcr:uuid=${location}`,
          ),
        );
        const json = await locationsResponse.json();
        return json?.results?.length ? json.results[0] : null;
      } catch (error) {
        Log.error(error);
        return null;
      }
    }
    return null;
  }

  /**
   * Single-run convenience effect to fetch special event and location data.
   *
   * Note: The specialEvent/${specialEvent} endpoint returns 404 when the
   * value provided as `specialEvent` is a UUID value and not a string, such
   * as 'Easter' or 'Christmas'. As such, if a UUID is provided, there is a
   * need to use the `jcr:uuid` filter to return proper results.
   *
   * Also, the RegEx used is from a supported and working answer from a
   * StackOverflow question, and checks for proper UUID syntax. This is used
   * since our version of the npm `uuid` package is a deprecated, legacy
   * version that introduces many more errors when updated to a more modern
   * one. As such, using this to avoid a much larger development effort of a
   * project-wide update of all various places `uuid` is used.
   *
   * Endpoints:
   * • String-based: `${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/specialEvent/${specialEvent}`.
   * • UUID-based: `${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/specialEvent?jcr:uuid=${specialEvent}`.
   *
   * References:
   * • https://docs.magnolia-cms.com/headless/api/delivery.html#_filter_types.
   * • https://stackoverflow.com/a/7905992/1914233.
   */
  React.useEffect(() => {
    async function fetchSpecialEventAndLocation() {
      try {
        const isUuid =
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
            specialEvent,
          );
        /* istanbul ignore next */
        const specialEventPath = isUuid
          ? `?jcr:uuid=${specialEvent}`
          : `/${specialEvent}`;
        const response = await fetch(
          encodeURI(
            `${getAPIBase(
              MGNL_ENV_VARS,
            )}/.rest/delivery/specialEvent${specialEventPath}`,
          ),
        );
        const data = await response.json();
        /* istanbul ignore next */
        const resultEventData = isUuid
          ? data?.results?.find(
              (eventData) => eventData['@id'] === specialEvent,
            )
          : data;
        if (resultEventData) {
          const resultEventDataId = resultEventData['@id'];
          const locationResponse = await getLocationData();
          setLocationData(locationResponse);

          /* istanbul ignore next */
          if (locationResponse?.hasSpecialEvent?.field?.toString() === 'true') {
            const special_event =
              locationResponse?.hasSpecialEvent?.specialEvent;
            const obj = special_event?.['@nodes']?.find(
              (node) => special_event[node].event === resultEventDataId,
            );
            if (obj) {
              setSpecialEventData(resultEventData);
              special_event?.['@nodes']?.forEach((i) => {
                /* istanbul ignore next*/
                if (special_event[i]?.event === resultEventData['@id']) {
                  const { eventTimes: specialEventTimes } = special_event[i];
                  const arr = (specialEventTimes?.['@nodes'] ?? []).map(
                    (node) => specialEventTimes[node],
                  );
                  setEventTimes(arr);
                }
              });
            }
          }
        }
      } catch (error) {
        /* istanbul ignore next */
        Log.error(error);
      }
    }

    fetchSpecialEventAndLocation(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* istanbul ignore next */
  const getDateString = (item) => {
    return !!DAYS_OF_WEEK[item.dow] && item.date
      ? `${DAYS_OF_WEEK[item.dow]}, ${item.date}`
      : DAYS_OF_WEEK[item.dow] || item.date || '';
  };

  /**
   * Handler function for button item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const onButtonClick = (event) => {
    const { cityOrTown, primaryAddress, state } = locationData;
    const cityValue = primaryAddress?.cityOrTown || cityOrTown;
    const stateValue = primaryAddress?.state || state;
    const description = `${cityValue}, ${stateValue}`;

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Info Card - Button',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    callGtagEvent({
      parameters: {
        event_category: `${specialEventData.name.trim()} Times`,
        event_label: `Campus CTA ${description}`,
      },
      type: 'click',
    });
  };

  const containerClass = `special-event-times ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`;

  return (
    <>
      <div
        className={containerClass}
        data-testid="lc-locationinfocard"
        id={sectionId}
      >
        {locationData && eventTimes?.length ? (
          <div className="special-events px-normal pb-normal pt-tight">
            <div className="content-wrapper special-events-wrapper">
              <div className="event-subcontainer">
                <h2 className="event-location-name-heading">
                  {`Life.Church ${locationData.name.trim()}`}
                </h2>
                {
                  /* istanbul ignore next */ eventTimes.map((item) => (
                    /* istanbul ignore next */ <div
                      className="special-events-item pb-normal mt-normal"
                      key={item['@id']}
                    >
                      <p className="date text-weight-bold mb-more_slight_tight mt-none line-height-tightest">
                        {getDateString(item)}
                      </p>
                      <div className="poster-event-times" key={item['@id']}>
                        {item.time.split(',').map((eventTime, index) => (
                          <div
                            className="poster-event-time"
                            key={`${eventTime}-${index}`}
                          >
                            {eventTime.replace(/&/g, '').trim()}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="content-wrapper">
              <div
                className="location-data-item"
                data-testid="set-location-address-phone"
              >
                {
                  /* istanbul ignore next */ locationData.primaryAddress ? (
                    <div className="location-data display-flex flex-row mb-normal">
                      <span className="icon-marker-filled">
                        <Spans />
                      </span>
                      <LocationPosterAddress
                        address={locationData.primaryAddress}
                        onClick={handleLocationLinkClick}
                      />
                    </div>
                  ) : null
                }
                {/* Note: Ignore added since test data set only includes `secondaryStreet1` instance on `secondaryAddress` data object, but both added as a fail-safe logical check. */}
                {/* Also only checking for {secondaryStreet1|street1} on `secondaryStreet` since if that is not set, the secondary address is not needed and fully populated. */}
                {
                  /* istanbul ignore next */ locationData.secondaryAddress
                    ?.secondaryStreet1 ||
                  locationData.secondaryAddress?.street1 ? (
                    <div className="location-data display-flex flex-row mb-normal">
                      <span className="icon-about-filled">
                        <Spans />
                      </span>
                      <div>
                        <LocationPosterSpan
                          addLineBreak={true}
                          className={'text-weight-bold'}
                          text="Weekday Contact"
                        />
                        <LocationPosterAddress
                          address={{
                            ...locationData.secondaryAddress,
                            cityOrTown:
                              locationData.secondaryAddress
                                .secondaryCityOrTown ||
                              locationData.secondaryAddress.cityOrTown,
                            latitude:
                              locationData.secondaryAddress.secondaryLatitude ||
                              locationData.secondaryAddress.latitude,
                            longitude:
                              locationData.secondaryAddress
                                .secondaryLongitude ||
                              locationData.secondaryAddress.longitude,
                            state:
                              locationData.secondaryAddress.secondaryState ||
                              locationData.secondaryAddress.state,
                            street1:
                              locationData.secondaryAddress.secondaryStreet1 ||
                              locationData.secondaryAddress.street1,
                            street2:
                              locationData.secondaryAddress.secondaryStreet2 ||
                              locationData.secondaryAddress.street2,
                            zip:
                              locationData.secondaryAddress.secondaryZip ||
                              locationData.secondaryAddress.zip,
                          }}
                          enableAsLink={false}
                          onClick={handleLocationLinkClick}
                        />
                      </div>
                    </div>
                  ) : null
                }
                {
                  /* istanbul ignore next */ locationData.phone ? (
                    <div className="location-data display-flex flex-row">
                      <span className="icon-phone-filled">
                        <Spans />
                      </span>
                      <LocationPosterPhone
                        onClick={handleLocationLinkClick}
                        phone={locationData.phone}
                      />
                    </div>
                  ) : null
                }
              </div>
            </div>
            {
              /* istanbul ignore next */ locationData.pastorName ? (
                <div className="content-wrapper">
                  <div
                    className="location-data-item"
                    data-testid="set-location-pastor"
                  >
                    <div className="display-flex justify-left align-center column-gap-normal mb-normal">
                      {locationData.pastorImage ? (
                        <LCImage
                          className="poster-profile"
                          htmlAttributes={{ alt: 'profile' }}
                          src={`${locationData.pastorImage}`}
                          width={80}
                        />
                      ) : null}
                      <div className="poster-name display-flex flex-column justify-left align-flex-start">
                        <h2 className="text-weight-bold line-height-normal">
                          {locationData.pastorName}
                        </h2>
                        <span className="line-height-normal">Pastor</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            }
            <div className="content-wrapper pt-normal">
              <a
                className="btn btn-secondary size-medium align-center"
                data-testid="set-button-link"
                href={implementUtmParams(
                  `${locationData.slug}`,
                  window?.location,
                )}
                onClick={onButtonClick}
                rel="noreferrer"
                target="_self"
              >
                {`About Life.Church ${locationData.name}`}
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LocationInfoCard;
