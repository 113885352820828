/**
 * @module LaunchedScheduleV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import Button from '../../ButtonItem/ButtonItem';
import Spans from '../../IconSpans/Spans';
import { ACTIONS, EVENTS } from '../../../helpers/constants';
import LaunchedContactCardAddressV2 from './LaunchedContactCardAddressV2';
import './LaunchedScheduleV2.scss';

/**
 * Represents the Service Times Schedule section for a launched campus location page.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isServiceTimesModalOpen] - Value whether the Service Times modal is open.
 * @param {object} [props.locationData] - The location data object.
 * @param {Function} [props.openContactUsModal] - Callback to open the Contact Us modal.
 *
 * @returns {React.Component} The LaunchedScheduleV2 component.
 */
export default function LaunchedScheduleV2({
  isServiceTimesModalOpen,
  locationData,
  openContactUsModal,
}) {
  const { user } = useAuth();
  let sectionClassName = 'bg-black text-white';
  let hourClassName = 'bg-grayscale';
  let descriptionClassName = 'text-gray-30';

  if (isServiceTimesModalOpen) {
    sectionClassName = 'bg-white text-black modal-open';
    hourClassName = 'bg-gray-10';
    descriptionClassName = 'text-gray-100';
  }

  /**
   * Convenience function to trigger `callSegmentTrack` for analytics.
   * This is intended to be a convenient way to avoid code duplication within
   * this file since all properties category values are shared.
   *
   * @param {object} params - The function params object.
   * @param {string} params.event - The event name.
   * @param {string} params.label - The event properties label value.
   */
  function callAnalytics({ event, label }) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: `Location Launched - Service Times`,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  // Handler functions.
  function handleContactUsClick(event) {
    /* istanbul ignore next */
    if (openContactUsModal) {
      openContactUsModal();
    }
    callAnalytics({ event, label: event?.currentTarget?.textContent });
  }

  function handleAddressClick(event) {
    callAnalytics({ event, label: 'Directions' });
  }

  return (
    <>
      {locationData ? (
        <section
          className={`location-schedule-v2 ${sectionClassName}`}
          data-testid="lc-launched-schedule-v2"
        >
          <div className="container">
            <div className="schedule-header">
              <h1 className="text-center">{locationData.title}</h1>
            </div>

            <div className="content">
              <h2>{locationData.subtitle}</h2>
              <p className={descriptionClassName}>{locationData.description}</p>

              {locationData?.serviceEventTimes?.size > 0
                ? [...locationData.serviceEventTimes].map(([day, hours]) => (
                    <div className="schedule-service-times" key={day}>
                      <h3>{day}</h3>
                      <div>
                        {hours.map((hour) => (
                          <span className={hourClassName} key={hour}>
                            {hour}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))
                : null}

              {openContactUsModal ? (
                <Button
                  buttonSize="medium"
                  className="schedule-btn"
                  darkVariant="true"
                  onClick={handleContactUsClick}
                  style="btn-secondary"
                  target=""
                  text="Get More Info"
                />
              ) : null}

              {locationData?.addressData && isServiceTimesModalOpen ? (
                <div className="contact-card-address">
                  <div>
                    <span className="icon icon-marker-filled">
                      <Spans />
                    </span>
                    <h3>{`Life.Church ${locationData?.addressData.locationName}`}</h3>
                  </div>
                  <LaunchedContactCardAddressV2
                    addressData={locationData.addressData}
                    handleAddressClick={handleAddressClick}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}
