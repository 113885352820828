/**
 * @module HomePageHeroFetcher
 */
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getMagnoliaItem } from '@io/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { convertS3DamItems } from '@io/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS } from '../constants';

const tmpSingleContentZone = {
  alignment: 'text-left',
  backgroundImage: '',
  backgroundImageMobile: '',
  backgroundVideo: '',
  bgcolor: 'transparent',
  colorsetting: 'text-black', // Make default color of SingleContentZone
  gradientend: 'transparent',
  gradientstart: 'transparent',
  gradientType: 'none',
  isFullWidth: 'false',
  metadata: {
    '@id': 'c6148cdb-4e72-4963-acc9-481f162c33aa',
    '@name': '0110',
    '@nodeType': 'mgnl:component',
    '@path': '/component1/main/0110',
    'mgnl:template': 'lifechurch:components/singlecontentzone',
  },
  sbOnMobile: 'mb-none',
  sbOnTabletAndUp: 'mb-none',
  zoneHeight: 'normal',
  zones: {
    '@id': 'b20250e0-ccd9-43c9-bc20-dede99573b60',
    '@name': 'zones',
    '@nodes': [],
    '@nodeType': 'mgnl:area',
    '@path': '/component1/main/0110/zones',
    'mgnl:created': '2021-02-19T18:16:34.939+07:00',
    'mgnl:lastModified': '2021-02-19T18:17:03.857+07:00',
  },
};

const tmpOpenContentList = {
  items: {},
  metadata: {
    '@id': '08f3ae7e-4790-4ba4-bc63-5c35682a4730',
    '@name': '00',
    '@nodes': ['type', 'items'],
    '@nodeType': 'mgnl:component',
    '@path': '/Home-Page/main/00',
    'mgnl:created': '2021-01-04T14:35:23.803+07:00',
    'mgnl:lastModified': '2021-01-04T15:18:54.887+07:00',
    'mgnl:template': 'lifechurch:components/opencontentlist',
  },
  sbOnMobile: 'mb-more_relaxed',
  sbOnTabletAndUp: 'mb-even_more_relaxed',
  type: {
    '@id': '06b4ef79-22e9-4edb-acdf-8c16072dae9a',
    '@name': 'type',
    '@nodes': [],
    '@nodeType': 'mgnl:contentNode',
    '@path': '/Home-Page/main/00/type',
    field: 'cards',
    itemsType: 'horizontal-cards',
    'mgnl:created': '2021-01-04T14:35:23.806+07:00',
    'mgnl:lastModified': '2021-01-04T14:35:23.806+07:00',
  },
};

/**
 * Data fetcher for home page hero data.
 *
 * @returns {object} The object containing the home page hero data and its content list.
 */
async function fetchHomePageHero() {
  let data;
  let contentList;
  let tmp;
  try {
    const homePageHeroData = await getMagnoliaItem({
      caller:
        'src/helpers/dataFetchers/homePageHeroFetcher.js > fetchHomePageHero',
      forceCall: true,
      mgnlEnvVars: MGNL_ENV_VARS,
      path: encodeURI(
        `/.rest/delivery/homepageHero?limit=1&orderBy=dateReleased desc`,
      ),
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR
    /* istanbul ignore next */
    if (homePageHeroData?.results?.length) {
      tmp = convertS3DamItems(
        homePageHeroData.results[0],
        process.env.IMGIX_BASE_URL,
      );
      const tmpData = tmpSingleContentZone;
      const enableDarkVariant = tmp.globalColor !== 'text-black';
      const tmpHeroBtn = {
        '@nodes': tmp.heroBtns?.['@nodes'],
      };
      if (tmp?.heroBtns) {
        tmp.heroBtns['@nodes'].forEach((heroButtonNode) => {
          tmpHeroBtn[heroButtonNode] = {
            ...tmp.heroBtns[heroButtonNode],
            '@nodeType': 'mgnl:component',
            buttonSize: 'medium',
            buttonWidth: 'normal-width',
            darkVariants: enableDarkVariant,
            'mgnl:template': 'lifechurch:components/buttonitem',
          };
        });
      }

      const tmpContentList = {
        '@nodes': tmp.contentCards?.['@nodes'],
        '@nodeType': 'mgnl:component',
        'mgnl:template': 'lifechurch:components/opencontentlistitem',
      };

      if (tmp?.contentCards) {
        tmp.contentCards['@nodes'].forEach((cardNode, index) => {
          tmpContentList[cardNode] = {
            ...tmp.contentCards[cardNode],
            '@nodeType': 'mgnl:component',
            cardIdentifier: `homepage-card-${index}`,
            'mgnl:template': 'lifechurch:components/opencontentlistitem',
            wholeCardLink: true,
          };
        });
      }

      tmpData.colorsetting = tmp.globalColor;
      tmpData.backgroundImage = tmp.heroBgImgDesktop;
      tmpData.backgroundImageMobile = tmp.heroBgImgMobile;
      tmpData.zones['@nodes'] = [
        'heroImg',
        'heroTitle',
        'richtext',
        'compositebuttongroup',
      ];
      tmpData.zones.heroImg = {
        '@id': 'ccf2d807-93d9-4de7-9679-10b203a54f7c',
        '@name': 'heroImg',
        '@nodes': [],
        '@nodeType': 'mgnl:component',
        '@path': '/Home-Page/main/0/zones/heroImg',
        image: tmp.heroImg,
        maxwidth: '350',
        'mgnl:created': '2021-01-04T14:12:09.786+07:00',
        'mgnl:lastModified': '2021-01-04T14:28:22.150+07:00',
        'mgnl:template': 'lifechurch:components/mediaasset',
        sbOnMobile: 'mb-normal',
        sbOnTabletAndUp: 'mb-relaxed',
      };

      tmpData.zones.heroTitle = {
        '@id': '357e524c-0eb3-4613-bfcb-f78d5b0f22ff',
        '@name': 'richtext',
        '@nodes': [],
        '@nodeType': 'mgnl:component',
        '@path': '/Home-Page/main/0/zones/richtext',
        alignment: 'text-left',
        content: `<h1 class="text-title">${tmp.title || ''}</h1>`,
        'mgnl:created': '2021-01-04T14:13:03.485+07:00',
        'mgnl:lastModified': '2021-01-04T14:28:10.390+07:00',
        'mgnl:template': 'lifechurch:components/richtext',
        sbOnMobile: '',
        sbOnTabletAndUp: '',
      };

      tmpData.zones.richtext = {
        '@id': '357e524c-0eb3-4613-bfcb-f78d5b0f22ff',
        '@name': 'richtext',
        '@nodes': [],
        '@nodeType': 'mgnl:component',
        '@path': '/Home-Page/main/0/zones/richtext',
        alignment: 'text-left',
        content: tmp.heroDesc,
        'mgnl:created': '2021-01-04T14:13:03.485+07:00',
        'mgnl:lastModified': '2021-01-04T14:28:10.390+07:00',
        'mgnl:template': 'lifechurch:components/richtext',
        sbOnMobile: 'mb-normal',
        sbOnTabletAndUp: 'mb-relaxed',
      };

      tmpData.zones.compositebuttongroup = {
        '@id': '0d9cfcef-e19f-483e-b635-0965f192e1d8',
        '@name': '01',
        '@nodes': ['items'],
        '@nodeType': 'mgnl:component',
        '@path': '/Home-Page/main/0/zones/01',
        alignment: 'left-align',
        buttonSize: 'medium',
        buttonWidth: 'normal-width',
        darkVariants: enableDarkVariant,
        items: {
          ...tmpHeroBtn,
          '@id': '2cfefd39-d698-4161-a177-33dafc259415',
          '@name': 'items',
          '@nodeType': 'mgnl:area',
          '@path': '/Home-Page/main/0/zones/01/items',
          'mgnl:created': '2021-01-04T14:14:00.881+07:00',
          'mgnl:lastModified': '2021-01-04T18:47:52.310+07:00',
        },
        'mgnl:created': '2021-01-04T14:14:00.675+07:00',
        'mgnl:lastModified': '2021-01-04T19:09:12.900+07:00',
        'mgnl:template': 'lifechurch:components/compositebuttongroup',
        sbOnMobile: 'mb-more_relaxed',
        sbOnTabletAndUp: 'mb-even_more_relaxed',
        type: 'buttons-group',
      };
      data = tmpData;
      contentList = {
        ...tmpOpenContentList,
        items: {
          ...tmpContentList,
          '@id': 'e5ea788d-ded8-4468-b834-467fe6000073',
          '@name': 'items',
          '@nodeType': 'mgnl:area',
          '@path': '/Home-Page/main/00/items',
          'mgnl:created': '2021-01-04T14:35:24.000+07:00',
          'mgnl:lastModified': '2021-01-04T14:51:12.335+07:00',
        },
      };
    }
  } catch (error) {
    /* istanbul ignore next */
    Log.error({ error });
  }

  return {
    contentList,
    data,
    tmp,
  };
}

export default fetchHomePageHero;
