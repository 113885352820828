/* eslint-disable no-bitwise */
/**
 * @module InThisSeries
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Carousel from 'nuka-carousel';
import { getUrlStructureByPath } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { mediaUrlFromType } from '@io/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import MediaThumbnail from '../MediaThumbnail/MediaThumbnail';

/**
 * Represents a container with elements and data for the specified media series.
 *
 * @param {object} props - The component props object.
 * @param {object} props.preload - The preloaded data of the series-based media collection.
 * @param {boolean} [props.showAsCarousel] - Optional boolean flag denoting whether or not to display the component as a carousel.
 * @param {boolean} [props.showCount] - Optional boolean flag denoting whether or not to display the number of items with the component header title.
 *
 * @returns {React.ReactElement} The InThisSeries component.
 */
const InThisSeries = ({ preload, showAsCarousel, showCount }) => {
  const { isDesktop, isMobile, isTablet } = useWindowSize();
  const { inThisSeries, mediaCollectionType } = preload;
  const [slideWidthPx, setSlideWidthPx] = React.useState(0);
  const [slidesToShow, setSlidesToShow] = React.useState(1);
  const [draggable, setDraggable] = React.useState(false);
  const [showNoControls, setShowNoControls] = React.useState(false);
  const [isResetSlideIndex, setIsResetSlideIndex] = React.useState(false);

  /**
   * Convenience function to get the episode, part or track number and the title based on the
   * provided segment type.
   *
   * @param {object} segmentTypes - Object containing segment types and their respective values.
   * @param {string} [segmentTypes.episodeNumber] - The episode number.
   * @param {string} [segmentTypes.part] - The part number.
   * @param {string} [segmentTypes.trackNumber] - The track number.
   *
   * @returns {SeriesPartNumberTitle} Data object containing the episode, part or track number and the title.
   */
  function getPartNumberAndTitle(segmentTypes) {
    const result = { number: '', title: '' };
    const currentSegment = Object.keys(segmentTypes).filter(
      (key) => segmentTypes[key],
    )?.[0];

    if (!currentSegment) {
      return result;
    }

    const segments = {
      episodeNumber: 'EPISODE',
      part: 'PART',
      trackNumber: 'TRACK',
    };

    result.number = segmentTypes[currentSegment];
    result.title = segments[currentSegment];

    return result;
  }

  /**
   * Convenience method to check and set the number of slides to show.
   *
   * @param {number} slideWidthValue - The slide width value.
   */
  const checkSlidesToShow = (slideWidthValue) => {
    const slider = document.getElementById('its-media-list');
    /* istanbul ignore next */
    if (slider?.offsetWidth) {
      const sliderWidth = slider.offsetWidth < 1200 ? slider.offsetWidth : 1200;
      setSlidesToShow(~~(sliderWidth / (slideWidthValue + 16)) || 1);
    }
  };

  /**
   * Convenience method to calculate whether or not to show carousel controls.
   */
  const showCarouselControlOrNot = () => {
    const slider = document.getElementById('its-media-list');
    const slides = slider?.querySelectorAll('.media-thumbnail');
    const slidesHaveLength = slides?.length;
    let totalWidth = slidesHaveLength ? slides.length * 16 : 0;

    if (slides?.length) {
      slides.forEach((item) => {
        /* istanbul ignore next */
        totalWidth += item.clientWidth;
      });
    }

    /* istanbul ignore next */
    if (slider && totalWidth < slider?.offsetWidth) {
      setShowNoControls(true);
    } else {
      setShowNoControls(false);
    }
  };

  /**
   * Convenience method to calculate and set carousel state values.
   * Size-specific values for width are set with the following general rules:
   *
   * - Mobile: Card width ~35% (shows 2 full cards and part of 3rd).
   * - Tablet: Card width ~27% (shows 3 full cards and part of 4th).
   * - Desktop: Card width ~20% (shows 4 full cards).
   */
  const checkCarouselSlideWidth = () => {
    let slideWidthValue = (154 * 16) / 9;
    if (isMobile) {
      slideWidthValue = (96 * 16) / 9;
      setSlideWidthPx(slideWidthValue);
      checkSlidesToShow(slideWidthValue);
      setDraggable(true);
    } else if (isDesktop) {
      slideWidthValue = (135 * 16) / 9;
      setSlideWidthPx(slideWidthValue);
      checkSlidesToShow(slideWidthValue);
      setDraggable(false);
    } else {
      setSlideWidthPx(slideWidthValue);
      checkSlidesToShow(slideWidthValue);
      setDraggable(false);
    }
  };

  /**
   * Convenience effect to set slide index reset state when slide width changes.
   */
  React.useEffect(() => {
    setIsResetSlideIndex(true);
  }, [slideWidthPx]);

  /**
   * Convenience method to return a MediaThumbnail component with the specified
   * item data.
   *
   * @param {object} item - The Media Thumbnail data item object.
   *
   * @returns {MediaThumbnail} A MediaThumbnail component.
   */
  const MediaThumbnailContents = (item) => {
    const {
      collection,
      item: itemSlug,
      subCollection,
    } = getUrlStructureByPath(item?.['@path']);
    const { episodeNumber, part, trackNumber } = item;
    const { number, title } = getPartNumberAndTitle({
      episodeNumber,
      part,
      trackNumber,
    });
    const itemUrl = mediaUrlFromType({
      slug: itemSlug,
      subCollection,
      type: collection,
    });

    return (
      <MediaThumbnail
        className="carousel-media"
        image={item?.featuredImage_public ?? ''}
        itemScope={true}
        itemType="https://schema.org/Episode"
        key={item['@id']}
        partNumber={number}
        partTitle={title}
        target="_self"
        timestamp={item?.timestamp}
        title={item.title}
        url={itemUrl}
      />
    );
  };

  /**
   * Convenience effect to set up window resize listeners.
   */
  React.useEffect(() => {
    function handleResize() {
      setIsResetSlideIndex(false);
      showCarouselControlOrNot();
      checkCarouselSlideWidth();
    }
    if (showAsCarousel) {
      checkCarouselSlideWidth();

      setTimeout(() => {
        showCarouselControlOrNot();
        checkCarouselSlideWidth();
      }, 500);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isMobile, isTablet]);

  const titleCount =
    showCount && showAsCarousel && !showNoControls
      ? `(${inThisSeries?.length || '0'})`
      : '';
  const inThisSeriesTitle = `In This Series ${titleCount}`.trim();

  return (
    <>
      {inThisSeries?.length ? (
        <div
          className={`in-this-series container ${
            isMobile ? 'mb-slightly_more_relaxed' : 'mb-even_more_relaxed'
          } ${
            showAsCarousel
              ? 'open-content-list content-general-carousel full-size'
              : ''
          }`.trim()}
          data-testid="lc-in-this-series"
          id="its-media-list"
        >
          <h2
            className={`title ${
              mediaCollectionType === 'switch' ? 'display-none-mobile' : ''
            }`}
          >
            {inThisSeriesTitle}
          </h2>
          {showAsCarousel ? (
            <Carousel
              autoplay={false}
              cellSpacing={16}
              data-testid="lc-in-this-series-carousel"
              defaultControlsConfig={{
                nextButtonClassName: 'custom-next-button',
                nextButtonText: ' ',
                prevButtonClassName: 'custom-prev-button',
                prevButtonText: ' ',
              }}
              dragging={draggable}
              slideIndex={isResetSlideIndex ? 0 : null}
              slideWidth={`${slideWidthPx}px`}
              slidesToScroll={1}
              slidesToShow={slidesToShow}
              speed={1000}
              swiping={true}
              withoutControls={showNoControls}
              wrapAround={false}
            >
              {inThisSeries.map((item) => {
                return MediaThumbnailContents(item);
              })}
            </Carousel>
          ) : (
            <div
              className={`open-content-list content-media-thumbnail ${
                inThisSeries.length === 1 ? 'has-single-item' : ''
              }`}
            >
              <div>
                {inThisSeries.map((item) => {
                  return MediaThumbnailContents(item);
                })}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default InThisSeries;
