/**
 * @module Authentication
 */
import React from 'react';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import {
  getQueryParam,
  getQueryParams,
} from '@io/web-tools-io/dist/utils/helpers/queryParams';
import Loading from '../../assets/loading.svg';
import LifeChurchLogoHorizontal from '../../assets/lc-logo-horizontal.svg';
import { LOCAL_STORAGE_KEYS } from '../../helpers/constants';

/**
 * Represents a view presented to the user when authentication callback is being processed.
 *
 * @returns {React.ReactElement} The Authentication component.
 */
function Authentication() {
  const { configure, handleAuthentication, isConfigured, logIn } = useAuth();

  /**
   * Convenience method to iterate through the provided URLSearchParams and
   * return the base `origin` param (if present) and store the rest in local
   * storage for auth callback, at which point they will be re-added to the
   * `origin` applied to the callback URL.
   *
   * @param {URLSearchParams} searchParams - The URLSearchParams object to inspect for storing and returning updated search params.
   *
   * @returns {string} The updated search params value with `origin` key/value pair, if present in the original URLSearchParams provided.
   */
  function storeAndUpdateNonOriginSearchParams(searchParams) {
    if (!searchParams) {
      return '';
    }
    let updatedUrlSearch = '';
    let searchParamsToStore = '';
    searchParams.forEach((value, key) => {
      if (key === 'origin') {
        updatedUrlSearch = `?${key}=${value}`;
      } else {
        searchParamsToStore += `${
          searchParamsToStore === '' ? '?' : '&'
        }${key}=${value}`;
      }
    });
    if (searchParamsToStore) {
      try {
        window?.localStorage?.setItem(
          LOCAL_STORAGE_KEYS.auth0.redirectOriginParams,
          searchParamsToStore,
        );
      } catch (error) {
        Log.error(error);
      }
    }
    return updatedUrlSearch;
  }

  /**
   * Convenience function to retrieve stored redirect search params from local
   * storage, and if found, also delete it from local storage for cleanup.
   *
   * @returns {string} The stored non-origin search param string, or a blank string if not found.
   */
  function getStoredNonOriginSearchParams() {
    let storedRedirectSearchParams = '';
    try {
      storedRedirectSearchParams =
        window?.localStorage?.getItem(
          LOCAL_STORAGE_KEYS.auth0.redirectOriginParams,
        ) ?? '';
    } catch (error) {
      Log.error(error);
    }
    // If local storage is found, remove item as a way to clean up.
    if (storedRedirectSearchParams) {
      try {
        window?.localStorage?.removeItem(
          LOCAL_STORAGE_KEYS.auth0.redirectOriginParams,
        );
      } catch (error) {
        Log.error(error);
      }
    }
    return storedRedirectSearchParams;
  }

  /**
   * Handler function for Auth0 routing paths.
   *
   * Note: There is logic to store and update the search params, if present on
   * the URL, as a way to save and rebuild the callback origin URL with query
   * params that were originally part of it.
   *
   * This is a necessary step since the URL params are concatenated when being
   * added and going to Auth0, and a query param in the origin URL is seen as
   * a double `?` and thus is set to `&`, thereby losing itself on return. By
   * storing the other search params (when present) in local storage, we can
   * successfully rebuild the URL for `?/origin={origin_url}` and attempt to
   * apply the stored search params to it for the best user experience.
   */
  function handleAuthPath() {
    if (['/auth/auth0', '/auth/auth0/'].includes(window.location.pathname)) {
      let { search: updatedUrlSearch } = window.location;
      if (updatedUrlSearch) {
        updatedUrlSearch = storeAndUpdateNonOriginSearchParams(
          getQueryParams(),
        );
      }
      const hashValue =
        window.location.hash.indexOf('organizationSignUp') > -1
          ? '#organizationSignUp'
          : null;
      logIn({
        hash: hashValue,
        redirectUri: !hashValue
          ? `${window.location.origin}${process.env.AUTH_CALLBACK_URL}${updatedUrlSearch}`
          : null,
      });
    } else if (
      ['/orgsignup', '/orgsignup/'].includes(window.location.pathname)
    ) {
      logIn({ hash: 'organizationSignUp ' });
    } else if (/access_token|id_token|error/.test(window.location.hash)) {
      /**
       * Check for origin query param. If present, ensure no leading slash, and
       * add to handleAuthentication call as parameter.
       */
      let originParam = getQueryParam('origin');
      if (originParam?.charAt(0) === '/') {
        originParam = originParam.substring(1);
      }
      const storedRedirectSearchParams = getStoredNonOriginSearchParams();
      handleAuthentication({
        path: originParam ? `${originParam}${storedRedirectSearchParams}` : '',
      });
    } else {
      window.open(window.location.origin, '_self');
    }
  }

  /**
   * Single-run convenience effect to trigger next step handling.
   * Important: For Auth0, double-checking inclusion of one of the required hash
   * variables to be present in the URL. If not found, redirect to main page.
   */
  React.useEffect(() => {
    if (!isConfigured) {
      const authConfig = {
        audience: process.env.AUTHAUDIENCE,
        clientID: process.env.AUTHCLIENTID,
        domain: process.env.AUTHDOMAIN,
        redirectUri: `${window.location.origin}${process.env.AUTH_CALLBACK_URL}`,
      };
      configure({
        callback: () => {
          handleAuthPath();
        },
        config: authConfig,
      });
    } else {
      handleAuthPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container" data-testid="callback-view">
      <div className="content-center">
        <div className="callback flex-center">
          <img
            alt="Life.Church"
            className="lc-logo"
            src={LifeChurchLogoHorizontal}
          />
          <img alt="Loading" src={Loading} />
        </div>
      </div>
    </div>
  );
}

export default Authentication;
