/**
 * @module MediaDetailSeriesBased
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { addParamsToUrl } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import ButtonItem from '../../ButtonItem/ButtonItem';
import RichText from '../../RichText/RichText';
import ContentAndForm from '../../MediaSharedComponents/ContentAndForm';
import InThisSeries from '../../MediaSharedComponents/InThisSeries';
import RelatedContentCarousel from '../../MediaSharedComponents/RelatedContentCarousel';
import MediaYouTubeOrFallBackPlayer from '../../MediaSharedComponents/MediaYouTubeOrFallBackPlayer';
import Modal from '../../Modal/Modal';
import EmbedItem from '../../EmbedItem/EmbedItem';
import {
  arrIcon,
  arrIconLocal,
} from '../../../helpers/dataFetchers/mediaDetailFetcher';
import { ACTIONS, EVENTS, MGNL_ENV_VARS } from '../../../helpers/constants';
import '../../MediaSharedComponents/style/_MediaComponentSharedStyle.scss';

const DEFAULT_BUTTON_TEXT = 'I want to take a next step';

const MediaDetailSeriesBased = (props) => {
  const {
    mediaItemData,
    mediaItemSeriesBased: preload,
    collectionData,
  } = props;

  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const [openTalkItOver, setOpenTalkItOver] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [nsFormModal, setNsFormModal] = React.useState(false);
  const [formListing, setFormListing] = React.useState(null);
  const [modalForm, setModalForm] = React.useState(null);
  const overrideBtn = mediaItemData?.overrideDefaultMediaButton;
  const { talkItOver, '@path': path } = mediaItemData;
  const collectionType = path?.split('/')?.[1];
  const [
    isStandardNextStepItemsDataAdded,
    setIsStandardNextStepItemsDataAdded,
  ] = React.useState(false);
  const [standardNextStepItems, setStandardNextStepItems] = React.useState({
    '109cdde4-a9cd-4f69-9812-ad24e59683e1': {
      order: 2,
      subtitle: 'Tell us about yourself',
      title: "I'm New",
    },
    '365f27b1-8b99-4cc4-abe7-1d882cc95568': {
      order: 0,
      subtitle: "We'd love to pray with you",
      title: 'Ask for Prayer',
    },
    'b3d93035-8a5a-4080-8f2d-be1c9d6a6b8c': {
      order: 1,
      subtitle: 'Share your decision to follow Christ',
      title: 'Commit to Christ',
    },
  });

  /* istanbul ignore next */
  const modalDivClass = !modalForm ? 'has-spacing' : '';

  /**
   * Convenience function to trigger Segment analytics tracking.
   *
   * @param {Event} event - The Event object.
   * @param {object} properties - Object of properties data to spread onto the analytics properties.
   */
  function callAnalytics(event, properties) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: properties?.component || 'Media Detail',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: properties?.label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        ...properties,
      },
    });
  }

  /**
   * Handler function for Talk It Over event.
   *
   * @param {Event} event - The Event object associated with the Talk It Over visibility request.
   */
  const handleOpenTalkItOver = (event) => {
    event.preventDefault();
    /**
     * Note: When openTalkItOver truthy, it means its open, thus the following
     * logic uses 'Close' when it's true, as the event would be to close it.
     */
    /* istanbul ignore next */
    callAnalytics(event, {
      label: `${openTalkItOver ? 'Close' : 'Show'} Talk It Over`,
    });
    setOpenTalkItOver(!openTalkItOver);
  };

  /**
   * Handler function for modal visibility.
   */
  const handleModal = async () => {
    setFormListing(preload.formListing);
    setModal(!modal);
  };

  /**
   * Handler function for modal button click event.
   *
   * @param {object} data - Data object for modal item object.
   * @param {boolean} isInModal - Optional boolean flag denoting whether or not to launch the data iframe in a modal. If false, will launch via window.open(). Default: false.
   */
  const handleModalButtonClick = (data, isInModal) => {
    // Open url in new tab.
    if (data?.modalType?.type?.iframeSource) {
      const baseUrl = data?.modalType?.type?.iframeSource;
      let fullUrl = '';
      const referrer = `source=web&referrer=${window.location.href}`;
      if (data?.modalType?.type?.urlParams) {
        fullUrl = addParamsToUrl(
          baseUrl,
          `${data?.modalType?.type?.urlParams}&${referrer}`,
        );
      } else {
        fullUrl = addParamsToUrl(baseUrl, referrer);
      }

      // Note: `data.name` is the value of the button triggering this function.
      if (isInModal) {
        callAnalytics(null, {
          component: 'Next Step Carousel',
          component_url: fullUrl,
          label: data?.name,
        });
        setNsFormModal(data);
      } else {
        callAnalytics(null, {
          component: 'Next Step Modal',
          component_url: fullUrl,
          label: data?.name,
        });
        window.open(fullUrl, '_blank');
      }
    } else {
      // Old method if url is absent (modal).
      /* istanbul ignore next */
      setTimeout(() => {
        setModalForm({
          ...data.modalType.type,
        });
      }, 100);
    }
  };

  /**
   * Convenience function to render and return a default carousel of next step cards for message pages.
   *
   * @returns {Carousel} Default carousel element for messages pages.
   */
  const renderNextStepsCarousel = () => {
    return standardNextStepItems &&
      isStandardNextStepItemsDataAdded &&
      ['weekend-messages', 'messages'].includes(collectionType) ? (
      <div className="next-steps-carousel container">
        {Object.values(standardNextStepItems).map((nsItem) => {
          return (
            <button
              className="form-nextstep ns-btn"
              key={nsItem?.data?.['@id']}
              onClick={() => handleModalButtonClick(nsItem?.data, true)}
            >
              <img
                alt={nsItem?.data?.name}
                className="modal-icon"
                src={arrIcon[nsItem?.data?.['@id']]}
              />
              <div className="ns-btn-label">
                <span className="ns-btn-title">{nsItem?.title}</span>
                <span className="ns-btn-subtitle">{nsItem?.subtitle}</span>
              </div>
            </button>
          );
        })}
        <button
          className="form-nextstep ns-btn"
          data-testid="lc-next-step-carousel-card-btn"
          onClick={(event) => {
            handleModal();
            callAnalytics(event, {
              component: 'Next Step Carousel',
            });
          }}
        >
          <img
            alt="horizontal bars"
            className="modal-icon"
            src={arrIconLocal['horizontal-bars']}
          />
          <div className="ns-btn-label">
            <span className="ns-btn-title">Find Your Next Step</span>{' '}
            <span className="ns-btn-subtitle">More ways to connect</span>
          </div>
        </button>
      </div>
    ) : null;
  };

  /**
   * Convenience effect to set standard next step item data objects once the
   * preload.formListing data is available.
   */
  React.useEffect(() => {
    if (
      preload?.formListing &&
      Array.isArray(preload.formListing) &&
      preload.formListing.length
    ) {
      Object.entries(standardNextStepItems).forEach(([itemId, itemData]) => {
        const formListingItem = preload.formListing.find(
          (element) => element['@id'] === itemId,
        );
        setStandardNextStepItems((prevData) => {
          return {
            ...prevData,
            [itemId]: {
              ...itemData,
              data: formListingItem,
            },
          };
        });
      });
      setIsStandardNextStepItemsDataAdded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preload?.formListing]);

  const buttonLinkUrl =
    overrideBtn?.buttonType?.field === 'link'
      ? `${overrideBtn?.buttonType?.url || /* istanbul ignore next*/ ''}`
      : `${getAPIBase(MGNL_ENV_VARS)}${
          overrideBtn?.buttonType?.pageID?.['@path']
        }`;

  return (
    <section
      className="media-detail-series-based"
      data-testid="media-detail-series-based"
      itemScope={true}
      itemType="https://schema.org/Episode"
    >
      <MediaYouTubeOrFallBackPlayer {...mediaItemData} />

      {overrideBtn?.field?.toString() === 'true' ? (
        <ButtonItem
          buttonSize="large"
          className={`align-center override-btn ${
            /* istanbul ignore next */ isMobile
              ? 'mb-relaxed'
              : 'mb-more_relaxed'
          }`}
          modalTrigger={
            overrideBtn?.buttonType?.field === 'modal' &&
            overrideBtn?.buttonType?.modalID
          }
          onClick={
            /* istanbul ignore next */ (event) => {
              callAnalytics(event);
            }
          }
          style="btn-primary"
          text={overrideBtn?.buttonTitle || DEFAULT_BUTTON_TEXT}
          url={
            overrideBtn?.buttonType?.field !== 'modal' ? buttonLinkUrl : null
          }
        />
      ) : (
        renderNextStepsCarousel()
      )}

      {collectionData ? (
        <ContentAndForm
          collectionData={collectionData}
          data={mediaItemData}
          isMedia={true}
          isMobile={isMobile}
          onTalkItOverClick={handleOpenTalkItOver}
          showCollectionTitle={true}
          showSocialShare={false}
        />
      ) : /* istanbul ignore next*/ null}
      {mediaItemData && preload.inThisSeries ? (
        <InThisSeries
          preload={preload.inThisSeries}
          showAsCarousel={true}
          showCount={true}
        />
      ) : null}
      {mediaItemData && preload.relatedContent ? (
        <RelatedContentCarousel
          data={mediaItemData}
          isMobile={isMobile}
          preload={preload.relatedContent}
        />
      ) : null}
      {modal ? (
        <Modal
          className="override-btn-modal"
          setShowStatus={() => {
            setModal(!modal);
            setModalForm(null);
          }}
          showStatus={true}
        >
          {
            /* istanbul ignore next */ !modalForm ? (
              <h2 className="modal-title">Next Steps</h2>
            ) : null
          }
          <div
            className={modalDivClass}
            data-testid="media-detail-series-based-modal"
          >
            {
              /* istanbul ignore next */ !modalForm && formListing
                ? formListing.map((data) => {
                    return (
                      <button
                        className="form-nextstep"
                        key={data['@id']}
                        onClick={() => handleModalButtonClick(data)}
                      >
                        <img
                          alt={data.name}
                          className="modal-icon"
                          src={arrIcon[data['@id']]}
                        />
                        <span>{data.name}</span>
                      </button>
                    );
                  })
                : null
            }
            {
              /* istanbul ignore next */ modalForm ? (
                <EmbedItem type={modalForm} />
              ) : null
            }
          </div>
        </Modal>
      ) : null}
      {nsFormModal ? (
        <Modal
          className="override-btn-modal ns-form-modal"
          data={nsFormModal}
          data-testid="lc-media-detail-series-based-next-step-modal"
          setShowStatus={() => {
            /* istanbul ignore next */
            setNsFormModal(null);
          }}
          showStatus={true}
        />
      ) : null}

      {openTalkItOver && talkItOver ? (
        <Modal
          className="tio-modal"
          setShowStatus={(status) => {
            setOpenTalkItOver(status);
          }}
          showStatus={true}
        >
          <div
            className="talk-it-over container py-normal"
            data-testid="talk-it-over-container"
          >
            <div className="talk-it-over-content">
              <div className="talk-it-over-item">
                <RichText
                  className="tio-content"
                  content={talkItOver.richText}
                />
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </section>
  );
};

export default MediaDetailSeriesBased;
