/**
 * @module SongDetail
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { normalizeTimestamp } from '@io/web-tools-io/dist/utils/helpers/date';
import { generateAlbumSubtitle } from '@io/web-tools-io/dist/utils/helpers/magnolia/generateAlbumSubtitle';
import { mediaUrlFromType } from '@io/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByPath } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import RichText from '../RichText/RichText';
import ButtonItem from '../ButtonItem/ButtonItem';
import Modal from '../Modal/Modal';
import SocialMediaShare from './SocialMediaShare';
import LCImage from '../LCImage/LCImage';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';

/**
 * Component for showing the details of the song.
 *
 * @param {object} props - The component props object.
 * @param {object} props.collectionData - The data of the collection of the song.
 * @param {object} props.data - The data of the song.
 * @param {boolean} props.isMobile - Boolean flag indicating whether or not the screen width is mobile.
 * @param {object} props.preload - The preloaded data of the song.
 *
 * @returns {React.ReactElement} The SongDetail component.
 */
function SongDetail({ collectionData, data, isMobile, preload }) {
  const { user } = useAuth();
  const { lyrics, behindTheMusic, additionalResources } = data;
  const [songList, setSongList] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [copyText, setCopyText] = React.useState('Copy');
  const inputLinkRef = React.useRef(null);

  React.useEffect(() => {
    setSongList(preload?.songList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience function to trigger Segment analytics tracking.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function callAnalytics(event) {
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media - Song Detail',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  // Handler Functions.
  const handleShareLinkClicked = (event) => {
    event.preventDefault();
    setModal(!modal);
    callAnalytics(event);
  };

  const handleCopyBtnClicked = async (event) => {
    callAnalytics(event);
    try {
      event.target.classList.add('copied');
      /* istanbul ignore next*/
      if (inputLinkRef) {
        inputLinkRef.current?.select();
      }
      await navigator.clipboard.writeText(inputLinkRef?.current?.value);
      setCopyText('Copied!');
    } catch (err) {
      /* istanbul ignore next*/
      Log.error('Failed to copy text: ', err);
    }
  };
  const urlStructure = getUrlStructureByPath(data?.['@path']);

  return (
    <div
      className={`song-detail container ${
        isMobile ? 'mb-relaxed' : 'mb-more_relaxed'
      }`}
      data-testid="lc-song-detail"
    >
      {data?.title ? (
        <h1 className="song-name text-title mb-more_slight_tight">
          {data.title}
        </h1>
      ) : null}

      {collectionData ? (
        <ButtonItem
          className="mb-bit_more_relaxed album-link"
          data-testid="lc-collection-data-title-album-link"
          onClick={(event) => callAnalytics(event)}
          style="link-tertiary"
          target="_self"
          text={collectionData.title}
          url={mediaUrlFromType({
            subCollection: urlStructure.subCollection,
            type: urlStructure.collection,
          })}
        />
      ) : null}

      {lyrics || behindTheMusic || additionalResources ? (
        <div className="lyric-and-btm">
          {lyrics ? (
            <div className="lyric-container">
              <h2 className="lyric-title text-group_header mt-none mb-more_slight_tight">
                Lyrics
              </h2>
              <RichText className="no-padding" content={lyrics} />
            </div>
          ) : null}

          {behindTheMusic || additionalResources ? (
            <div
              className={`behind-the-music ${
                isMobile ? 'mt-more_slight_tight' : ''
              }`}
            >
              {behindTheMusic ? (
                <>
                  <h2 className="behind-the-music-title text-group_header mt-none mb-more_slight_tight">
                    Behind The Music
                  </h2>
                  <RichText className="no-padding" content={behindTheMusic} />
                </>
              ) : null}

              {additionalResources?.['@nodes']?.length ? (
                <>
                  <h2 className="song-resources-title text-group_header mt-more_relaxed mb-more_slight_tight">
                    Song Resources
                  </h2>
                  <div className="song-resources-links button-group-wrapper mb-more_relaxed">
                    <ul className="button-item-listing links-group left-align">
                      {additionalResources['@nodes'].map((item) => {
                        const resItem = additionalResources[item];
                        return (
                          <ButtonItem
                            key={resItem['@id']}
                            onClick={(event) => callAnalytics(event)}
                            style="link-tertiary"
                            target={
                              resItem.field === 'url' && !!resItem.target
                                ? resItem.target
                                : '_blank'
                            }
                            text={resItem.text}
                            url={
                              resItem.field === 'url'
                                ? resItem.link
                                : resItem.asset
                            }
                          />
                        );
                      })}
                    </ul>
                  </div>
                </>
              ) : null}

              {collectionData ? (
                <div className="album-image-wrapper display-flex mb-relaxed">
                  {collectionData.channelImageList?.albumImage_public ? (
                    <a
                      className=""
                      data-testid="lc-album-image-link"
                      href={implementUtmParams(
                        mediaUrlFromType({
                          subCollection: urlStructure.subCollection,
                          type: urlStructure.collection,
                        }),
                        window?.location,
                      )}
                      onClick={(event) => callAnalytics(event)}
                    >
                      <LCImage
                        backup={'album-img'}
                        className="album-image"
                        htmlAttributes={{ alt: 'album-image' }}
                        src={collectionData.channelImageList.albumImage_public}
                        width="100"
                      />
                    </a>
                  ) : null}
                  <div className="title-and-total">
                    {collectionData.title ? (
                      <h2 className="album-title text-group_header mt-none mb-tighter">
                        {collectionData.title}
                      </h2>
                    ) : null}
                    {collectionData.parts ||
                    collectionData.albumCopyrightDate ? (
                      <p className="total-song mt-none">
                        {' '}
                        {generateAlbumSubtitle(
                          collectionData.albumCopyrightDate,
                          collectionData.parts,
                        )}{' '}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="song-listing display-flex flex-column">
                {songList?.length
                  ? songList.map((song) => {
                      const { collection, item, subCollection } =
                        getUrlStructureByPath(song?.['@path']);
                      const isCurrentSong = data['@id'] === song['@id'];
                      const songUrl = mediaUrlFromType({
                        slug: item,
                        subCollection,
                        type: collection,
                      });

                      return (
                        <p
                          className={`song-item display-flex m-none`}
                          key={song['@id']}
                        >
                          <a
                            className={`song-item-link ${
                              isCurrentSong ? 'current' : ''
                            }`}
                            href={implementUtmParams(songUrl, window?.location)}
                            onClick={(event) => callAnalytics(event)}
                          >
                            {song?.part ? (
                              <span className="text-weight-bold">
                                {song.part}{' '}
                              </span>
                            ) : null}
                            {song?.title ? (
                              <span className="song-item-title">
                                {' '}
                                {song.title}
                              </span>
                            ) : null}
                          </a>
                          {song?.timestamp ? (
                            <span className="timestamp text-gray-50 ml-auto">
                              {normalizeTimestamp(song.timestamp)}
                            </span>
                          ) : null}
                        </p>
                      );
                    })
                  : null}
              </div>
              <div className="content-wrapper">
                <h3 className="share-album-title text-group_header mt-more_relaxed mb-relaxed">
                  Share The Album
                </h3>
                <SocialMediaShare
                  data={data}
                  instagram={false}
                  linkedin={false}
                  shareLink={true}
                  shareLinkOnClick={handleShareLinkClicked}
                  youtube={false}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : /* istanbul ignore next */ null}

      {modal ? (
        <Modal
          setShowStatus={() => {
            setModal(!modal);
          }}
          showStatus={true}
        >
          <div className="share-link-modal-content">
            <h2 className="share-title">Share The Album</h2>
            <LCImage
              className="album-image"
              htmlAttributes={{ alt: 'album-image' }}
              src={collectionData?.channelImageList?.albumImage_public ?? ''}
              width={100}
            />
            {data?.title ? <p className="album-title">{data.title}</p> : null}
            <div className="link-copy-wrapper">
              <input
                className="link-input-box"
                defaultValue={window.location.href}
                ref={inputLinkRef}
                type="text"
              />
              <ButtonItem
                buttonSize="medium"
                className="btn-copy-link"
                onClick={handleCopyBtnClicked}
                style="btn-primary"
                text={copyText}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default SongDetail;
