/**
 * @module LocationPoster
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { getMagnoliaItem } from '@io/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { mediaUrlFromType } from '@io/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByData } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import Card from '../OpenContentListItem/OpenContentListItem';
import Modal from '../Modal/Modal';
import '../OpenContentList/OpenContentList.scss';
import LCBackground from '../LCImage/LCBackground';
import {
  ENDPOINT_WORKSPACE_MAP,
  MGNL_ENV_VARS,
  WEB_DISTRIBUTION_PLATFORM_UUID,
} from '../../helpers/constants';
import Poster from './Poster';
import './LocationPoster.scss';

const LocationPoster = (props) => {
  const {
    additionalInfo,
    campusCode,
    campusImage,
    email,
    eventTimes,
    facebook,
    hasSpecialEvent,
    instagram,
    label,
    mediaOverride,
    name,
    pastorImage,
    pastorName,
    phone,
    primaryAddress,
    secondaryAddress,
    specialEventData,
    specialEventTimes,
    subtitle,
  } = props;
  const [latestEvent, setLatestEvent] = React.useState(null);
  const [modal, setModal] = React.useState(null);

  React.useEffect(() => {
    async function fetchLatestEvent() {
      let latest;
      if (mediaOverride) {
        try {
          const tmpOverride = await getMagnoliaItem({
            caller: 'src/components/LocationPoster.js > fetchLatestEvent',
            mgnlEnvVars: MGNL_ENV_VARS,
            path: `/.rest/delivery/media?jcr:uuid=${mediaOverride}`,
            workspaceMap: ENDPOINT_WORKSPACE_MAP,
          }); // NOSONAR
          const res = tmpOverride.results[0];
          latest = {};
          const col = !!res.mediaCollection && res.mediaCollection[0];
          const urlStructure = getUrlStructureByData(res);
          latest.description = col.description;
          latest.url = mediaUrlFromType(
            urlStructure.collection,
            urlStructure.subCollection,
            urlStructure.item,
          );
          latest.featuredImage = col.featuredImage;
          latest.channelImageList = col.channelImageList;
        } catch (error) {
          /* istanbul ignore next*/
          Log.error(error);
        }
      } else {
        try {
          const response = await fetch(
            encodeURI(
              `${getAPIBase(
                MGNL_ENV_VARS,
              )}/.rest/delivery/v1/collections?@ancestor=/messages/&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}&orderBy=startDate desc, mgnl:lastActivated desc&limit=1`,
            ),
          );
          const data = await response.json();
          [latest] = data.results;
        } catch (error) {
          /* istanbul ignore next*/
          Log.error(error);
        }
      }
      setLatestEvent(latest);
    }

    fetchLatestEvent(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = (iframeSource) => {
    setModal({
      modalType: {
        field: 'formModal',
        type: {
          field: 'iframe',
          iframeSource,
        },
      },
    });
  };
  const lc = {
    '@path': '/lifegroups',
    description: label?.lifegroups_desc ?? '',
    featuredImage: {
      '@lifekids': `${process.env.IMGIX_BASE_URL}/locations/LK-square.jpg`,
      '@link': `${process.env.IMGIX_BASE_URL}/locations/LG-square.jpg`,
    },
    linkTitle: 'Learn More',
    title: label?.lifegroups_title ?? '',
    urlNoMedia: true,
  };
  return (
    <div className="location-poster" data-testid="lc-location-poster">
      <LCBackground
        className="location-hero"
        imgixParams={{ dpr: 2 }}
        src={campusImage}
      ></LCBackground>
      <div className="location-content mb-even_more_relaxed">
        <Poster
          additionalInfo={additionalInfo}
          campusCode={campusCode}
          email={email}
          facebook={facebook}
          handleOpenModal={handleOpenModal}
          hasSpecialEvent={hasSpecialEvent}
          instagram={instagram}
          label={label}
          locationName={name}
          locationSubtitle={subtitle}
          pastorImage={pastorImage}
          pastorName={pastorName}
          phone={phone}
          primaryAddress={primaryAddress}
          secondaryAddress={secondaryAddress}
          specialEventData={specialEventData}
          specialEventTimes={specialEventTimes}
          weeklyEventTimes={eventTimes}
        />
        <div className="location-card container">
          <div className="open-content-list content-horizontal-cards">
            <div>
              {latestEvent ? (
                <Card
                  content={latestEvent.description}
                  image={
                    latestEvent.featuredImage ||
                    latestEvent.channelImageList?.albumImage ||
                    ''
                  }
                  linkTitle={latestEvent.linkTitle || 'Watch Now'}
                  target="_self"
                  title="Current Series"
                  type="horizontal-cards"
                  url={latestEvent.url || `/media/${latestEvent.slug}`}
                  wholeCardLink="true"
                />
              ) : null}
              <Card
                content={lc.description}
                image={lc.featuredImage['@link']}
                linkTitle={lc.linkTitle}
                target="_self"
                title={lc.title}
                type="horizontal-cards"
                url={lc['@path']}
                wholeCardLink="true"
              />
              <Card
                content={label.new_to_lc_desc}
                image={lc.featuredImage['@lifekids']}
                linkTitle="Tell Us About Your Family"
                target="_blank"
                title={label.new_to_lc_title}
                type="horizontal-cards"
                url="https://my.life.church/forms/family"
                wholeCardLink="true"
              />
            </div>
          </div>
        </div>
      </div>
      {modal ? (
        <Modal
          data={modal}
          setShowStatus={() => setModal(null)}
          showStatus={true}
        />
      ) : null}
    </div>
  );
};

export default LocationPoster;
