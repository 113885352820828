/**
 * @module ForYouSharedSection
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import Button from '../../ButtonItem/ButtonItem';

/**
 * Represents a section of a launched location page used for the "For You", "For Your Family", and "For Your Community" sections.
 *
 * @param {object} props - The component props object.
 * @param {Array} [props.actionBtns] - The buttons for the section.
 * @param {string} [props.className] - The additional class name to be applied to the section.
 * @param {object} props.sectionData - The data for the shared section.
 *
 * @returns {React.Component} The ForYouSharedSection component.
 */
export default function ForYouSharedSection({
  actionBtns,
  className,
  sectionData,
}) {
  const { isMobile, isTablet } = useWindowSize();
  let sectionImg = null;

  const containerClass = `shared-section ${convertValueToClassName(
    className,
  )}`.trim();

  if (isMobile) {
    sectionImg = sectionData?.imgSmall;
  } else if (isTablet) {
    sectionImg = sectionData?.imgMedium;
  } else {
    sectionImg = sectionData?.imgLarge;
  }

  return (
    <>
      {sectionData ? (
        <div className={containerClass} data-testid="lc-for-you-shared-section">
          <div className="container">
            <div className="section-header text-center">
              <h2>{sectionData.title}</h2>
            </div>
            <div className="content">
              {sectionImg ? (
                <div>
                  <img alt={sectionData.title} src={sectionImg} />
                </div>
              ) : null}
              <div>
                <h3>{sectionData.subtitle}</h3>
                <p>{sectionData.description}</p>
                <div className="section-action-btns">
                  {actionBtns
                    ? actionBtns.map((actionBtn) => (
                        <Button
                          buttonSize={actionBtn?.buttonSize ?? 'medium'}
                          key={actionBtn.url}
                          onClick={actionBtn?.onClick ?? null}
                          style={actionBtn?.style ?? 'btn-primary'}
                          text={actionBtn?.text ?? /* istanbul ignore next*/ ''}
                          url={actionBtn?.url ?? /* istanbul ignore next*/ '#'}
                        />
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
