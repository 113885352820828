/**
 * @module PodcastCollectionHero
 */
import React from 'react';
import _ from 'lodash';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { mediaUrlFromType } from '@io/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByPath } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCBackground from '../LCImage/LCBackground';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';

const PodcastCollectionHero = (props) => {
  const { user } = useAuth();
  const { channelImageList, headline, label, latestMedia } = props;
  const { breakpoints, isTablet, width } = useWindowSize();
  const [bgImage, setBgImage] = React.useState('');
  const [backup, setBackup] = React.useState('');
  let imgSrc = '';

  const checkImage = () => {
    const desktopImg = channelImageList?.superHeroImage_public ?? '';
    const tabletImg = channelImageList?.landscapeImage_public ?? '';
    const mobileImg = channelImageList?.albumImage_public ?? '';
    const oldImgSrc = imgSrc;
    const largeImage = isTablet ? tabletImg : desktopImg;
    imgSrc = width < breakpoints.medium ? mobileImg : largeImage;

    const largeBackup = isTablet ? 'landscape-img' : 'super-hero-img';
    setBackup(width < breakpoints.medium ? 'album-img' : largeBackup);

    if (oldImgSrc !== imgSrc) {
      setBgImage(imgSrc);
    }
  };

  React.useEffect(() => {
    checkImage();
    window.addEventListener('resize', checkImage);
    return () => window.removeEventListener('resize', checkImage);
    // Note: Adding dependencies for hook-related state items so resize logic
    // for determining/setting image source is properly utilized and set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet, width]);

  const { collection, item, subCollection } = getUrlStructureByPath(
    latestMedia?.['@path'],
  );
  const newestMediaUrl = mediaUrlFromType({
    slug: item,
    subCollection,
    type: collection,
  });

  /**
   * Convenience function to trigger Segment analytics tracking.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Podcast Collection Hero',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <LCBackground
      backup={backup}
      className="podcast-collection-hero text-white"
      src={bgImage}
    >
      <div className="podcast-hero-content text-center">
        <div className="container">
          {label ? (
            <h2
              className="title"
              dangerouslySetInnerHTML={{
                __html: _.replace(label.hero_title, /~\//g, '/'),
              }}
            />
          ) : null}
          {headline ? <p className="description">{headline}</p> : null}
          {latestMedia ? (
            <ButtonItem
              buttonSize="medium"
              className="podcast-btn-hero dark-mode mx-auto"
              onClick={handleButtonClick}
              style="btn-secondary display-inline-block"
              text="Watch the Newest Episode"
              url={newestMediaUrl}
            />
          ) : null}
        </div>
      </div>
    </LCBackground>
  );
};

export default PodcastCollectionHero;
