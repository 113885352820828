/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module MediaCollectionEarlyChildhoodBased
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import ButtonItem from '../../ButtonItem/ButtonItem';
import { fetchMediaCollection } from '../../../helpers/dataFetchers/mediaCollectionFetcher';
import MediaYouTubeOrFallBackPlayer from '../../MediaSharedComponents/MediaYouTubeOrFallBackPlayer';
import ContentAndForm from '../../MediaSharedComponents/ContentAndForm';
import PastSeries from '../../MediaSharedComponents/PastSeries';
import { ACTIONS, EVENTS } from '../../../helpers/constants';

const MediaCollectionEarlyChildhoodBased = ({ data, pagination, preload }) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = React.useState(false);
  const latestMedia = preload?.latest;
  const pastMediaList = preload.pastMedia;
  const collectionType = data?.['@path']?.split('/')?.[1];

  const [seriesData, setSeriesData] = React.useState(pastMediaList);
  const [limit, setLimit] = React.useState(pagination?.limit || 0);
  const [next, setNext] = React.useState(pagination?.next || 0);
  const [total, setTotal] = React.useState(pagination?.total || 0);

  async function handleShowMoreBtnClicked(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media Collection',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    setIsLoading(true);
    try {
      // Note: Offset that comes from pagination object is *current* offset, not
      // that which should be used for the subsequent API calls.
      /* istanbul ignore next */
      const results = await fetchMediaCollection({
        ...data,
        limitItems: limit >= 1000 ? 12 : limit,
        mediaCollectionType: collectionType,
        next,
        offsetItems: next,
      }); // NOSONAR
      const resultsData = results.collectionTypeSpecificData;
      setSeriesData((prevData) => {
        return [
          ...prevData,
          ...(resultsData.pastMedia || /* istanbul ignore next*/ []),
        ];
      });
      setLimit(results.pagination.limit);
      setNext(results.pagination.next);
      setTotal(results.pagination.total);
      // Since data loads so quickly, it's feasible that toggling between
      // button label of Show More and Loading depending on state would flash
      // too quickly, so a small, short timer helps to avoid instant flicker.
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    } catch (error) {
      /* istanbul ignore next*/
      setIsLoading(false);
      /* istanbul ignore next*/
      Log.error(error);
    }
  }

  return (
    <section
      className="media-collection-early-childhood-based"
      data-testid="lc-media-collection-early-childhood-based"
    >
      {latestMedia ? (
        <MediaYouTubeOrFallBackPlayer {...latestMedia} />
      ) : /* istanbul ignore next*/ null}
      <ContentAndForm
        data={latestMedia}
        isMobile={isMobile}
        showForm={false}
        showSocialShare={false}
      />
      <PastSeries
        className="early-childhood-based"
        collectionType="early-childhood"
        data={seriesData}
        isMobile={isMobile}
        source="media"
      />
      {total > next ? (
        <div
          className={`early-childhood-based ${
            isMobile
              ? 'pb-relaxed pt-relaxed'
              : 'pb-more_relaxed pt-more_relaxed'
          }`}
        >
          <ButtonItem
            buttonSize="medium"
            className={`align-center`}
            disabled={isLoading}
            onClick={handleShowMoreBtnClicked}
            style="btn-secondary"
            text="Show More"
          />
        </div>
      ) : null}
    </section>
  );
};

export default MediaCollectionEarlyChildhoodBased;
