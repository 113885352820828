/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module MediaCollectionPodcastBased
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { getLabelValue } from '@io/web-tools-io/dist/utils/helpers/magnolia/labels';
import PodcastCollectionHero from '../../MediaSharedComponents/PodcastCollectionHero';
import PodcastTextAndImage from '../../MediaSharedComponents/PodcastTextAndImage';
import PodcastCollectionSubscribe from '../../MediaSharedComponents/PodcastCollectionSubscribe';
import PodcastCollectionContentForm from '../../MediaSharedComponents/PodcastCollectionContentForm';
import PodcastAccordion from '../../MediaSharedComponents/PodcastAccordion';
import '../../MediaSharedComponents/style/_MediaComponentSharedStyle.scss';
import PodcastFilter from '../../MediaSharedComponents/PodcastFilter';

const MediaCollectionPodcastBased = (props) => {
  const { featuredImg, latest, modalLabels } =
    props.preload.podcastTextAndImage;

  return (
    <section
      className="podcast-collection"
      data-testid="lc-media-collection-podcast-based"
    >
      <PodcastCollectionHero
        channelImageList={props.channelImageList}
        headline={props.headline}
        label={{ hero_title: getLabelValue(props.labelList, 'hero_title') }}
        latestMedia={latest}
      />
      <PodcastTextAndImage
        featuredImg={featuredImg}
        labelList={props.labelList}
        latestMedia={latest}
        modalLabels={modalLabels}
        podcastTitle={props.title}
      />
      <PodcastCollectionSubscribe
        applePodcastURL={props.applePodcastURL}
        label={{
          subscribeTitle: getLabelValue(
            props.labelList,
            'listen_and_subscribe',
          ),
        }}
        spotifyURL={props.spotifyURL}
        youtubeURL={props.youtubeURL}
      />
      <PodcastCollectionContentForm
        description={props.description}
        headline={props.headline}
        label={{
          form_desc: getLabelValue(props.labelList, 'form_desc'),
          form_title: getLabelValue(props.labelList, 'form_title'),
          social_share_desc: getLabelValue(
            props.labelList,
            'social_share_desc',
          ),
          social_share_title: getLabelValue(
            props.labelList,
            'social_share_title',
          ),
        }}
        title={props.title}
      />
      <PodcastFilter />
      <PodcastAccordion
        {...props}
        filterOutByID={latest?.['@id']}
        label={{
          more_media_title: getLabelValue(props.labelList, 'more_media_title'),
        }}
        pagination={props.preload.pagination}
        preload={props.preload.accordionData}
        source="collection"
      />
    </section>
  );
};

export default MediaCollectionPodcastBased;
