/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module NavigationBar
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { addParamsToUrl } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './NavigationBar.scss';

const NavigationBar = (props) => {
  const { user } = useAuth();
  const [active, setActive] = React.useState(false);
  const navBar = !!props.navBar && !!props.navBar.length && props.navBar[0];
  const colorTheme = navBar?.colorTheme || 'gray';

  const containerClass = `navigation-bar theme-${convertValueToClassName(
    colorTheme,
  )}`.trim();

  const handleMobileMenu = () => {
    setActive(!active);
  };

  const displaySubNavTitle = () => {
    let isDisplaySubNavTitle = true;
    /* istanbul ignore next */
    if (navBar) {
      navBar['@nodes'].forEach((n) => {
        if (navBar[n]['@path'] === props.metadata['@path']) {
          isDisplaySubNavTitle = !navBar[n].hideParentLabel;
        }
      });
    }
    return isDisplaySubNavTitle;
  };

  const displayInSubNav = (node = null) => {
    const page = node ? navBar[node] : navBar;
    return !page?.hideInSubNav;
  };

  const getLink = (node) => {
    let path = node['@path'];
    if (node.queryParams) {
      path = addParamsToUrl(path, node.queryParams);
    }
    return path;
  };

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Navigation Bar',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <section className={containerClass} data-testid="lc-navigation-bar">
      {!!navBar && (
        <div className="container">
          <h1 className="title">
            {!!navBar &&
              !!displaySubNavTitle() &&
              (navBar.parentLabelNavBar || '')}
          </h1>
          <nav className="navigation">
            <span className="mobile-nav-selected" onClick={handleMobileMenu} />
            <ul
              className={
                `${navBar?.title ? navBar.title.toLowerCase() : ''}` +
                `${active ? ' active' : ''}`
              }
            >
              {displayInSubNav() && (
                <li>
                  <a
                    className={
                      props.metadata['@path'] === navBar['@path']
                        ? 'active'
                        : ''
                    }
                    href={implementUtmParams(
                      addParamsToUrl(navBar['@path'], navBar.queryParams),
                      window?.location,
                    )}
                    onClick={handleElementClick}
                  >
                    {navBar?.navigationTitle || navBar?.title}
                  </a>
                </li>
              )}
              {!!navBar &&
                !!navBar['@nodes'].length &&
                navBar['@nodes'].filter(displayInSubNav).map((node) => (
                  <li key={navBar[node]['@id']}>
                    <a
                      className={
                        props.metadata['@path'] === navBar[node]['@path']
                          ? 'active'
                          : ''
                      }
                      href={implementUtmParams(
                        getLink(navBar[node]),
                        window?.location,
                      )}
                      onClick={handleElementClick}
                    >
                      {navBar[node].navigationTitle || navBar[node].title}
                    </a>
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      )}
    </section>
  );
};

export default NavigationBar;
