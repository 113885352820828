/**
 * @module FormHeading
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import './FormHeading.scss';

/**
 * Represents a container with optional img, h1, and p elements for logo, title, and description.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.alt] - The alt text attribute for the img element.
 * @param {string} [props.description] - Optional description value for the component, used in the <p> tag element.
 * @param {string} [props.logo] - Optional logo src URL used for the img element.
 * @param {string} [props.sbOnMobile] - Class name to apply to component wrapper for mobile screen sizes.
 * @param {string} [props.sbOnTabletAndUp] - Class name to apply to component wrapper for tablet and up screen sizes.
 * @param {string} [props.title] - The title value for the component, used in the <h1> tag element.
 *
 * @returns {React.ReactElement} The FormHeading component.
 */
const FormHeading = ({
  alt,
  description,
  logo,
  sbOnMobile,
  sbOnTabletAndUp,
  title,
}) => {
  const { isMobile } = useWindowSize();
  const containerClass = `form-heading ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();

  return (
    <div className={containerClass}>
      {logo ? <img alt={alt} className="form-logo" src={`${logo}`} /> : null}
      {title ? (
        <h1 className="form-title text-section_header">{title}</h1>
      ) : null}
      {description ? <p className="form-description">{description}</p> : null}
    </div>
  );
};

export default FormHeading;
