/**
 * @module CurrentSeries
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { getLabelValue } from '@io/web-tools-io/dist/utils/helpers/magnolia/labels';
import { mediaUrlFromType } from '@io/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { rectifyMediaCollectionType } from '@io/web-tools-io/dist/utils/helpers/magnolia/rectifyMediaCollectionType';
import { getUrlStructureByPath } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import { BREAKPOINTS } from '../../helpers/constants';
import LCImage from '../LCImage/LCImage';

const CurrentSeries = ({
  data,
  labelList,
  source,
  isMobile,
  isTablet,
  hasChecked,
  className = '',
  showDescription = true,
}) => {
  const img =
    source === 'media'
      ? data?.featuredImage_public
      : data?.channelImageList?.cinemaImage_public;
  const collectionType = data?.['@path']?.split('/')?.[1];
  const { collection, subCollection, item } = getUrlStructureByPath(
    data?.['@path'],
  );
  const collectionTitleLabel = getLabelValue(
    labelList,
    rectifyMediaCollectionType(collectionType),
  );
  const mediaUrl = mediaUrlFromType({
    slug: item,
    subCollection,
    type: collection,
  });

  const containerClass = `current-series ${convertValueToClassName(
    className,
  )} ${isMobile ? 'py-bit_more_relaxed' : 'py-very_relaxed '}`.trim();

  return (
    <div className={containerClass} data-testid="lc-current-series">
      <div className="current-series-wrapper container">
        {collectionTitleLabel ? (
          <h1 className="current-series-title">{collectionTitleLabel}</h1>
        ) : null}

        {img && hasChecked ? (
          <a
            className="image-link"
            href={implementUtmParams(mediaUrl, window?.location)}
            target="_self"
          >
            <LCImage
              backup={
                collectionType === 'messages'
                  ? 'message_thumbnail_img'
                  : /* istanbul ignore next */ 'cinema-img'
              }
              className="current-series-image"
              htmlAttributes={{
                alt: img?.metadata?.caption ?? `feature image ${isTablet}`,
              }}
              src={img}
              width={
                isTablet && className !== 'episode-based'
                  ? BREAKPOINTS.large
                  : 684
              }
            />
          </a>
        ) : null}

        {data?.title ? (
          <h2 className="current-series-sub-title">{data.title}</h2>
        ) : null}

        {showDescription && data?.description ? (
          <p className="current-series-desc">{data.description}</p>
        ) : null}
      </div>
    </div>
  );
};

export default CurrentSeries;
