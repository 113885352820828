/**
 * @module LocationPrelaunchedV2
 */
import React from 'react';
import { getLabelValue } from '@io/web-tools-io/dist/utils/helpers/magnolia/labels';
import Modal from '../Modal/Modal';
import PrelaunchedBannerV2 from './V2/PrelaunchedBannerV2';
import PrelaunchedDownloadV2 from './V2/PrelaunchedDownloadV2';
import PrelaunchedHeroV2 from './V2/PrelaunchedHeroV2';
import WhatToExpectV2 from './V2/PrelaunchedWhatToExpectV2';
import './LocationPrelaunched.scss';

const LocationPrelaunchedV2 = (props) => {
  const [modal, setModal] = React.useState(null);

  const { campusImage, facebook, instagram, labelList, name, phase } = props;

  const heroData = {
    campusImage,
    description: phase?.heading,
    label: getLabelValue(labelList, 'hero_btn_txt'),
    name,
    subtitle: phase?.subtitle,
  };

  const whatToExpectData = {
    description: getLabelValue(labelList, 'what_to_expect_desc'),
    subtitle: 'No matter who you are, you’re welcome here.',
    title: 'Life.Church is for You',
  };

  const bannerData = {
    title: `Life.Church ${name}`,
  };

  const downloadData = {
    img: 'https://magnolia-cms-live.s3.amazonaws.com/logos/lc-app.svg',
    title: 'The best way to connect with Life.Church.',
  };

  /**
   * Handler function for modal open event.
   *
   * @param {Event} event - The synthetic Event object associated with the modal open event.
   */
  function handleOpenModal(event) {
    event.preventDefault();
    setModal({
      modalType: {
        field: 'formModal',
        type: {
          field: 'hubspot',
          hubspotFormID: props.hubspotId,
        },
      },
    });
  }

  return (
    <>
      <section
        className="location-prelaunched-v2"
        data-testid="lc-location-prelaunched-v2"
      >
        <PrelaunchedHeroV2
          handleOpenModal={handleOpenModal}
          locationData={heroData}
        />
        <WhatToExpectV2 locationData={whatToExpectData} />
        <PrelaunchedDownloadV2 locationData={downloadData} />
        <PrelaunchedBannerV2
          facebook={facebook}
          handleOpenModal={handleOpenModal}
          instagram={instagram}
          locationData={bannerData}
        />
      </section>
      {modal ? (
        <Modal
          data={modal}
          setShowStatus={() => setModal(null)}
          showStatus={true}
        />
      ) : null}
    </>
  );
};

export default LocationPrelaunchedV2;
