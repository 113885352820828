/**
 * @module Icon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import Spans from '../IconSpans/Spans';

const Icon = ({ icon, iconRenderAsBg }) => {
  let selectedIcon = '';
  if (icon) {
    switch (icon.field) {
      case 'general-icons':
        selectedIcon = icon.generalIcon;
        break;
      case 'external-icons':
        selectedIcon = icon.externalIcon;
        break;
      case 'lc-icons':
        selectedIcon = icon.lcIcon;
        break;
      case 'directional-icons':
        selectedIcon = icon.directionalIcon;
        break;
      case 'channel-icons':
        selectedIcon = icon.channelIcon;
        break;
      default:
        break;
    }
  }
  const spanClass = `icon ${
    iconRenderAsBg ? 'has-bg-image' : ''
  } ${convertValueToClassName(selectedIcon)}`.trim();

  return (
    <>
      {!!icon && !!selectedIcon ? (
        <span className={spanClass} data-testid="lc-button-icon">
          {icon.field === 'external-icons' && !iconRenderAsBg ? (
            <Spans />
          ) : null}
        </span>
      ) : null}
    </>
  );
};

export default Icon;
