/* eslint-disable no-unused-vars */
/**
 * @module Index
 */
import React from 'react';
import { render } from 'react-dom';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import App from './App';
import { LOG_CONFIG } from './helpers/constants';

Log.configure(LOG_CONFIG);

// eslint-disable-next-line react/jsx-props-no-spreading, no-underscore-dangle
render(<App {...window.___appProps} />, document.getElementById('root'));
