/* eslint-disable jsdoc/require-description-complete-sentence */
/**
 * @module BitmovinPlayer
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { Player, PlayerEvent } from 'bitmovin-player';
import { v4 as uuidv4 } from 'uuid';
import { useElapsedTime } from 'use-elapsed-time';
import { EVENTS, MEDIA_TRACKING } from '../../helpers/constants';
import './BitmovinPlayer.scss';

/**
 * Represents a container to display a Bitmovin video player.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.autoplay] - Boolean flag denoting whether or not to auto play the video.
 * @param {object} [props.mediaData] - Data object of needed media item details (e.g. `id`, `title`).
 * @param {boolean} [props.muted] - Boolean flag denoting whether or not the video will be muted.
 * @param {string} [props.poster] - URL for the video poster image.
 * @param {string} [props.videoUrl] - The video URL.
 *
 * @returns {React.ReactElement} The Video component.
 *
 * @see {@link https://cdn.bitmovin.com/player/web/8/docs/interfaces/core.playerapi.html#on}.
 */
const BitmovinPlayer = ({
  autoplay = true,
  mediaData,
  muted = false,
  poster = '',
  videoUrl,
}) => {
  const { user } = useAuth();
  const [id, setId] = React.useState('');
  const [isInitialized, setIsInitialized] = React.useState(false);
  const config = {
    key: 'bdc58d65-1acd-4ad2-9599-f3a9f3b890ea',
    location: {
      /**
       * At the time I use this CDN because the UI plugin (below) has issues
       * with missing icon.
       *
       * @see {@link https://www.npmjs.com/package/bitmovin-player-ui}.
       */
      ui: '//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js',
      ui_css: '//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css',
    },
    playback: {
      autoplay,
      muted: autoplay ? true : muted,
    },
  };
  const source = {
    hls: videoUrl?.includes('.m3u8') ? videoUrl : null,
    poster,
    progressive: videoUrl?.includes('.mp4') ? videoUrl : null,
  };
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [metricsEventData, setMetricsEventData] = React.useState({});

  /**
   * Convenience function to call Segment track method.
   *
   * Note: For tracking, interval values are set in the `MEDIA_TRACKING`
   * constant in `segment.events.timeTriggers`. Also, in its present form,
   * the component doesn't have series or session id values, and as such,
   * only episode_id is set based on the mediaData.id value.
   *
   * @param {number|string} interval - The interval value for the Segment data properties object.
   */
  /* istanbul ignore next */
  function callAnalytics(interval) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.mediaPlayed,
      properties: {
        component: 'Bitmovin Video Player',
        episode_id: mediaData?.id,
        interval: interval === '0' ? parseInt(interval, 10) : interval,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        resource_name:
          mediaData?.title ?? document?.title?.split('|')[0].trim() ?? '',
        series_id: null,
        session_id: null,
        title: document?.title || '',
        type: 'Video',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        video_url: videoUrl,
        youtube_id: null,
      },
    });
  }

  // Utilize elapsed time hook to track playback timing.
  /* istanbul ignore next */
  useElapsedTime({
    isPlaying,
    onUpdate: (value) => {
      const timeKey =
        metricsEventData?.start && !metricsEventData.start.isTracked
          ? 'start'
          : parseInt(Math.floor(value), 10);
      let metricsObject = metricsEventData[timeKey];
      if (timeKey > 60 && timeKey % 60 === 0) {
        metricsObject = {
          isTracked: false,
        };
        setMetricsEventData((prevData) => {
          return {
            ...prevData,
            [timeKey]: metricsObject,
          };
        });
      }
      if (metricsObject && !metricsObject.isTracked) {
        callAnalytics(timeKey === 'start' ? '0' : timeKey);
        setMetricsEventData((prevData) => {
          return {
            ...prevData,
            [timeKey]: {
              ...prevData[timeKey],
              isTracked: true,
            },
          };
        });
      }
    },
    updateInterval: 1,
  });

  /**
   * Single-run convenience effect to set state for metrics tracking data.
   */
  React.useEffect(() => {
    Object.keys(MEDIA_TRACKING.segment.events.timeTriggers).forEach(
      (seconds) => {
        setMetricsEventData((prevData) => {
          return {
            ...prevData,
            [seconds]: {
              isTracked: false,
            },
          };
        });
      },
    );
  }, []);

  /**
   * Handler function for player playing event.
   */
  /* istanbul ignore next */
  function handlePlayerPlaying() {
    setIsPlaying(true);
  }

  /**
   * Handler function for player paused event.
   */
  /* istanbul ignore next */
  function handlePlayerPaused() {
    setIsPlaying(false);
  }

  /**
   * Convenience effect to set id and load player. Note that it is important to
   * ensure that the player has not yet been initialized and that there is a
   * valid videoUrl. If the videoUrl is not set when the player is loaded, it
   * will error out with a "source invalid" error on the player.
   */
  React.useEffect(() => {
    if (!isInitialized && videoUrl) {
      setIsInitialized(true);
      const tmpId = `${uuidv4()}`;
      setId(tmpId);
      // No need to test third-party player for imported component.
      /* istanbul ignore next */
      setTimeout(() => {
        const player = new Player(document.getElementById(tmpId), config);
        player.load(source);
        player.on(PlayerEvent.Playing, handlePlayerPlaying);
        player.on(PlayerEvent.Paused, handlePlayerPaused);
        /**
         * Note that video duration needs to be accessed from an actual
         * Player instance, which we only set here for initial event setup. As
         * such, an inline function is used to keep reference to `player`.
         */
        player.on(PlayerEvent.PlaybackFinished, () => {
          setIsPlaying(false);
          callAnalytics(parseInt(player.getDuration(), 10));
        });
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, videoUrl]);

  return (
    <>
      {videoUrl ? (
        <div
          className="life-church-bitmovin-player video-wrapper"
          data-media-component="YouTube Video"
          data-media-id={mediaData?.id}
          data-media-title={mediaData?.title}
          data-media-url={videoUrl}
          data-media-youtube-id={''}
          data-testid="lc-bitmovin-player"
          id={id}
        ></div>
      ) : null}
    </>
  );
};

export default BitmovinPlayer;
