/**
 * @module PrelaunchedDownloadV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import Button from '../../ButtonItem/ButtonItem';
import { ACTIONS, EVENTS } from '../../../helpers/constants';

/**
 * Represents the new Download section.
 *
 * @param {object} props - The component props object.
 * @param {LocationData} props.locationData - The location data object for the Download section.
 *
 * @returns {React.ReactElement} The PrelaunchedDownloadV2 component.
 */
export default function PrelaunchedDownloadV2({ locationData }) {
  const { user } = useAuth();

  /**
   * Handler function for download button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleDownloadClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Pre-Launched - App Banner',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <section
      className="prelaunched-download"
      data-testid="lc-prelaunched-download-v2"
    >
      <div className="container text-center">
        <div className="download-content display-flex flex-column text-white">
          <img alt="Life.Church Logomark" src={locationData.img} />
          <h2 className="my-relaxed">{locationData.title}</h2>
          <Button
            buttonSize="medium"
            darkVariant="true"
            onClick={handleDownloadClick}
            style="btn-secondary"
            target="_blank"
            text="Download the Life.Church App"
            url="https://www.life.church/app/"
          />
        </div>
      </div>
    </section>
  );
}
