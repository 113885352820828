/**
 * @module HomePageHero
 */
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import SingleContentZone from '../SingleContentZone/SingleContentZone';
import OpenContentList from '../OpenContentList/OpenContentList';
import LifeChurchOnlineOnDemandBanner from '../LifeChurchOnlineOnDemandBanner';
import fetchHomePageHero from '../../helpers/dataFetchers/homePageHeroFetcher';
import fetchLcoOnDemandData from '../../helpers/dataFetchers/lcoOnDemandBannerFetcher';
import './HomePageHero.scss';

const HomePageHero = (props) => {
  const [data, setData] = React.useState(props?.preload);
  const [lcoOnDemandLabels, setLcoOnDemandLabels] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const homePageHeroData = await fetchHomePageHero(props);
        setData(homePageHeroData);
      } catch (error) {
        Log.error(error);
      }
    }

    async function fetchLcoData() {
      const lcoOnDemandData = await fetchLcoOnDemandData();
      setLcoOnDemandLabels(lcoOnDemandData);
    }

    if (props.preload) {
      setData(props.preload);
    } else {
      fetchData(); // NOSONAR
    }
    fetchLcoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect to add and expose a window-level convenience function
   * allowing debugging and testing of banner visibility, and to fetch the label
   * list to store data. The exposed function allows debugging and overriding of
   * the banner visibility and inclusion via setting is_enabled and is_override.
   */
  React.useEffect(() => {
    if (
      lcoOnDemandLabels &&
      Object.keys(lcoOnDemandLabels).length > 0 &&
      !window.showLcoOnDemandBanner
    ) {
      window.showLcoOnDemandBanner = () => {
        setLcoOnDemandLabels((prevStateData) => {
          return {
            ...prevStateData,
            is_enabled: true,
            is_override: true,
          };
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lcoOnDemandLabels]);

  return (
    <section className="homepage-hero">
      {!!data && !!data.data && <SingleContentZone {...data.data} />}
      {!!data && !!data.contentList && (
        <OpenContentList {...data.contentList} />
      )}
      {lcoOnDemandLabels?.is_enabled ? (
        <LifeChurchOnlineOnDemandBanner labelList={lcoOnDemandLabels} />
      ) : null}
    </section>
  );
};

export default HomePageHero;
