/**
 * @module Mapbox
 */
/* global mapboxgl */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Helmet } from 'react-helmet';
import { getLetterForNumber } from '@io/web-tools-io/dist/utils/helpers/strings';
import prelaunchmarker from './prelaunchmarker.png';
import { BREAKPOINTS } from '../../helpers/constants';
import './_Mapbox.scss';

function createGeoJson(lcLocations) {
  return lcLocations.map((location) => {
    const icon =
      location.phase === 'Pre-Launch'
        ? 'prelaunch-map-marker'
        : 'default-map-marker';
    return {
      geometry: {
        coordinates: [location.longitude, location.latitude],
        type: 'Point',
      },
      properties: {
        icon,
        name: location.name,
        slug: location.slug,
        state: location.state,
      },
      type: 'Feature',
    };
  });
}

// eslint-disable-next-line no-unused-vars
function lcPopup(name, slug) {
  return (
    `<a href=/locations/${slug} >\n` +
    `  <p class="popup-paragraph color-black font-weight-bold">Life.Church ${name}</p>\n` +
    `  <a class="popup-link font-weight-bold" href=/locations/${slug}>Learn More</a>\n` +
    `</a>`
  );
}

const Mapbox = ({
  center = false,
  handleMap = () => {},
  isPrelaunch = false,
  locations,
  states = [],
  stateClicked = '',
  zoom,
}) => {
  const mapContainer = React.useRef();

  React.useEffect(() => {
    if (window.mapLoader) {
      handleMap();
    } else {
      window.mapLoader = () => {
        mapboxgl.accessToken =
          'pk.eyJ1IjoiZGlnZXJhdGlsaWNlbnNlcyIsImEiOiJjazduaWl2ZHUwdG5kM2VvbWtxYmp2M2MzIn0.nkLN-NwXanyzYsAAiS2_4w';
        const offCenter =
          document.body.clientWidth >= BREAKPOINTS.large
            ? [-95.4992354, 37.192453]
            : [-99.4992354, 33.192453]; // starting position [lng, lat]
        const customZoom = document.body.clientWidth > 1439 ? 4 : 2;
        const map = new mapboxgl.Map({
          center: center || offCenter,
          container: 'map', // container ID
          style: 'mapbox://styles/digeratilicenses/ckb2d6evz0ktu1jlfrwx41nho', // style URL
          zoom: zoom || customZoom,
        });

        window.lifeChurchMap = map; // Save map to window for update center and zoom

        window.lifeChurchMap.on('load', () => {
          const customEvent = new CustomEvent('mapLoaded', {
            detail: 'loaded',
          });
          document.dispatchEvent(customEvent);
        });

        const geoJSON = createGeoJson(locations);
        map.on('load', () => {
          map.addSource('states', {
            data: 'https://docs.mapbox.com/mapbox-gl-js/assets/us_states.geojson',
            type: 'geojson',
          });

          map.addLayer({
            filter: ['in', 'STATE_NAME', ...states],
            id: 'state-fills',
            paint: {
              'fill-color': '#000',
              'fill-opacity': 0.08,
            },
            source: 'states',
            type: 'fill',
          });

          map.addSource('lcLocations', {
            cluster: true,
            clusterMaxZoom: 6,
            clusterRadius: 30,
            data: {
              features: geoJSON,
              type: 'FeatureCollection',
            },
            type: 'geojson',
          });

          if (isPrelaunch) {
            map.loadImage(prelaunchmarker, (error, image) => {
              if (error) {
                throw error;
              }

              // Add the image to the map style.
              map.addImage('prelaunchmarker', image);
            });
          } else {
            map.addControl(
              new mapboxgl.NavigationControl({ showCompass: false }),
            );
          }

          window.createClickPopup = (event) => {
            const coordinates = event.features[0].geometry.coordinates.slice();
            const { properties } = event.features[0];

            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(
                `<a href="/${properties.slug}/"><p class="popup-paragraph color-black font-weight-bold">Life.Church ${properties.name}</p><a class="popup-link font-weight-bold" href="/${properties.slug}/">Learn More</a></a>`,
              )
              .addTo(map);
          };

          map.addLayer({
            filter: ['has', 'point_count'],
            id: 'clusters',
            paint: {
              'circle-color': '#2d2d2e',
              'circle-radius': 20,
            },
            source: 'lcLocations',
            type: 'circle',
          });

          map.on('click', 'clusters', (e) => {
            // eslint-disable-next-line testing-library/render-result-naming-convention
            const features = map.queryRenderedFeatures(e.point, {
              layers: ['clusters'],
            });
            const clusterId = features[0].properties.cluster_id;
            map
              .getSource('lcLocations')
              .getClusterExpansionZoom(clusterId, (err, zoomValue) => {
                if (err) {
                  return;
                }

                map.easeTo({
                  center: features[0].geometry.coordinates,
                  zoomValue,
                });
              });
          });

          map.addLayer({
            filter: ['has', 'point_count'],
            id: 'cluster-count',
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-size': 12,
            },
            paint: {
              'text-color': '#ffffff',
            },
            source: 'lcLocations',
            type: 'symbol',
          });

          map.addLayer({
            // Learn about filter and expressions here https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/, https://docs.mapbox.com/help/tutorials/mapbox-gl-js-expressions/
            filter: [
              'all',
              ['!', ['has', 'point_count']],
              ['==', 'default-map-marker', ['get', 'icon']],
            ],
            id: 'unclustered-point-default',
            layout: {
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-image': isPrelaunch
                ? 'prelaunchmarker'
                : 'default-map-marker',
              'icon-size': 1.2,
            },
            source: 'lcLocations',
            type: 'symbol',
          });

          map.addLayer({
            filter: [
              'all',
              ['!', ['has', 'point_count']],
              ['==', 'prelaunch-map-marker', ['get', 'icon']],
            ],
            id: 'unclustered-point-prelaunch',
            layout: {
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-image': 'blue-map-marker',
              'icon-size': 1.2,
            },
            source: 'lcLocations',
            type: 'symbol',
          });

          map.on('click', 'unclustered-point-default', (event) => {
            window.createClickPopup(event);
          });

          map.on('click', 'unclustered-point-prelaunch', (event) => {
            window.createClickPopup(event);
          });

          // Change the cursor to a pointer when the mouse is over the places layer.
          map.on('mouseenter', 'lcLocations', () => {
            map.getCanvas().style.cursor = 'pointer';
          });

          // Change it back to a pointer when it leaves.
          map.on('mouseleave', 'lcLocations', () => {
            map.getCanvas().style.cursor = '';
          });
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, center]);

  // Handler logic for the letters for the markers.
  React.useEffect(() => {
    const lcMap = window?.lifeChurchMap;

    if (stateClicked && lcMap) {
      const source = lcMap.getSource('lcLocations');
      // eslint-disable-next-line no-underscore-dangle
      const sourceFeatures = source?._data?.features || [];

      const featuresOfStateClicked = sourceFeatures.reduce((acc, curr) => {
        if (curr.properties.state === stateClicked) {
          acc.push({
            ...curr,
            properties: {
              ...curr.properties,
              letter: getLetterForNumber(acc.length),
            },
          });
        }
        return acc;
      }, []);

      const locationCampuses = {
        features: featuresOfStateClicked,
        type: 'FeatureCollection',
      };

      lcMap.addSource('locationCampuses', {
        cluster: true,
        clusterMaxZoom: 6,
        clusterRadius: 30,
        data: locationCampuses,
        type: 'geojson',
      });

      lcMap.addLayer({
        filter: ['!', ['has', 'point_count']],
        id: 'locationCampusesLayer',
        layout: {
          'text-field': ['get', 'letter'],
          'text-offset': [0, -1],
          'text-size': 14,
        },
        paint: {
          'text-color': '#fff',
          'text-halo-color': '#fff',
          'text-halo-width': 0.3,
        },
        source: 'locationCampuses',
        type: 'symbol',
      });
    } else if (lcMap?.getSource('locationCampuses')) {
      lcMap.removeLayer('locationCampusesLayer');
      lcMap.removeSource('locationCampuses');
    }
  }, [stateClicked]);

  return (
    <>
      <Helmet>
        <script
          onLoad="window.mapLoader()"
          src="https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.js"
        ></script>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>
      <div
        className="map-container"
        id="map"
        ref={mapContainer}
        style={{ height: '100%', width: '100%' }}
      />
    </>
  );
};

export default Mapbox;
