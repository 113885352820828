/**
 * @module DualContentZone
 */
import React from 'react';
import { EditableArea, EditorContextHelper } from '@magnolia/react-editor';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { convertToCmsAssetUrl } from '@io/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import LCBackground from '../LCImage/LCBackground';
import YoutubeVideo from '../YoutubeVideo/YoutubeVideo';
import './DualContentZone.scss';

const DualContentZone = ({
  arealeft,
  arearight,
  leftAreaBgImage,
  leftAreaBgImageMobile,
  leftAreaBgColor,
  leftAreaBgPosition,
  leftTextColor,
  rightAreaBgImage,
  rightAreaBgImageMobile,
  rightAreaBgColor,
  rightAreaBgPosition,
  rightTextColor,
  alignment,
  backgroundImage,
  backgroundImageMobile,
  backgroundColor,
  bgRepeat,
  bgcolor,
  gradientstart,
  gradientend,
  gradientType,
  metadata,
  sbOnMobile,
  sbOnTabletAndUp,
  pyOnMobile = 'py-even_more_relaxed',
  pyOnTabletAndUp = 'py-even_more_relaxed',
  spaceBetweenMobile,
  spaceBetweenTabletDesktop,
  alignMobile = 'direction-vertical',
  alignTabletMobile,
  reverseMobile,
  icon,
  sectionId,
  backgroundVideo,
  youtubeID,
  verticalAlignment = '',
  zoneHeight,
}) => {
  const { isMobile, isTablet, isDesktop } = useWindowSize();
  const isVertical =
    alignTabletMobile === 'direction-vertical' ? 'bg-vertical' : '';
  const dualRef = React.useRef(null);
  const desktopBgImg = backgroundImage || '';
  const [finalVideoBackground, setFinalVideoBackground] = React.useState();
  const [youTubeVideoError, setYouTubeVideoError] = React.useState(false);

  let mobileBgImg = '';
  if (backgroundImageMobile) {
    mobileBgImg = backgroundImageMobile;
  } else if (backgroundImage) {
    mobileBgImg = backgroundImage;
  }

  const backgroundSrc = !isDesktop ? mobileBgImg : desktopBgImg;

  /* istanbul ignore next */
  const isDevMode = EditorContextHelper.inIframe();

  /* istanbul ignore next */
  const checkIsMobileTablet = () => {
    // Dynamic Calculate Left And Right Area Height for Vertical Direction
    setTimeout(() => {
      if (dualRef?.current) {
        const { current } = dualRef;
        const leftAreaBg = dualRef.current.querySelector('.bg-area-left');
        const rightAreaBg = dualRef.current.querySelector('.bg-area-right');
        if (alignTabletMobile === 'direction-vertical' || isMobile) {
          const leftArea = current.querySelector('.left-area');
          const rightArea = current.querySelector('.right-area');
          if (!!leftArea && !!rightArea) {
            const currentZoneHeight = current.offsetHeight;
            const spacing =
              (currentZoneHeight -
                leftArea.offsetHeight -
                rightArea.offsetHeight -
                72 * 2) /
              2;
            const leftAreaBgHeight =
              current.offsetHeight - rightArea.offsetHeight - spacing - 72; // 72 is padding top of the zone
            const rightAreaBgHeight =
              current.offsetHeight - leftArea.offsetHeight - spacing - 72;
            if (!!leftAreaBg && leftAreaBgPosition === 'fiftyLeft') {
              leftAreaBg.style.height = `${leftAreaBgHeight}px`;
            }
            if (!!rightAreaBg && rightAreaBgPosition === 'fiftyRight') {
              rightAreaBg.style.height = `${rightAreaBgHeight}px`;
            }
          }
        } else {
          if (leftAreaBg) {
            leftAreaBg.style.height = '';
          }
          if (rightAreaBg) {
            rightAreaBg.style.height = '';
          }
        }
      }
    }, 250);
  };

  /**
   * Convenience callback and memo to determine final video URL based on convert
   * to CMS Assets URL rather than Imgix.
   */
  const convertUrl = React.useCallback(async () => {
    try {
      const cmsAssetUrl = await convertToCmsAssetUrl({
        cmsAssetsBaseUrl: process.env.CMS_ASSETS_BASE_URL,
        imgixBaseUrl: process.env.IMGIX_BASE_URL,
        source: backgroundVideo,
      });
      setFinalVideoBackground(cmsAssetUrl);
    } catch (error) {
      /* istanbul ignore next */
      Log.error(error);
    }
  }, [backgroundVideo]);

  /**
   * Convenience effect to trigger url conversion to CMS Asset.
   */
  React.useEffect(() => {
    convertUrl();
  }, [backgroundVideo, convertUrl]);

  React.useEffect(() => {
    function handleResize() {
      /* istanbul ignore next */
      checkIsMobileTablet();
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('DOMContentLoaded', checkIsMobileTablet());
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('DOMContentLoaded', checkIsMobileTablet);
    };
    // Note: Adding dependencies for hook-related state items so resize logic
    // for determining/setting image source is properly utilized and set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isTablet]);

  const reversedClassname = reverseMobile && isMobile ? 'reversed' : '';

  const leftAreaBgImageToUse = isMobile
    ? leftAreaBgImageMobile
    : leftAreaBgImage;

  const leftAreaBgSrc =
    leftAreaBgPosition === 'normal' ? leftAreaBgImageToUse || '' : '';

  const rightAreaBgToUse = isMobile ? rightAreaBgImageMobile : rightAreaBgImage;

  const rightAreaBgSrc =
    rightAreaBgPosition === 'normal' ? rightAreaBgToUse || '' : '';

  const iconClassName = !backgroundImage
    ? ` bg-${convertValueToClassName(bgcolor)} p-10`
    : '';
  const sectionLcBackgroundMobileClass = isMobile
    ? `${convertValueToClassName(sbOnMobile)} ${convertValueToClassName(
        pyOnMobile,
      )}`
    : `${convertValueToClassName(sbOnTabletAndUp)} ${convertValueToClassName(
        pyOnTabletAndUp,
      )}`;
  const sectionLcBackgroundClass =
    `dual-contentzone bg ${convertValueToClassName(isVertical)} bg-${bgcolor} ${
      bgRepeat === 'true' ? 'bg-repeat' : ''
    } ${icon ? 'mt-56' : ''} ${sectionLcBackgroundMobileClass} ${
      zoneHeight === '70vh' ? 'height-70vh' : ''
    }`.trim();
  const leftLcBackgroundClass = `bg bg-area-left bg-${convertValueToClassName(
    leftAreaBgColor,
  )} ${reverseMobile && isMobile ? 'reversed' : ''}`.trim();
  const rightLcBackgroundClass = `bg bg-area-right bg-${convertValueToClassName(
    rightAreaBgColor,
  )} ${reverseMobile && isMobile ? 'reversed' : ''}`.trim();
  const areaContainerClass = `container ${convertValueToClassName(
    verticalAlignment,
  )} ${
    isMobile
      ? `${convertValueToClassName(
          spaceBetweenMobile,
        )} ${convertValueToClassName(alignMobile)} ${convertValueToClassName(
          reversedClassname,
        )}`
      : `${convertValueToClassName(
          spaceBetweenTabletDesktop,
        )} ${convertValueToClassName(alignTabletMobile)}`
  }`.trim();
  const leftAreaLcBackgroundClass = `left-area bg ${
    leftAreaBgPosition === 'normal' && !!leftAreaBgColor
      ? `bg-${convertValueToClassName(leftAreaBgColor)}`
      : ''
  } ${convertValueToClassName(alignment)} ${convertValueToClassName(
    leftTextColor,
  )} ${zoneHeight === '70vh' ? 'height-35vh' : ''}`.trim();
  const rightAreaLcBackgroundContainerClass = `right-area bg ${
    rightAreaBgPosition === 'normal' && !!rightAreaBgColor
      ? `bg-${convertValueToClassName(rightAreaBgColor)}`
      : ''
  } ${convertValueToClassName(alignment)} ${convertValueToClassName(
    rightTextColor,
  )} ${zoneHeight === '70vh' ? 'height-35vh' : ''}`.trim();
  const gradientClass = `gradient-${convertValueToClassName(
    gradientstart,
  )}-${convertValueToClassName(gradientend)}-${convertValueToClassName(
    gradientType,
  )}`;

  const handleOnYTNotAvailable = (error) => {
    setYouTubeVideoError(error);
  };

  return (
    <section data-testid="dualcontentzone" id={sectionId} ref={dualRef}>
      <LCBackground
        className={sectionLcBackgroundClass}
        devmodeexception={isDevMode}
        imgixParams={bgRepeat === 'true' ? { ar: '1:1' } : ''}
        parentref={dualRef}
        src={backgroundSrc}
        style={{ backgroundColor }}
      >
        {gradientType !== 'none' && (
          <div
            className={gradientClass}
            style={{
              height: '100%',
              position: 'absolute',
              top: 0,
              width: '100%',
              zIndex: '0',
            }}
          ></div>
        )}

        {/* For Area Left Fifty Fifty Left Background */}
        {(!!leftAreaBgImage || !!leftAreaBgImageMobile || !!leftAreaBgColor) &&
        leftAreaBgPosition === 'fiftyLeft' ? (
          <LCBackground
            className={leftLcBackgroundClass}
            parentref={dualRef}
            src={isMobile ? leftAreaBgImageMobile : leftAreaBgImage}
          ></LCBackground>
        ) : null}
        {/* For Area Right Fifty Fifty Right Background */}
        {(!!rightAreaBgImage ||
          !!rightAreaBgImageMobile ||
          !!rightAreaBgColor) &&
        rightAreaBgPosition === 'fiftyRight' ? (
          <LCBackground
            className={rightLcBackgroundClass}
            parentref={dualRef}
            src={isMobile ? rightAreaBgImageMobile : rightAreaBgImage}
          ></LCBackground>
        ) : null}

        {finalVideoBackground && !youtubeID && !isMobile ? (
          <video
            autoPlay="autoplay"
            className="video-background"
            loop={true}
            muted={true}
            style={{ maxWidth: '100%' }}
          >
            <source src={finalVideoBackground} />
          </video>
        ) : null}

        {youtubeID && !youTubeVideoError ? (
          <div className="video-background" style={{ maxWidth: '100%' }}>
            <YoutubeVideo
              autoplay={true}
              hideInfo={true}
              loop={true}
              onYTNotAvailable={handleOnYTNotAvailable}
              youtubeID={youtubeID}
            />
          </div>
        ) : null}

        {icon ? (
          <div className={`singleIcon${iconClassName}`}>
            <img alt="single-icon" src={`${icon}`} />
          </div>
        ) : null}

        <div className={areaContainerClass}>
          {arealeft ? (
            <LCBackground
              className={leftAreaLcBackgroundClass}
              src={leftAreaBgSrc}
            >
              <EditableArea
                content={arealeft}
                parentTemplateId={metadata['mgnl:template']}
              />
            </LCBackground>
          ) : null}
          {arearight ? (
            <LCBackground
              className={rightAreaLcBackgroundContainerClass}
              src={rightAreaBgSrc}
            >
              <EditableArea
                content={arearight}
                parentTemplateId={metadata['mgnl:template']}
              />
            </LCBackground>
          ) : null}
        </div>
      </LCBackground>
    </section>
  );
};

export default DualContentZone;
