/**
 * @module LocationPrelaunchedHero
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import Button from '../ButtonItem/ButtonItem';
import Modal from '../Modal/Modal';
import LCBackground from '../LCImage/LCBackground';
import './LocationPrelaunchedHero.scss';

const LocationPrelaunchedHero = (props) => {
  const { user } = useAuth();
  const [modal, setModal] = React.useState(null);
  const { label } = props;

  const handleOpenModal = (event) => {
    event.preventDefault();
    setModal({
      modalType: {
        field: 'formModal',
        type: {
          field: 'hubspot',
          hubspotFormID: props.hubspotId,
        },
      },
    });

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Pre-Launched - Header',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  };
  return (
    <>
      <section data-testid="lc-location-prelaunched-hero">
        <LCBackground
          className="location-prelaunched-hero text-center text-white"
          src={props.campusImage}
        >
          <div className="container">
            <h1 className="title mb-tighter">Life.Church {props.name}</h1>
            <h2 className="subtitle">{props.phase.subtitle}</h2>
            <h3 className="description mx-auto mb-bit_more_relaxed">
              {props.phase.heading}
            </h3>
            {!!props.hubspotId && (
              <div className="button-group-wrapper">
                <ul className="button-item-listing buttons-group center-align btn-width-auto">
                  <div>
                    <Button
                      buttonSize="medium"
                      className="btn-hero"
                      darkVariant="true"
                      onClick={handleOpenModal}
                      style="btn-primary"
                      target=""
                      text={!!label && label.hero_btn_txt}
                      url="#"
                    />
                  </div>
                </ul>
              </div>
            )}
          </div>
        </LCBackground>
      </section>
      {!!modal && (
        <Modal
          data={modal}
          setShowStatus={() => setModal(null)}
          showStatus={true}
        />
      )}
    </>
  );
};

export default LocationPrelaunchedHero;
