/**
 * @module Radio
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import './Radio.scss';

/**
 * Represents a form control element containing radio input wrapped with a label element.
 *
 * @param {object} props - The component props object.
 * @param {string} props.inputName - The value to use for the input name attribute.
 * @param {string} props.label - The label value for the component.
 * @param {string} props.type - The input type (Example: radio).
 *
 * @returns {React.ReactElement} The Radio component.
 */
const Radio = ({ inputName, label, type }) => {
  return (
    <div className="form-control" data-testid="radio-component-wrapper">
      <label>
        <input
          className="radio display-none"
          data-testid="radio-input"
          name={inputName}
          type={type}
        />
        <span
          className={convertValueToClassName(type)}
          data-testid="radio-span"
        ></span>
        <span className="label-text" data-testid="radio-label">
          {label}
        </span>
      </label>
    </div>
  );
};

export default Radio;
