/**
 * @module OpenContentList
 */
import React from 'react';
import Carousel from 'nuka-carousel';
import {
  EditableArea,
  EditableComponent,
  EditorContextHelper,
} from '@magnolia/react-editor';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { convertValueToClassName } from '@io/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@io/web-tools-io/dist/hooks/useWindowSize';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './OpenContentList.scss';

const OpenContentList = (props) => {
  const { user } = useAuth();
  const { isDesktop, isMobile } = useWindowSize();
  const [slideWidth, setSlideWidth] = React.useState(0);
  const [showNoControls, setShowNoControls] = React.useState(false);
  const [spacing, setSpacing] = React.useState(16);
  const [slidesToShow, setSlidesToShow] = React.useState(1);
  const isDevMode = EditorContextHelper.inIframe();
  let { type } = props;

  /**
   * Note: metadata['@id'] not necessarily present since media migration. With
   * current logic using and overriding `type`, get the type['@id'] value stored
   * and use to set unique id when no sectionId present, before overriding type.
   */
  const typeId = type['@id'];
  const { items, metadata, sbOnMobile, sbOnTabletAndUp, sectionId } = props;
  type = type.field === 'media-thumbnail' ? type.field : type.itemsType;
  const id = sectionId || `opc-${typeId}`;

  items?.['@nodes']?.forEach((key) => {
    items[key].type = type;
  });
  const itemsNodesLength = Number(items?.['@nodes']?.length);
  const isEvenOddClassName = itemsNodesLength % 2 === 0 ? 'even' : 'odd';
  const singleItemClassName =
    items?.['@nodes']?.length === 1 ? 'has-single-item' : '';
  const isCarousel = type?.includes('carousel');
  const carouselConfig = isCarousel
    ? {
        autoPlay: props.type?.autoPlay?.toString() === 'true',
        autoPlayInterval: props.type.autoPlayInterval
          ? parseInt(props.type.autoPlayInterval, 10)
          : 3000,
        speed: props.type.speed ? parseInt(props.type.speed, 10) : 1000,
      }
    : {};

  const containerClass =
    `container open-content-list content-${convertValueToClassName(
      type,
    )} ${convertValueToClassName(isEvenOddClassName)} ${convertValueToClassName(
      singleItemClassName,
    )} ${
      isMobile
        ? convertValueToClassName(sbOnMobile)
        : convertValueToClassName(sbOnTabletAndUp)
    }`.trim();

  const checkSlidesToShow = (slide) => {
    const slider = document.getElementById(id);
    if (!!slider && !!slider.offsetWidth) {
      const width = slider.offsetWidth < 1200 ? slider.offsetWidth : 1200;
      // eslint-disable-next-line no-bitwise
      setSlidesToShow(~~(width / (slide + 16)) || 1);
    }
  };

  const showCarouselControlOrNot = () => {
    if (isCarousel && ['album-carousel', 'general-carousel'].includes(type)) {
      const slider = document.getElementById(`opc-${metadata['@id']}`);
      const slides = slider
        ? [...slider.querySelectorAll('.slider-slide')]
        : [];
      let totalWidth = slides ? slides.length * 16 : 0;

      if (Array.isArray(slides)) {
        slides?.forEach((item) => {
          totalWidth += item.clientWidth;
        });
      }

      if (slider && totalWidth < slider.offsetWidth) {
        setShowNoControls(true);
      } else {
        setShowNoControls(false);
      }
    }
  };

  const checkCarouselSlideWidth = () => {
    const dimensionsMap = {
      'album-carousel': {
        desktop: { slidesShowing: 214, width: '214px' },
        mobile: { slidesShowing: 131, width: '131px' },
        tablet: { slidesShowing: 145, width: '145px' },
      },
      'general-carousel': {
        desktop: {
          slidesShowing: (210 * 16) / 9,
          width: `${(210 * 16) / 9}px`,
        },
        mobile: { slidesShowing: (150 * 16) / 9, width: `${(150 * 16) / 9}px` },
        tablet: { slidesShowing: (170 * 16) / 9, width: `${(170 * 16) / 9}px` },
      },
    };
    if (isCarousel) {
      const dimensions = dimensionsMap[type];

      if (dimensions) {
        let size = 'desktop';
        if (isMobile) {
          size = 'mobile';
        } else if (!isDesktop) {
          size = 'tablet';
        }
        const { width, slidesShowing } = dimensions[size];
        setSlideWidth(width);
        checkSlidesToShow(slidesShowing);
      } else {
        setSlideWidth(1);
        setSlidesToShow(1);
      }
    }
  };

  const checkIsAlbumCarousel = () => {
    if (type === 'album-carousel') {
      if (!isDesktop) {
        setSpacing(8);
      } else {
        setSpacing(16);
      }
    }
  };

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Open Content List',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  React.useEffect(() => {
    function handleResize() {
      showCarouselControlOrNot();
      checkCarouselSlideWidth();
      checkIsAlbumCarousel();
    }

    checkIsAlbumCarousel();
    checkCarouselSlideWidth();

    setTimeout(() => {
      showCarouselControlOrNot();
    }, 500);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // Note: Adding dependencies for hook-related state items so resize logic
    // for determining/setting image source is properly utilized and set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isMobile]);

  return (
    <div className={containerClass} id={id}>
      {!!items && !!items['@nodes'] && (
        <>
          {!isCarousel ? (
            <EditableArea
              content={items}
              parentTemplateId={metadata['mgnl:template']}
            />
          ) : (
            <>
              {type !== 'text-carousel' && (
                <div className="carousel-title-wrapper">
                  {!!props.type.carouselTitle && (
                    <h2 className="carousel-title text-group_header">
                      {props.type.carouselTitle}
                    </h2>
                  )}
                  <a
                    className="view-all"
                    href={implementUtmParams(
                      props.type.viewAllLink ? props.type.viewAllLink : '#',
                      window?.location,
                    )}
                    onClick={handleElementClick}
                  >
                    View All
                  </a>
                </div>
              )}
              {!!items['@nodes'] && (
                <Carousel
                  autoPlayInterval={carouselConfig.autoPlayInterval}
                  autoplay={carouselConfig.autoPlay}
                  cellSpacing={spacing}
                  defaultControlsConfig={{
                    nextButtonClassName: 'custom-next-button',
                    nextButtonText: ' ',
                    prevButtonClassName: 'custom-prev-button',
                    prevButtonText: ' ',
                  }}
                  dragging={false}
                  slideWidth={slideWidth}
                  slidesToScroll={1}
                  slidesToShow={slidesToShow}
                  speed={carouselConfig.speed}
                  swiping={true}
                  withoutControls={showNoControls}
                  wrapAround={false}
                >
                  {items['@nodes'].map((name) => {
                    items[name].slideWidth = slideWidth;
                    items[name].parentID = id;
                    return (
                      <EditableComponent
                        content={items[name]}
                        key={items[name]['@id']}
                        slideWidth={slideWidth}
                      />
                    );
                  })}
                </Carousel>
              )}
              {type === 'text-carousel' && isDevMode && (
                <EditableArea
                  className={'text-carousel-area'}
                  content={items}
                  parentTemplateId={metadata['mgnl:template']}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OpenContentList;
