/**
 * @module CareersHeader
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { implementUtmParams } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import { stringToSlug } from '@io/web-tools-io/dist/utils/helpers/magnolia/urls';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import logo from './careers-logo.svg';
import MenuModal from '../CareersMenuModal/MenuModal';
import './Header.scss';

const CareersHeader = ({ menuData }) => {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const isContentNode = (menuItem) => menuItem['@nodeType'] === 'mgnl:content';
  const rootMenu = menuData;

  /**
   * Handler function for button or link item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Careers Header',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for menu open event.
   *
   * Note: Ignore directives due to edge case of not being used within the
   * context of our main Magnolia page markup (for example, in built-in Careers
   * pages not loaded from Magnolia back-end data return). The logic is the same
   * and is confirmed working, so test ignore is acceptable.
   *
   * @param {Event} event - The Event object associated with the event.
   */
  const handleOpenMenu = (event) => {
    handleElementClick(event);
    const mainDiv = document.querySelector('div.Basic');
    /* istanbul ignore next */
    const rootDiv = document.getElementById('root');
    /* istanbul ignore next */
    if (mainDiv) {
      if (!open) {
        mainDiv.classList.add('is-reveal-open');
      } else {
        mainDiv.classList.remove('is-reveal-open');
      }
    } else if (rootDiv) {
      if (!open) {
        rootDiv.classList.add('is-reveal-open');
      } else {
        rootDiv.classList.remove('is-reveal-open');
      }
    }
    setOpen(!open);
  };

  /**
   * Convenience function to retrieve children from the specified root menu item.
   *
   * @param {object} rootMenuItem - The root menu item to inspect for content nodes nested within the '@nodes' attribute.
   *
   * @returns {Array} An array of item child data objects.
   */
  const getItemChildren = (rootMenuItem) => {
    /* istanbul ignore next */
    return rootMenuItem['@nodes']
      ? rootMenuItem['@nodes']
          .filter((child) => isContentNode(rootMenuItem[child]))
          .map((child) => rootMenuItem[child])
      : null;
  };

  /**
   * Handler function for menu item select.
   *
   * @param {object} [params] - The optional function params object.
   * @param {Function} [params.func] - Optional function to trigger and invoke.
   */
  /* istanbul ignore next */
  function handleMenuItemSelect({ func } = { func: () => {} }) {
    if (func && typeof func === 'function') {
      func();
    }
    setOpen(false);
  }

  /**
   * Convenience function to retrieve and return markup for a menu item.
   *
   * @param {object} rootMenuItem - The root menu item to inspect.
   * @param {*} stop - Optional boolean flag denoting whether or not to override menu item children logical check.
   *
   * @returns {React.ReactElement} A <li> element with link and nested <ul> when menu item children are present.
   */
  const getMenuItem = (rootMenuItem, stop = false) => {
    let url = '#';
    if (
      !!rootMenuItem?.customLink?.field &&
      rootMenuItem.customLink.field === 'internal'
    ) {
      url =
        !!rootMenuItem.customLink.internal &&
        rootMenuItem.customLink.internal['@path'];
    } else if (
      !!rootMenuItem?.customLink?.field &&
      rootMenuItem.customLink.field === 'external'
    ) {
      url = rootMenuItem.customLink.external;
    }
    const children = getItemChildren(rootMenuItem);
    const hasChildren = !!children && children.length > 0 && !stop;

    // Figure out active path to accurately set the class for the nav item.
    const linkUrl = new URL(url, window?.location?.origin);
    let windowUrlPath = window?.location?.pathname;
    let linkUrlPath = linkUrl?.pathname;
    if (!windowUrlPath.endsWith('/')) {
      windowUrlPath = `${windowUrlPath}/`;
    }
    if (!linkUrlPath.endsWith('/')) {
      linkUrlPath = `${linkUrlPath}/`;
    }
    let isActive = windowUrlPath === linkUrlPath;

    if (hasChildren) {
      children.forEach((childItem) => {
        let childUrl = '';
        if (childItem?.customLink?.field === 'internal') {
          childUrl = childItem.customLink.internal?.['@path'];
        } else if (childItem?.customLink?.field === 'external') {
          childUrl = childItem.customLink.external;
        }
        const childLinkUrl = new URL(childUrl, window?.location?.origin);
        if (windowUrlPath === childLinkUrl?.pathname) {
          isActive = true;
        }
      });
    }

    return (
      <li
        className={`nav-desktop${hasChildren ? ' has-children' : ''}${
          isActive ? ' active' : ''
        }`}
        key={rootMenuItem['@id']}
      >
        {hasChildren ? (
          <div
            className={`modal-link-title${
              /* istanbul ignore next */ hasChildren ? ' has-children' : ''
            }`}
            data-menu-id={stringToSlug(rootMenuItem.name)}
          >
            {rootMenuItem.name}
            {
              /* istanbul ignore next */ hasChildren ? (
                <ul className="sub-nav">
                  {children.map((child) => {
                    return getMenuItem(child, true);
                  })}
                </ul>
              ) : null
            }
          </div>
        ) : (
          <a
            className={`modal-link-title${
              /* istanbul ignore next */ hasChildren ? ' has-children' : ''
            }${isActive ? ' active' : ''}`}
            data-menu-id={stringToSlug(rootMenuItem.name)}
            href={implementUtmParams(url, window?.location)}
            onClick={handleElementClick}
            rel="noreferrer"
            target={
              !!rootMenuItem.openInNewTab &&
              rootMenuItem.openInNewTab.toString() === 'true'
                ? '_blank'
                : undefined
            }
          >
            {rootMenuItem.name}
          </a>
        )}
      </li>
    );
  };

  return (
    <header className="careers-header" data-testid="lc-header">
      <nav className="navigation container">
        <a
          className="nav-logo"
          href={implementUtmParams(
            'https://www.life.church/careers/',
            window?.location,
          )}
          onClick={handleElementClick}
        >
          <img alt="Life.Church Careers" className="logo" src={logo} />
        </a>
        <ul className={`main-nav`}>
          {!!menuData && !!rootMenu.length
            ? rootMenu.map((rootMenuItem) => {
                return getMenuItem(rootMenuItem);
              })
            : null}
        </ul>
        <ul className="menu-nav">
          <li>
            <button
              className="hamburger-menu"
              data-menu-id="hamburger-menu"
              onClick={handleOpenMenu}
            >
              <div className={`hamburger${open ? ' active' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <span>MENU</span>
            </button>
          </li>
        </ul>
      </nav>
      <MenuModal
        isOpen={open}
        menuData={menuData}
        onMenuItemSelect={handleMenuItemSelect}
      />
    </header>
  );
};

export default CareersHeader;
