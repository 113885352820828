/**
 * @module Careers
 */
import React from 'react';
import { Log, getQueryParam } from '@io/web-tools-io/dist/utils/helpers';
import { getAPIBase } from '@io/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { EditableArea } from '@magnolia/react-editor';
import CareersHeader from '../components/CareersHeader/CareersHeader';
import Footer from '../components/Footer/Footer';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import Modal from '../components/Modal/Modal';
import useModals from '../hooks/useModals';
import fetchCareersTemplate from '../helpers/dataFetchers/careersTemplateFetcher';
import {
  CONSENT_MANAGER_THIRD_PARTY_SERVICES,
  MGNL_ENV_VARS,
  injectScript,
} from '../helpers';

const Careers = (props) => {
  const { modalTriggers, modalVisibilities, showModal } = useModals();
  const [modal, setModal] = React.useState(null);
  const [data, setData] = React.useState(null);
  const { main, displayNavBar = false } = props;
  const navBar = data?.navBar;

  React.useEffect(() => {
    const fetchModalByHash = async () => {
      let { hash } = window.location;
      if (hash) {
        // eslint-disable-next-line prefer-destructuring
        hash = hash.split('#')[1];
        const apiBase = getAPIBase(MGNL_ENV_VARS);
        await fetch(`${apiBase}/.rest/delivery/modal?@name=${hash}`)
          .then((res) => res.json())
          .then((res) => {
            if (!!res.results && !!res.results.length) {
              setModal(res.results[0]);
            }
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.log({ err }));
      }
    };

    async function fetchData() {
      try {
        const theme = getQueryParam('theme');
        const careersTemplateData = await fetchCareersTemplate(props, theme);
        setData(careersTemplateData);
      } catch (error) {
        Log.error(error);
      }
    }
    if (props.preload) {
      setData(props.preload);
    } else {
      fetchData(); // NOSONAR
    }

    fetchModalByHash(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect to inject third party scripts.
   */
  React.useEffect(() => {
    const { markups } = CONSENT_MANAGER_THIRD_PARTY_SERVICES.google;
    injectScript(markups.googleOptimizeScript, true);
    injectScript(markups.leadershipPodcastAdTracking, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        <div className="Basic">
          {data?.menuData ? (
            <CareersHeader
              menuData={data.menuData}
              menuItems={data.menuItems}
              navItems={data.navItems}
            />
          ) : null}
          <main>
            {displayNavBar.toString() === 'true' ? (
              <NavigationBar {...props} {...{ navBar }} />
            ) : null}
            {main ? <EditableArea className="Area" content={main} /> : null}
            {modal ? (
              <Modal
                data={modal}
                setShowStatus={() => setModal(null)}
                showStatus={true}
              />
            ) : null}
            {modalTriggers
              ? Object.entries(modalTriggers).map(
                  ([modalTriggerId, modalTriggerData]) => {
                    return (
                      <Modal
                        data={modalTriggerData}
                        key={modalTriggerId}
                        setShowStatus={() => {
                          showModal(modalTriggerId, false);
                          window.history.pushState(
                            {},
                            null,
                            window.location.href.split('#')[0],
                          );
                        }}
                        showStatus={modalVisibilities[modalTriggerId]}
                      />
                    );
                  },
                )
              : null}
          </main>
          {data?.footerData ? <Footer footerData={data.footerData} /> : null}
        </div>
      }
    </>
  );
};

export default Careers;
