/**
 * @module App
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { AuthProvider } from '@io/web-tools-io/dist/context/AuthContext';
import { getRouterBasename } from '@io/web-tools-io/dist/utils/helpers/magnolia/getRouterBasename';
import { GeoLocationProvider } from '@io/web-tools-io/dist/context/GeoLocationContext';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { Helmet } from 'react-helmet';
import { ModalProvider } from './context/ModalContext';
// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
import PageLoader from './helpers/PageLoader';
import { MGNL_ENV_VARS } from './helpers/constants';
import { loadSegment } from './lib';
import packageJson from '../package.json';
import './styles/main.scss';

if (!Bugsnag.isStarted() && process.env.BUGSNAG_API_KEY.indexOf('XXXX') < 0) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: packageJson.version,
    // eslint-disable-next-line no-unused-vars
    onUnhandledRejection: (err, event) => {
      if (err instanceof Error) {
        Bugsnag.notify(err);
      }
    },
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
  });
}

/**
 * The App view component.
 *
 * @returns {React.ReactElement} - The App view component.
 */
function App() {
  /**
   * Fetch user from local storage to pass along to Segment for analytics.
   */
  let user;
  let pagePayload;
  try {
    user = window?.localStorage?.getItem('user_profile')
      ? JSON.parse(window?.localStorage?.getItem('user_profile'))
      : null;
    pagePayload = {
      category: document?.title?.split('|')?.[0]?.trim() || '',
      name: document?.title || '',
      properties: {
        logged_in: !!user,
        path: window?.location?.pathname,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    };
  } catch (error) {
    Log.error(error);
  }

  return (
    <AuthProvider>
      <GeoLocationProvider>
        <ModalProvider>
          <Helmet>
            {process.env.SEGMENT_KEY && process.env.SEGMENT_URL
              ? loadSegment({ autoLoad: true, pagePayload })
              : null}
          </Helmet>
          <BrowserRouter basename={getRouterBasename(MGNL_ENV_VARS)}>
            <Switch>
              <Route component={PageLoader} path="/" />
            </Switch>
          </BrowserRouter>
        </ModalProvider>
      </GeoLocationProvider>
    </AuthProvider>
  );
}

export default App;
